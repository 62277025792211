import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseIcon } from 'src/components/BaseIcon';
import { assetTypeToValidator } from 'src/components/FormAssetsParams/fieldValidators';
import { TFormFieldsGeneratorProps } from 'src/components/FormFieldsGenerator';
import { ASSET_TYPE_TO_FIELDS_PARAMS_COMPONENT } from 'src/features/Assets/constants';
import { InputDataStreamId } from 'src/features/Assets/InputDataStreamId';
import newAssetStyles from 'src/features/Assets/NewAsset/NewAsset.module.scss';
import { selectUsedAssetsNames } from 'src/redux/configuration/configuration.selectors';
import { get3dAssetIcon } from 'src/utils/assetIcons';
import { validateFields } from 'src/utils/fieldValidation';

import s from './SingleAsset.module.scss';
import { TSingleAssetProps } from './SingleAsset.types';
import { getPropertyValue, getSubtitleProperty } from './utils';

export const SingleAsset: React.FC<TSingleAssetProps> = ({ onChange, asset }) => {
  const { t } = useTranslation();

  const usedAssetsNames = useSelector(selectUsedAssetsNames);

  const [errors, setErrors] = useState<TFormFieldsGeneratorProps['errors']>(null);

  function handleValidation() {
    return validateFields({
      validators: assetTypeToValidator[asset.type]({
        usedAssetsNames,
        currentValues: asset,
        fields: asset,
      }),
      fields: undefined,
    });
  }
  const handleChange = (changes) => {
    const newAsset = { ...asset };
    changes.forEach(({ name, value }) => {
      newAsset[name] = value;
    });
    onChange(newAsset);

    const { errors } = handleValidation();
    setErrors(errors);
  };

  const handleBlur: TFormFieldsGeneratorProps['onBlur'] = () => {
    const { errors } = handleValidation();
    setErrors(errors);
  };

  return (
    <div>
      <div className={newAssetStyles.hero}>
        <div className={newAssetStyles.icon}>
          <BaseIcon icon={get3dAssetIcon(asset.type)} />
        </div>
        <h5>{asset.name}</h5>
        <p>{`${t(getSubtitleProperty(asset))}: ${getPropertyValue(asset) || '--'}`}</p>
      </div>
      <InputDataStreamId className={s.dataStreamId} />
      <form>
        {ASSET_TYPE_TO_FIELDS_PARAMS_COMPONENT[asset.type]['component']({
          values: asset,
          errors,
          handleChange,
          onBlur: handleBlur,
        })}
      </form>
    </div>
  );
};
