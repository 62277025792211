import React, { useEffect } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { CustomResultHeader } from 'src/features/Results/DashboardHeader/CustomResultHeader';
import { DownloadResultsButton } from 'src/features/Results/DashboardHeader/CustomResultHeader/DownloadResultsButton';
import { TDashboardHeaderProps } from 'src/features/Results/DashboardHeader/DashboardHeader.types';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { useResults } from 'src/hooks/useResults';
import {
  selectSelectedEndDatetime,
  selectSelectedStartDatetime,
} from 'src/redux/application/application.selectors';
import {
  setSelectedEndDatetime,
  setSelectedMemberUuid,
  setSelectedStartDatetime,
} from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { UTCMoment } from 'src/utils/UTCMoment';

import s from './DashboardHeader.module.scss';

export const DashboardHeader: React.FC<TDashboardHeaderProps> = ({ className }) => {
  const dispatch = useAppDispatch();

  const selectedStartDatetime = useSelector(selectSelectedStartDatetime);
  const selectedEndDatetime = useSelector(selectSelectedEndDatetime);

  const { isOperationalCommunity } = useConfiguration();
  const { maxDate } = useResults();
  const { isUserACommunityMember } = useIsUserACommunityMember();

  const onClickGoBack = () => {
    dispatch(setSelectedMemberUuid(null));
  };

  useEffect(() => {
    // First render
    if (!selectedStartDatetime && !selectedEndDatetime) {
      const date = UTCMoment.utc(maxDate);
      const startOfMaxDay = date.startOf('day').toDate().toISOString();
      const endOMaxfDay = date.endOf('day').toDate().toISOString();
      dispatch(setSelectedStartDatetime(startOfMaxDay));
      dispatch(setSelectedEndDatetime(endOMaxfDay));
    }
  }, [dispatch, maxDate, selectedEndDatetime, selectedStartDatetime]);

  return (
    <div className={classnames(s.header, className)}>
      <CustomResultHeader
        onClickGoBack={onClickGoBack}
        flag={isOperationalCommunity ? 'Operation' : 'Simulation'}
      />
      {!isUserACommunityMember && !!selectedStartDatetime && !!selectedEndDatetime && (
        <div className={s.downloadResultContainer}>
          <DownloadResultsButton startDate={selectedStartDatetime} endDate={selectedEndDatetime} />
        </div>
      )}
    </div>
  );
};
