import { useTranslation } from 'react-i18next';
import {
  EViewType,
  TKeyResultInfoDescriptions,
} from 'src/features/Results/SingleAreaResults/KeyResults/KeyResults.types';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { ESCMGroupSettingsName } from 'src/utils/assetsFields/assetsFields.types';

export const useGetDetails = (): {
  getDetails: (viewType: EViewType) => TKeyResultInfoDescriptions;
} => {
  const { t } = useTranslation();
  const { isUserACommunityMember } = useIsUserACommunityMember();

  const getDetails = (viewType: EViewType, scmGroupSettingsName?: ESCMGroupSettingsName) => {
    switch (viewType) {
      case EViewType.Savings:
        return {
          title: isUserACommunityMember ? t('SAVINGS_WITH_YOUR_BOX') : t('HOME_SAVINGS'),
          description:
            scmGroupSettingsName === ESCMGroupSettingsName.VERGY
              ? t('HOME_SAVINGS_INFO_VERGY')
              : t('HOME_SAVINGS_INFO_DEFAULT'),
        };
      case EViewType.SelfSufficiency:
        return {
          title: t('HOME_SELF_SUFFICIENCY'),
          description: t('HOME_SELF_SUFFICIENCY_INFO'),
        };
      case EViewType.EnergyBill:
        return {
          title: t('bill_components.ENERGY_BILL'),
          description:
            scmGroupSettingsName === ESCMGroupSettingsName.VERGY
              ? t('ENERGY_BILL_INFO_VERGY')
              : t('ENERGY_BILL_INFO_DEFAULT'),
        };
      case EViewType.CommunitySavings:
        return {
          title: t('COMMUNITY_SAVINGS'),
          description: t('ENERGY_TRADING_INFO'),
        };
      case EViewType.CommunitySelfSufficiency:
        return {
          title: t('COMMUNITY_SELFSUFFIENCIENCY'),
          description: `${t('SHARE_OF_COMMUNITY_ELECTRICITY')}${t('MINIMUM_SHARE_OF_RENEWABLE')}`,
          //'This is the share of community electricity demand that is provided by the community. This rate also represents the minimum share of renewable energy in your community energy mix.',
        };
      case EViewType.TotalBenefit:
        return {
          title: t('TOTAL_BENEFIT'),
          description: t('TOTAL_BENEFIT_INFO'),
        };
      case EViewType.Revenue:
        return {
          title: t('HOME_REVENUE'),
          description: t('HOME_REVENUE_INFO'),
        };
      default:
        return {
          title: '',
          description: '',
        };
    }
  };

  return {
    getDetails,
  };
};
