import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { MemberForm } from 'src/features/Members/SingleMember/MemberForm/MemberForm';
import { TFormErrorAreas } from 'src/features/Members/SingleMember/SingleMember.types';
import {
  ReadConfigurationDocument,
  ScmCommunityMember,
  useCreateCommunityMemberMutation,
  useInviteAndAssignCommunityMemberMutation,
} from 'src/graphql';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { setSelectedMemberUuid } from 'src/redux/application/application.slice';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { setIsAppLoading } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { dispatchSuccessToast, dispatchErrorToast } from 'src/utils/toasts';

import s from './NewMember.module.scss';

const EMAIL_VALIDATION = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const NewMember: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const configUuid = useSelector(selectActiveConfigurationUuid);

  const [newMember, setNewMember] = useState<ScmCommunityMember>({
    name: null,
    email: null,
    address: null,
    zipCode: null,
  });
  const [formErrorAreas, setFormErrorAreas] = useState<TFormErrorAreas>({});

  const { isOperationalCommunity } = useConfiguration();

  const [inviteMember] = useInviteAndAssignCommunityMemberMutation({
    errorPolicy: 'none',
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });
  const [createCommunityMember] = useCreateCommunityMemberMutation({
    errorPolicy: 'none',
    refetchQueries: [{ query: ReadConfigurationDocument, variables: { uuid: configUuid } }],
    onCompleted: (result) => {
      if (!!result?.createCommunityMember?.uuid) {
        dispatch(setSelectedMemberUuid(result.createCommunityMember.uuid));

        if (isOperationalCommunity && !!configUuid && !!result.createCommunityMember.email) {
          inviteMember({ variables: { configUuid, email: result.createCommunityMember.email } });
          return dispatchSuccessToast('Invitation sent');
        }
        dispatchSuccessToast();
      }

      dispatch(setIsAppLoading(false));
    },
    onError: (err) => {
      dispatchErrorToast(err);
      dispatch(setIsAppLoading(false));
    },
  });

  const onChange = useCallback(
    ({ name, value }) => {
      setNewMember({ ...newMember, [name]: value });
    },
    [newMember],
  );

  const onSubmit = useCallback(() => {
    if (!configUuid) return;

    const formKeys = ['name', 'email', 'address'];

    const isFormValid = formKeys.every((key) => newMember[key]);

    if (!configUuid) return;

    if (!isFormValid) {
      // find the first empty field and focus on it
      const emptyFields = formKeys.filter((key) => !newMember[key]);
      if (emptyFields.length > 0) {
        const errorAreas: TFormErrorAreas = {};
        emptyFields.forEach((field) => {
          errorAreas[field] = 'This field is required';
        });
        setFormErrorAreas(errorAreas);
      }
      return;
    }

    dispatch(setIsAppLoading(true));

    if (configUuid) {
      createCommunityMember({
        variables: {
          name: newMember.name || '',
          email: newMember.email || '',
          zipcode: newMember.zipCode || '',
          address: newMember.address || '',
          configUuid: configUuid,
          marketMakerRate: 0,
          feedInTariff: 0,
          gridFee: 0,
          taxesSurcharges: 0,
          electricityTax: 0,
          fixedMonthlyFee: 0,
          marketplaceMonthlyFee: 0,
          assistanceMonthlyFee: 0,
          serviceMonthlyFee: 0,
          contractedPowerMonthlyFee: 0,
          contractedPowerCargoMonthlyFee: 0,
          energyCargoFee: 0,
        },
      });
    }
  }, [dispatch, newMember, configUuid, createCommunityMember]);

  const onClickBack = () => dispatch(setSelectedMemberUuid(null));

  const canSave =
    !!newMember?.name &&
    !!newMember?.email &&
    !!newMember?.address &&
    EMAIL_VALIDATION.test(newMember.email);

  return (
    <div className={s.newMember}>
      <div className={s.header} onClick={onClickBack}>
        <BaseIcon size={14} icon="arrow-left-full" className={s.iconBack} />
        {t('navigation.ALL_MEMBERS')}
      </div>
      <MemberForm
        formErrorAreas={formErrorAreas}
        values={newMember}
        onChange={onChange}
        className={s.newMemberForm}
      />
      <BaseButton type="button" onClick={onSubmit} className={s.formButton} disabled={!canSave}>
        {t('commands.SAVE')}
      </BaseButton>
    </div>
  );
};
