import { TFormAssetsParamsProps } from 'src/components/FormAssetsParams';
import { TLocationSearchValue } from 'src/components/LocationSearch';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { TMapboxLocation } from 'src/services/api/mapbox.api';
import { EFormVariant, TAssetType } from 'src/typings/base-types';
import { TAsset } from 'src/typings/configuration.types';

export type TModalAssetsManagerProps = {
  coords?: TLocationSearchValue['coords'];
  placeName?: TMapboxLocation['place_name'] | undefined;
  summaryIcon?: 'house' | 'market';
  hostAssetUuid?: TAsset['uuid'];
  view: EModalAssetsManagerView;
  // viewOnDestroy:EModalAssetsManagerView;
  viewOnDestroy:
    | EModalAssetsManagerView.AddHouse
    | EModalAssetsManagerView.Summary
    | EModalAssetsManagerView.AddCommunity
    | EModalAssetsManagerView.SaveOrSettings;
  formVariant?: EFormVariant;
  disallowReturnToSummary?: boolean | 'hide-button' | (() => void);
  onViewChange?(view: EModalAssetsManagerView): void;
  onFormVariantChange?(formVariant: EFormVariant): void;
  onAssetValuesSave?: TFormAssetsParamsProps['onSubmit'];
  onAssetValuesSavePromise?: TFormAssetsParamsProps['onSubmitPromise'];
  addSaveKey?(key: string): void;
  rootElRefProp?: React.RefObject<HTMLDivElement | null>;
};

export type TNewAsset = {
  values: TConfigurationState['assetsValues']['assetType'];
};

export enum EModalAssetsManagerView {
  Wait = 'WAIT',
  AddHouse = 'ADD_HOUSE',
  Summary = 'SUMMARY',
  ChooseAsset = 'CHOOSE_ASSET',
  AssetsInside = 'ASSETS_INSIDE',
  Form = 'FORM',
  SaveOrSettings = 'SAVE_OR_SETTINGS',
  AddCommunity = 'ADD_COMMUNITY',
  AssetLoading = 'ASSET_LOADING',
  GridMarketAndAgent = 'GRID_MARKET_AND_AGENT',
  SaveToLibrary = 'SAVE_TO_LIBRARY',
}

export type TAssetsInside = Array<{
  icon: 'house' | 'pv' | 'plug' | 'battery' | 'factory' | 'heatpump';
  amount: number;
  type: TAssetType;
}>;
