import React, { useCallback, useContext, useEffect, useRef } from 'react';

import classnames from 'classnames';
import mapboxgl, { MapboxOptions } from 'mapbox-gl';
import { batch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { locationsCache } from 'src/cache/locations';
import { AssetPopup } from 'src/components/AssetPopup';
import { ConfigurationManagementCentral } from 'src/components/ConfigurationManagementCentral/ConfigurationManagementCentral';
import { EModalAssetsManagerView, ModalAssetsManager } from 'src/components/ModalAssetsManager';
import { Popup } from 'src/components/WorldMap/components/Popup';
import { WorldMapSelectedCommunityAssets } from 'src/components/WorldMapSelectedCommunityAssets';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { useConfiguration } from 'src/hooks/useConfiguration';
import {
  selectModalAssetManagerActiveView,
  selectModalCommunitySummaryView,
  selectOnboardingStep,
} from 'src/redux/application/application.selectors';
import {
  setModalAssetManagerActiveView,
  setModalCommunitySummaryView,
} from 'src/redux/application/application.slice';
import { selectUsername } from 'src/redux/auth/auth.selectors';
import { selectCommunitiesList } from 'src/redux/communities/communities.selectors';
import {
  selectActiveConfigurationUuid,
  selectAssetsAmountBelowCommunity,
  selectCommunityAsset,
  selectIsCommunityCreatedInDB,
  selectSelectedAssetUuid,
} from 'src/redux/configuration/configuration.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { selectSelectedLocation } from 'src/redux/map/map.selectors';
import { updateSelectedLocation, updateVisibleLocation } from 'src/redux/map/map.slice';
import { useAppDispatch } from 'src/redux/store';
import { TMapboxLocation, getLocationDetails } from 'src/services/api/mapbox.api';
import { TViewport, WorldMapService } from 'src/services/map/map.service';
import { TLngLat, TLngLatArray } from 'src/typings/base-types';
import {
  convertLngLat,
  getLocationDetailsByPriority,
  getPlaceTypeZoom,
} from 'src/utils/worldMap/helpers';

import s from './WorldMap.module.scss';
import { TWorldMapProps } from './WorldMap.types';

const MAP_SETTINGS: Partial<mapboxgl.MapboxOptions> = {
  minZoom: 0,
  maxZoom: 20,
  minPitch: 0,
  maxPitch: 50,
  style: process.env.REACT_APP_D3A_MAPBOX_STYLE,
};

export const WorldMap: React.FC<TWorldMapProps> = React.memo(({ className }) => {
  const step = useSelector(selectOnboardingStep);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { mapService, setMapService } = useContext(WorldMapContext);

  const selectedLocation = useSelector(selectSelectedLocation);
  const selectedAssetUuid = useSelector(selectSelectedAssetUuid);
  const rawSelectedAssetUuid = useSelector(selectSelectedAssetUuid);
  const communitiesList = useSelector(selectCommunitiesList);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const mapboxRef = useRef<HTMLDivElement>(null);
  const modalCommunitySummaryView = useSelector(selectModalCommunitySummaryView);
  //const isCreateOrEditHouseFlow = useSelector(selectIsCreateOrEditHouseFlow);
  const modalAssetManagerActiveView = useSelector(selectModalAssetManagerActiveView);
  const communityAsset = useSelector(selectCommunityAsset);
  const isCommunityCreatedInDB = useSelector(selectIsCommunityCreatedInDB);
  const assetsAmountBelowCommunity = useSelector(selectAssetsAmountBelowCommunity);

  const { configuration, communityArea } = useConfiguration();

  const userMustSaveConfiguration =
    selectedAssetUuid === communityAsset?.uuid &&
    modalCommunitySummaryView !== EModalAssetsManagerView.Summary &&
    !isCommunityCreatedInDB &&
    assetsAmountBelowCommunity === 2;
  const username = useSelector(selectUsername);
  const prevSelectedAssetUuid = useRef(selectedAssetUuid);
  const prevSelectedLocation = useRef(selectedLocation);
  const setSelectedLocation = useCallback(
    async (lngLat: TLngLat) => {
      dispatch(updateSelectedLocation({ ...lngLat }));

      const details = await getLocationDetails(lngLat);
      if (details) {
        const locationDetails = getLocationDetailsByPriority(details.features);
        locationsCache.set(locationsCache.convertToKey(lngLat), locationDetails);

        dispatch(
          updateSelectedLocation({
            ...lngLat,
            place_name: locationDetails?.place_name,
            place_type: locationDetails?.place_type,
            text: locationDetails?.text,
            bbox: locationDetails?.bbox,
          }),
        );
      }
    },
    [dispatch],
  );

  const onMouseMoveListener = useCallback(async () => {
    mapService?.map.getCanvasContainer().classList.add(s.cursorDefault);
  }, [mapService]);

  const onMoveEndListener = useCallback(
    async (viewport: TViewport) => {
      const data = await getLocationDetails({ lng: viewport.lng, lat: viewport.lat });
      if (!data) return;
      dispatch(
        updateVisibleLocation(
          data.features.map((item) => ({
            ...convertLngLat(item.center as TLngLatArray),
            place_name: item.place_name,
            place_type: item.place_type,
            text: item.text,
            bbox: item.bbox,
          })),
        ),
      );
    },
    [dispatch],
  );

  const onZoomListener = useCallback(
    async (viewport: TViewport) => {
      mapService?.threeboxController.viewportChanged(viewport);
    },
    [mapService?.threeboxController],
  );

  const geolocateListener = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      setSelectedLocation({
        lng: e.coords.longitude,
        lat: e.coords.latitude,
      });
    },
    [setSelectedLocation],
  );

  const renderListener = useCallback((e) => {
    if (e.target.getPitch() < (MAP_SETTINGS.minPitch as number)) {
      e.target.setPitch(MAP_SETTINGS.minPitch);
    } else if (e.target.getPitch() > (MAP_SETTINGS.maxPitch as number)) {
      e.target.setPitch(MAP_SETTINGS.maxPitch);
    }
  }, []);

  const styleLoadListener = useCallback(() => {
    // On direct enter on configuration page, models were displayed and caused issues
    if (!configUuid) {
      mapService?.threeboxController.setUsername(username);
      mapService?.threeboxController.setCommunityList(communitiesList);
    }
  }, [configUuid, communitiesList, mapService?.threeboxController, username]);

  useEffect(() => {
    if (configUuid === 'NEW_COMMUNITY' && selectedLocation) {
      mapService?.threeboxController.addTempHousePin(selectedLocation);
    }
  }, [configUuid, mapService, selectedLocation]);

  useEffect(() => {
    // On direct enter on configuration page, models were displayed and caused issuess
    if (!configUuid) {
      mapService?.threeboxController.setCommunityList(communitiesList);
    }
  }, [configUuid, communitiesList, mapService?.threeboxController]);

  useEffect(() => {
    mapService?.threeboxController.setUsername(username);
  }, [mapService?.threeboxController, username]);

  useEffect(() => {
    if (mapboxRef.current && !mapService) {
      mapboxgl.accessToken = process.env.REACT_APP_D3A_MAPBOX_KEY as string;

      if (selectedLocation && selectedLocation.lng && selectedLocation.lat) {
        MAP_SETTINGS.center = [selectedLocation.lng, selectedLocation.lat];
        MAP_SETTINGS.zoom = getPlaceTypeZoom(
          (selectedLocation.place_type && selectedLocation.place_type[0]) || '',
        );
      }

      const service = new WorldMapService(mapboxRef.current, MAP_SETTINGS as MapboxOptions);

      setMapService(service);
    }
  }, [mapService, selectedLocation, setMapService]);

  useEffect(() => {
    mapService?.threeboxController.setBuildMode(!!configUuid);
    const viewport = mapService?.getViewport();
    if (viewport) mapService?.threeboxController.viewportChanged(viewport);
  }, [configUuid, mapService]);

  useEffect(() => {
    return () => {
      mapService?.threeboxController.dispose();
    };
  }, [mapService]);

  useEffect(() => {
    mapService?.addListener('render', renderListener);
    mapService?.addListener('mousemove', onMouseMoveListener);
    mapService?.addListener('moveend', onMoveEndListener);
    mapService?.addListener('zoom', onZoomListener);
    mapService?.addListener('geolocate', geolocateListener);
    mapService?.addListener('styleLoad', styleLoadListener);
    return () => {
      mapService?.removeListener('render', renderListener);
      mapService?.removeListener('mousemove', onMouseMoveListener);
      mapService?.removeListener('moveend', onMoveEndListener);
      mapService?.removeListener('zoom', onZoomListener);
      mapService?.removeListener('geolocate', geolocateListener);
      mapService?.removeListener('styleLoad', styleLoadListener);
    };
  }, [
    geolocateListener,
    mapService,
    onMouseMoveListener,
    onMoveEndListener,
    onZoomListener,
    renderListener,
    styleLoadListener,
  ]);

  useEffect(() => {
    // This effect toggles between "selected location" and "selected asset" in order to avoid displaying two modals at once.
    const selectedAssetUuidChangedToTruthy =
      prevSelectedAssetUuid.current !== communityArea?.uuid && !!communityArea; // prettier-ignore
    const selectedLocationChangedToTruthy = prevSelectedLocation.current !== selectedLocation && selectedLocation // prettier-ignore

    if (selectedAssetUuidChangedToTruthy) {
      dispatch(updateSelectedLocation(null));
    }

    if (selectedLocationChangedToTruthy) {
      dispatch(setSelectedAssetUuid(undefined));
    }

    prevSelectedAssetUuid.current = communityArea?.uuid;
    prevSelectedLocation.current = selectedLocation;
  }, [dispatch, communityArea, selectedLocation]);

  useEffect(() => {
    history.listen(() => {
      if (mapService) {
        mapService.threeboxController.viewportChanged(mapService.getViewport());
      }
    });
  }, [history, mapService]);

  useEffect(() => {
    if (!!communityArea?.children && communityArea?.children?.length > 0) {
      batch(() => {
        dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Summary));
        dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.Summary));
      });
    }
  }, [communityArea, dispatch]);

  return (
    <div className={classnames(s.container, className)}>
      {/* The map */}
      <div ref={mapboxRef} className={s.theMap} />

      {mapService && configUuid && (
        <ConfigurationManagementCentral>
          {({ handleAssetValuesSave, addSaveKey }) => (
            <>
              {selectedLocation && (
                <Popup lng={selectedLocation.lng} lat={selectedLocation.lat} higherZIndex>
                  {selectedLocation.lng && selectedLocation.lat && (
                    <ModalAssetsManager
                      view={modalAssetManagerActiveView}
                      viewOnDestroy={
                        step == 0
                          ? EModalAssetsManagerView.AddCommunity
                          : EModalAssetsManagerView.AddHouse
                      }
                      coords={convertLngLat(selectedLocation as NonNullable<TMapboxLocation>)}
                      placeName={selectedLocation.place_name}
                      onViewChange={(v) => dispatch(setModalAssetManagerActiveView(v))}
                      addSaveKey={addSaveKey}
                    />
                  )}
                </Popup>
              )}
              {!!communityArea &&
                !!configuration?.coordinates?.latitude &&
                !!configuration?.coordinates?.longitude && (
                  <Popup
                    lng={configuration.coordinates.longitude}
                    lat={configuration.coordinates.latitude}
                    style={{ marginTop: -100 }}
                    higherZIndex>
                    <AssetPopup />
                  </Popup>
                )}

              <WorldMapSelectedCommunityAssets
                selectedAssetUuid={selectedAssetUuid}
                rawSelectedAssetUuid={rawSelectedAssetUuid}
                disableInteraction={userMustSaveConfiguration}
                onAssetValuesSave={handleAssetValuesSave}
                onGridMarketClick={() => null}
                addSaveKey={addSaveKey}
              />

              {/* TODO: Dev test components >>> */}
              {/* <TestTree
                  underUuid={underUuid}
                  setUnderUuid={(uuid: TConfigurationState['selectedAssetUuid']) => {
                    dispatch(setSelectedAssetUuid(uuid));
                  }}
                  onAssetRemove={handleAssetRemove}
                /> */}
              {/* TODO: <<< Dev test components */}
            </>
          )}
        </ConfigurationManagementCentral>
      )}
    </div>
  );
});
