import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import vars from 'src/assets/styles/utils/vars.module.scss';
import { EDomIds } from 'src/constants/domSelectors';
import { EPredefinedModalIds } from 'src/constants/modals';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { useNavigation } from 'src/hooks/useNavigation';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';

import s from './InViewPositioner.module.scss';
import { TInViewPositionerProps } from './InViewPositioner.types';

function getViewportBorders() {
  const offset = 5;
  const MAP_CONTROLS_WIDTH = 55;
  const output = {
    top: offset,
    right: window.innerWidth - offset - MAP_CONTROLS_WIDTH,
    bottom: window.innerHeight - offset,
    left: offset,
  };

  const header = document.getElementById(EDomIds.HEADER);
  if (header) {
    output.top += header.getBoundingClientRect().bottom;
  }

  const sidebar = document.getElementById(EDomIds.MODAL_MAP_SIDEBAR);

  if (sidebar) {
    output.left += sidebar.getBoundingClientRect().right;
  }

  const runSimCta = document.getElementById(EDomIds.MODAL_RUN_SIMULATION_CTA);

  if (runSimCta) {
    output.bottom -= window.innerHeight - runSimCta.getBoundingClientRect().top;
  }

  const searchLocation = document.getElementById(EDomIds.MODAL_SEARCH_LOCATION);

  if (searchLocation) {
    output.bottom -= window.innerHeight - searchLocation.getBoundingClientRect().top;
  }

  const simulationProgress = document.getElementById(EDomIds.SIMULATION_PROGRESS_MODAL);

  if (simulationProgress) {
    output.bottom -= window.innerHeight - simulationProgress.getBoundingClientRect().top;
  }

  return output;
}

export const InViewPositioner: React.FC<TInViewPositionerProps> = ({
  className,
  innerClassName,
  children,
  recalculateKey,
  reservedHeight = 0,
  rootElRef,
}) => {
  const { mapService } = useContext(WorldMapContext);
  const [style, setStyle] = useState<React.CSSProperties>();
  const [availableHeight, setAvailableHeight] = useState<number>(
    parseInt(vars['assets-manager-scroll-max-height']),
  );
  const rootEl: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const { modalSimulationProgressShow } = useNavigation();
  const isModalMapSidebarOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_MAP_SIDEBAR),
  );
  const isModalRunSimulationCtaOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_RUN_SIMULATION_CTA),
  );
  const isModalSearchLocationOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_SEARCH_LOCATION),
  );
  const [viewportBorders, setViewportBorders] = useState(getViewportBorders());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const adjustChildrenPosition = useCallback(
    debounce(() => {
      if (!rootEl.current) return;
      const rootElRect = rootEl.current.getBoundingClientRect();
      let px = 0;
      let py = 0;

      // Check left/right side
      if (rootElRect.right > viewportBorders.right) {
        px = viewportBorders.right - rootElRect.right;
      } else if (rootElRect.left < viewportBorders.left) {
        px = -rootElRect.left + viewportBorders.left;
      }

      // Check top/bottom side
      if (rootElRect.bottom > viewportBorders.bottom) {
        py = viewportBorders.bottom - rootElRect.bottom;
      } else if (rootElRect.top < viewportBorders.top) {
        py = -rootElRect.top + viewportBorders.top;
      }

      setStyle({
        transform: `translate3d(${px}px, ${py}px, 0)`,
        transition: `transform 0.2s ease-out`,
      });
      setAvailableHeight(viewportBorders.bottom - viewportBorders.top);
    }, 200),
    [viewportBorders],
  );

  useEffect(() => {
    mapService?.addListener('moveend', adjustChildrenPosition);
    return () => {
      mapService?.removeListener('moveend', adjustChildrenPosition);
    };
  }, [adjustChildrenPosition, mapService]);

  useEffect(() => {
    adjustChildrenPosition();
  });

  useEffect(() => {
    const handler = debounce(() => {
      setViewportBorders(getViewportBorders());
    }, 500);

    window.addEventListener('resize', handler);
    window.addEventListener('orientationchange', handler);

    return () => {
      window.removeEventListener('resize', handler);
      window.removeEventListener('orientationchange', handler);
    };
  }, [
    modalSimulationProgressShow,
    isModalMapSidebarOpened,
    isModalRunSimulationCtaOpened,
    isModalSearchLocationOpened,
  ]);

  const initMount = useRef(true);
  useEffect(() => {
    if (!initMount.current) {
      initMount.current = false;
      return;
    }

    const id = setTimeout(() => {
      setViewportBorders(getViewportBorders());
    }, 300);

    return () => {
      clearTimeout(id);
    };
  }, [
    modalSimulationProgressShow,
    isModalMapSidebarOpened,
    isModalRunSimulationCtaOpened,
    isModalSearchLocationOpened,
    recalculateKey,
  ]);

  return (
    <div
      ref={(ref) => {
        rootEl.current = ref;

        if (rootElRef) {
          rootElRef.current = ref;
        }
      }}
      className={className}>
      <div
        className={classnames(s.inner, innerClassName)}
        style={{
          ...style,
          ['--assets-manager-scroll-max-height' as string]:
            Math.min(availableHeight - reservedHeight, 350) + 'px',
        }}>
        {children}
      </div>
    </div>
  );
};
