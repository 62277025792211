import React from 'react';

import { useTranslation } from 'react-i18next';

import s from './TableBills.module.scss';

export const TableBillsHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr className={s.darkTableHeader}>
        <th className={s.rightBorder} style={{ width: '20%' }} colSpan={1}></th>
        <th className={s.rightBorder} colSpan={3}>
          {t('labels.BOUGHT_FROM_GRID')}
        </th>
        <th className={s.rightBorder} colSpan={2}>
          {t('labels.SOLD_TO_GRID')}
        </th>

        <th className={s.rightBorder} colSpan={2}>
          {t('labels.BOUGHT_FROM_COMMUNITY')}
        </th>
        <th className={s.rightBorder} colSpan={2}>
          {t('labels.SOLD_TO_COMMUNITY')}
        </th>

        <th colSpan={3}>{t('labels.TOTAL_BALANCE')}</th>
      </tr>
      <tr className={s.columnTitlesHeader}>
        <th className={s.textLeft}>{t('common.MEMBER')}</th>
        <th className={s.greyBackground}>{t('common.ENERGY')} (kWh)</th>
        <th colSpan={2}>{t('common.PAID')} (€)</th>
        <th className={s.greyBackground}>{t('common.ENERGY')} (kWh)</th>
        <th>{t('common.REVENUE')} (€)</th>

        <th className={s.greyBackground}>{t('common.ENERGY')} (kWh)</th>
        <th>{t('common.PAID')} (€)</th>
        <th className={s.greyBackground}>{t('common.ENERGY')} (kWh)</th>
        <th>{t('common.REVENUE')} (€)</th>

        <th className={s.greyBackground}>{t('common.ENERGY')} (kWh)</th>
        <th>{t('common.TOTAL')} (€)</th>
      </tr>
      <tr className={s.informationsHeader}>
        <th></th>
        <th></th>
        <th className={s.informationCell}>{t('labels.TRADE_COST')} (€)</th>
        <th className={s.informationCell}>{t('labels.GRID_FEES')} (€)</th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
  );
};
