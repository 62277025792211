import React from 'react';

import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { DatePickerCustomHeader } from 'src/components/BaseDateRangePicker/DatePickerCustomHeader';
import { useResults } from 'src/hooks/useResults';
import { selectSelectedStartDatetime } from 'src/redux/application/application.selectors';
import {
  setSelectedEndDatetime,
  setSelectedStartDatetime,
} from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { UTCMoment } from 'src/utils/UTCMoment';

import s from './SelectDay.module.scss';

export const SelectDay: React.FC = () => {
  const [isCalendarOpened, setIsCalendarOpened] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const selectedStartDatetime = useSelector(selectSelectedStartDatetime);

  const { minDate, maxDate } = useResults();

  const handleChange = (date: Date) => {
    const startOfDay = UTCMoment.utc(date).startOf('day').toDate();
    const endOfDay = UTCMoment.utc(date).endOf('day').toDate();
    dispatch(setSelectedStartDatetime(startOfDay.toISOString()));
    dispatch(setSelectedEndDatetime(endOfDay.toISOString()));
  };

  return (
    <div
      className={classNames({
        [s.container]: true,
        [s.themeLight]: true,
        [s.calendarOpened]: !!isCalendarOpened,
        [s.startCalendarActive]: isCalendarOpened,
        themeLight: true,
      })}>
      <div className={s.pickers}>
        {!!selectedStartDatetime && (
          <DatePicker
            className={s.datepickerInput}
            selected={new Date(selectedStartDatetime)}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(d) => handleChange(d as Date)}
            dateFormat="dd MMMM yyyy"
            disabled={false}
            minDate={minDate}
            maxDate={maxDate}
            placeholderText={'placeholderText'}
            renderCustomHeader={DatePickerCustomHeader}
            onCalendarOpen={() => {
              setIsCalendarOpened(true);
            }}
            onCalendarClose={() => {
              setIsCalendarOpened(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
