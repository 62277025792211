import React, { useCallback, useEffect } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { BaseButtonRound } from 'src/components/BaseButtonRound';
import { BaseIcon } from 'src/components/BaseIcon';
import { EActiveTabState } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity';
import { EPredefinedModalIds } from 'src/constants/modals';
import {
  setActiveSidebarMainTab,
  setActiveSidebarSettingsSubTab,
} from 'src/redux/application/application.slice';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import {
  selectActiveConfigurationUuid,
  selectIsCommunityCreatedInDB,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import { openModal } from 'src/redux/modals/modals.slice';
import { selectActiveSCMStep } from 'src/redux/scm/scm.selectors';
import { setActiveSCMStep } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { EFormVariant, EUserRoles } from 'src/typings/base-types';
import { ELsKey, ls } from 'src/utils/localStorage';

import s from './HeaderSteps.module.scss';
import { EButtonType } from './HeaderSteps.types';

const STEPS = [
  'COMMUNITY_BUILER',
  'MEMBERS_MANAGEMENT',
  'MARKET_SETTINGS',
  'VISUALISE_AND_ASSESS',
  'OPERATE_YOUR_COMMUNITY',
];

export const HeaderSteps: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userRole = useSelector(selectUserRole);
  const activeStep = useSelector(selectActiveSCMStep);
  const isCommunityCreatedInDB = useSelector(selectIsCommunityCreatedInDB);
  const simulationStatus = useSelector(selectSimulationStatus);
  const configUuid = useSelector(selectActiveConfigurationUuid);

  const isDisabledArea = ['running', 'paused', 'queued', 'started', 'initializing'].includes(
    simulationStatus as string,
  );

  useEffect(() => {
    const scmStep = ls.get(ELsKey.SCM_STEP);
    if (scmStep && isCommunityCreatedInDB) {
      dispatch(setActiveSCMStep(Number(scmStep)));
    }
  }, [dispatch, isCommunityCreatedInDB]);

  const handleArrowButtonClick = useCallback<(buttonType: EButtonType) => void>(
    (buttonType) => {
      if (buttonType === EButtonType.next && activeStep + 1 < STEPS.length) {
        dispatch(setActiveSCMStep(activeStep + 1));
        return;
      }

      if (buttonType === EButtonType.prev && activeStep >= 0) {
        dispatch(setActiveSCMStep(activeStep - 1));
      }
    },
    [activeStep, dispatch],
  );

  if (userRole === EUserRoles.Aggregator || userRole === EUserRoles.Researcher) {
    return null;
  }

  const handleStepClick = (value: number) => {
    dispatch(setActiveSCMStep(value));
    switch (value) {
      case 0:
        if (!configUuid) {
          return dispatch(openModal(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY));
        } else {
          return batch(() => {
            dispatch(setActiveSidebarMainTab(EActiveTabState.Settings));
            dispatch(setActiveSidebarSettingsSubTab(EFormVariant.Express));
          });
        }
        break;
      case 1:
        return batch(() => {
          dispatch(setActiveSidebarMainTab(EActiveTabState.Registry));
        });

      case 2:
        return batch(() => {
          dispatch(openModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
          dispatch(setActiveSidebarMainTab(EActiveTabState.Settings));
          dispatch(setActiveSidebarSettingsSubTab(EFormVariant.GridMarket));
        });
      case 3:
        return dispatch(setActiveSidebarMainTab(EActiveTabState.Community));
      default:
        return;
    }
  };

  if (!configUuid && configUuid !== 'NEW_COMMUNITY') return null;

  return (
    <div
      className={s.container}
      style={{
        pointerEvents: isDisabledArea ? 'none' : 'auto',
      }}>
      <BaseButtonRound
        className={s.arrowButton}
        theme="border"
        disabled={activeStep === 0}
        onClick={() => handleArrowButtonClick(EButtonType.prev)}>
        <BaseIcon icon="arrow-modern-prev" size={10} />
      </BaseButtonRound>

      {STEPS.map((step, index) => {
        return (
          <button
            type="button"
            disabled={index === 4}
            key={step}
            className={classnames(s.stepContainer, {
              [s.active]: step === STEPS[activeStep],
              [s.covered]: index <= activeStep,
            })}
            onClick={() => handleStepClick(index)}>
            {index < activeStep && <BaseIcon icon="check-mark" size={16} />}
            <div className={s.textTab}>{t(`navigation.${step}`)}</div>
          </button>
        );
      })}

      <BaseButtonRound
        className={s.arrowButton}
        theme="border"
        disabled={activeStep === STEPS.length - 1}
        onClick={() => handleArrowButtonClick(EButtonType.next)}>
        <BaseIcon icon="arrow-modern-next" size={10} />
      </BaseButtonRound>
    </div>
  );
};
