import React from 'react';

import classNames from 'classnames';

import s from './ListMembers.module.scss';
import { TListMembersTileProps } from './ListMembers.types';

export const ListMembersTile: React.FC<TListMembersTileProps> = ({ data, handleClick }) => (
  <div className={classNames(s.userTile)} onClick={() => handleClick(data?.uuid || '')}>
    <div className={s.userDataContainer}>
      <p className={s.username}>{data.name}</p>
      <p className={s.userEmail}>{data.email}</p>
    </div>
  </div>
);
