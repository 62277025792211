import React from 'react';

import classnames from 'classnames';

import s from './GridMarket.module.scss';

type GridMarketModalHeaderProps = {
  formTitle: string;
  onClick?: () => void;
  className?: string;
};

export const GridMarketModalHeader: React.FC<GridMarketModalHeaderProps> = ({
  className,
  formTitle,
  onClick,
}) => {
  return (
    <header className={classnames(s.header, className)} onClick={onClick}>
      <h3 className={s.title}>{formTitle}</h3>
    </header>
  );
};
