import React from 'react';

import classNames from 'classnames';
import { TTableBillRowProps } from 'src/features/Results/SingleCommunityResults/TableBills/TableBills.types';

import s from './TableBills.module.scss';

export const TableBillRow: React.FC<TTableBillRowProps> = ({
  uuid,
  name,
  bill,
  handleBillClick,
}) => {
  const roundToTwoDecimals = (num?: number) => {
    return !num ? '0,00' : num.toFixed(2).replace('.', ',');
  };

  const onClick = name === 'Community' ? undefined : () => handleBillClick(uuid);

  return (
    <tr onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'default' }}>
      <td>
        <span className={s.borderLeft}>{name}</span>
      </td>
      <td className={s.greyBackground}>{roundToTwoDecimals(bill.boughtFromGrid)}</td>
      <td className={s.redText}>{roundToTwoDecimals(bill.spentToGrid)}</td>
      <td className={s.blueText}>{roundToTwoDecimals(bill.importGridFees)}</td>
      <td className={s.greyBackground}>{roundToTwoDecimals(bill.soldToGrid)}</td>
      <td className={s.greenText}>{roundToTwoDecimals(bill.earnedFromGrid)}</td>
      <td className={s.redText}>{roundToTwoDecimals(bill.boughtFromCommunity)}</td>
      <td className={s.blueText}>{roundToTwoDecimals(bill.spentToCommunity)}</td>
      <td className={s.greyBackground}>{roundToTwoDecimals(bill.soldToCommunity)}</td>
      <td className={s.greenText}>{roundToTwoDecimals(bill.earnedFromCommunity)}</td>
      <td className={s.greyBackground}>{roundToTwoDecimals(bill.homeBalanceKwh)}</td>
      <td
        className={classNames(
          bill.homeBalance > 0 ? s.redText : s.greenText,
          s.textLeft,
          s.totalText,
        )}>
        <span>{bill.homeBalance > 0 ? 'Paid' : 'Revenue'}</span>
        {roundToTwoDecimals(Math.abs(bill.homeBalance))}
      </td>
    </tr>
  );
};
