import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { ChartSimulationProgress } from 'src/components/SimulationProgressModal/components/ChartSimulationProgress';
import { TimelineArrowButtons } from 'src/components/SimulationProgressModal/components/TimelineArrowButtons';
import { TimelineEventChart } from 'src/components/SimulationProgressModal/components/TimelineEventChart';
import { EPredefinedModalIds } from 'src/constants/modals';
import {
  selectAssets,
  selectConfigType,
  selectRootAssetUuid,
  selectSettingsData,
} from 'src/redux/configuration/configuration.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { UTCMoment } from 'src/utils/UTCMoment';

import { TimelineFilters } from '../TimelineFilters';
import s from './SimulationProgressModalExpanded.module.scss';
import { TSimulationProgressModalExpandedProps } from './SimulationProgressModalExpanded.types';

export const SimulationProgressModalExpanded: React.FC<TSimulationProgressModalExpandedProps> = ({
  simulationStatusTitle,
  simulationProgress,
  runButtonState,
  runButtonOptions,
  stopButtonOptions,
  toggleExpanded,
  detailsData,
  filter,
  setFilter,
  durationFilter,
  setDurationFilter,
  graphStartDate,
  graphEndDate,
  handleArrowButtonClick,
  showProgressPercentage,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const rootAssetUuid = useSelector(selectRootAssetUuid);
  const assets = useSelector(selectAssets);
  const settingsData = useSelector(selectSettingsData);
  const configType = useSelector(selectConfigType);
  const isSidebarOpened = useSelector(selectIsModalOpened(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
  const isCanaryNetwork = configType === 'CANARY_NETWORK';

  const assetsCount = useMemo(() => {
    const assetsInsideCommunity = Object.keys(assets).filter(
      (uuid) => assets[uuid].uuid !== rootAssetUuid && assets[uuid].parentUuid !== rootAssetUuid,
    );
    return assetsInsideCommunity.length;
  }, [assets, rootAssetUuid]);

  const runButtonTheme = useMemo(() => {
    switch (runButtonState) {
      case 'run':
      case 'resume':
      case 'rerun':
        return 'primary';
      case 'pause':
        return 'secondary';
      case 'processing':
        return 'grey';
    }
  }, [runButtonState]);

  const timeElapsed = useMemo(() => {
    const seconds = simulationProgress?.elapsedTimeSeconds;
    if (!simulationProgress || !seconds) return null;
    if (seconds > 60)
      return Math.round(seconds / 60) + ' min ' + (seconds % 60 ? (seconds % 60) + ' sec' : '');
    return seconds + ' sec';
  }, [simulationProgress]);

  const simulationTime = useMemo(() => {
    const startDate = UTCMoment.utc(settingsData.startDate);
    const endDate = UTCMoment.utc(settingsData.endDate);
    const dayDiff = endDate.diff(startDate, 'd');
    const currentDay = Math.min(
      dayDiff,
      Math.ceil((dayDiff / 100) * (simulationProgress?.percentageCompleted || 0)),
    );
    const dayDiffFromCurrentDate = UTCMoment.utc(new Date()).diff(startDate, 'd');
    return isCanaryNetwork ? `${dayDiffFromCurrentDate}` : `${currentDay} / ${dayDiff}`;
  }, [
    settingsData.endDate,
    settingsData.startDate,
    simulationProgress?.percentageCompleted,
    isCanaryNetwork,
  ]);

  useEffect(() => {
    if (containerRef) {
      setWidth(containerRef.current?.offsetWidth || 0);
    }
  }, [containerRef, isSidebarOpened]);

  return (
    <div className={s.container} ref={containerRef}>
      <div className={s.header}>
        <div>
          <span>{simulationStatusTitle}</span>
          {!!simulationProgress?.percentageCompleted && showProgressPercentage && (
            <span>{simulationProgress.percentageCompleted}%</span>
          )}
        </div>
        <div>
          <div className={s.simActionButtons}>
            <BaseButtonSquare
              size="2"
              theme={stopButtonOptions.disabled ? 'flat-gray-light' : 'flat-red'}
              icon={stopButtonOptions.icon}
              svgSize="3"
              disabled={stopButtonOptions.disabled}
              onClick={() => stopButtonOptions.onClick && stopButtonOptions.onClick()}
            />
            <BaseButton
              size="small"
              theme={runButtonTheme}
              className={s.actionButton}
              disabled={runButtonOptions.disabled}
              onClick={() => runButtonOptions.onClick && runButtonOptions.onClick()}>
              {runButtonOptions.title}
            </BaseButton>
            {isCanaryNetwork && (
              <TimelineFilters
                filter={filter}
                setFilter={setFilter}
                durationFilter={durationFilter}
                setDurationFilter={setDurationFilter}
              />
            )}
          </div>
          <div className={s.modalControlButtons}>
            <BaseButtonSquare
              size="2"
              theme="flat-gray-light"
              icon="minimize"
              svgSize="3"
              onClick={toggleExpanded}
            />
          </div>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.detailsAndControls}>
          <ul>
            <li>
              <span>Simulation time:</span> {simulationTime} days
            </li>
            {!!timeElapsed && (
              <li>
                <span>Time elapsed:</span> {timeElapsed}
              </li>
            )}
            <li>
              <span>Nodes added:</span> {assetsCount}/1000
            </li>
          </ul>
        </div>
        <div className={s.graph}>
          <ChartSimulationProgress
            graphHeight={100}
            graphWidth={width}
            filter={filter}
            durationFilter={durationFilter}
            graphStartDate={graphStartDate}
            graphEndDate={graphEndDate}
          />
          {isCanaryNetwork && (
            <>
              <TimelineEventChart
                graphWidth={width}
                isSidebarOpened={isSidebarOpened}
                simulationStatusTitle={simulationStatusTitle}
                simulationProgress={simulationProgress}
                runButtonState={runButtonState}
                runButtonOptions={runButtonOptions}
                stopButtonOptions={stopButtonOptions}
                detailsData={detailsData}
                filter={filter}
                durationFilter={durationFilter}
                graphStartDate={graphStartDate}
                graphEndDate={graphEndDate}
              />
            </>
          )}
        </div>
      </div>
      {isCanaryNetwork && (
        <div className={s.arrrowButtonsWrapper}>
          <TimelineArrowButtons handleArrowButtonClick={handleArrowButtonClick} />
        </div>
      )}
    </div>
  );
};
