import React, { useState } from 'react';

import classnames from 'classnames';
import { TBaseErrorMessageProps } from 'src/components/BaseErrorMessage';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseInput } from 'src/components/BaseInput';
import { TBaseInputProps } from 'src/components/BaseInput';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { get2dAssetIcon } from 'src/utils/assetIcons';
import { TValidationObject, rules, validateFields } from 'src/utils/fieldValidation';

import s from './CoefficientAssetTile.module.scss';
import { TCoefficientAssetTileProps } from './CoefficientAssetTile.types';

export const CoefficientAssetTile: React.FC<TCoefficientAssetTileProps> = ({
  data,
  onAssetValueChange,
  disabled,
}) => {
  const validators = () => ({
    name: [...((n) => [rules.required(n)])('Name')],
    coefficientPercentage: [
      ...((n) => [rules.required(n), rules.floatString(n), rules.range(n, 0, 1)])(
        'coefficientPercentage',
      ),
    ],
  });
  const initFormData = {
    coefficientPercentage: data.coefficientPercentage || 0,
  };
  const errorWidth = '0';
  const [formData, setFormData] = useState(initFormData);
  const [validationErrors, setValidationErrors] = useState<
    {
      [key in keyof typeof initFormData]?: TBaseErrorMessageProps['children'];
    }
  >({});

  const handleOnChange: TBaseInputProps['onChange'] = ({ value, name }) => {
    if (typeof value !== 'string') return;

    setFormData((curr) => ({
      ...curr,
      [name]: value,
    }));
    onAssetValueChange?.(data.uuid!, parseFloat(value));
  };
  function handleFieldBlur(
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    validationsArr: TValidationObject[],
  ) {
    const { name } = e.target;
    const { errors } = validateFields({
      validators: {
        [name]: validationsArr,
      },
      formData,
    });

    if (errors) {
      if (errors[name]) {
        setValidationErrors((prevState) => ({
          ...prevState,
          [name]: errors[name],
        }));
      }
    } else {
      const newState = { ...validationErrors };
      delete newState[name];
      setValidationErrors(newState);
    }
  }

  return (
    <div className={s.container}>
      <div className={s.headingText}>
        <div className={s.row}>
          <BaseIcon className={s.assetIcon} icon={get2dAssetIcon('Area')} />
          <div>
            <div className={s.assetTitle}>{data.name}</div>
            <div className={s.assetUser}>{data.email}</div>
          </div>
        </div>
      </div>
      <div className={s.pill}>
        <FieldContainer className={classnames(s.inputField)}>
          <BaseInput
            className={s.input}
            showValidationIcon={false}
            value={formData.coefficientPercentage}
            name="coefficientPercentage"
            type="text"
            theme="filled-white"
            onChange={(val) => handleOnChange(val)}
            autoComplete="off"
            disabled={disabled}
            errorWidth={errorWidth}
            showErrorMessage={false}
            error={validationErrors.coefficientPercentage}
            onBlur={(e) => handleFieldBlur(e, validators().coefficientPercentage)}
          />
        </FieldContainer>
      </div>
    </div>
  );
};
