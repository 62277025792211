import React, { useMemo, useRef, useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import fileUpload from 'src/assets/images/Scm/icon-file-plus.png';
import { BaseButton } from 'src/components/BaseButton';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseLink } from 'src/components/BaseLink';
import { EActiveTabState } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { ReadConfigurationDocument, useUploadCommunityDatasheetMutation } from 'src/graphql';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useMapNavigation } from 'src/hooks/useMapNavigation';
import { setActiveSidebarMainTab } from 'src/redux/application/application.slice';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { setActiveConfigurationUuid } from 'src/redux/configuration/configuration.slice';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal, updateModalContent } from 'src/redux/modals/modals.slice';
import { setActiveSCMStep, setDataSheetResponse } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';

import { FileUploadDrop } from './components/FileUploadDrop';
import { TFileType } from './components/FileUploadDrop/FileUploadDrop.types';
import { downloadFile } from './downloadFile';
import s from './ModalUploadDataSheet.module.scss';

export const ModalUploadDataSheet: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { isCommunityAreaRepresentationEmpty, communityArea, areaUuidToArea } = useConfiguration();
  const { discardCurrentConfiguration, zoomIntoConfiguration } = useMapNavigation();

  const loadingModalData = useMemo(
    () => ({
      title: 'Processing Sheet',
      subTitle: 'Scanning Data',
      icon: 'file',
    }),
    [],
  );

  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const isModalDeployOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET),
  );

  const modalRef = useRef<HTMLDivElement>(null);
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const [file, setFile] = useState<TFileType>();

  const [uploadCommunityDataSheetMutation] = useUploadCommunityDatasheetMutation({
    errorPolicy: 'none',
    refetchQueries: [
      { query: ReadConfigurationDocument, variables: { uuid: activeConfigurationUuid } },
    ],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data && data?.uploadCommunityDatasheet) {
        batch(() => {
          dispatch(setDataSheetResponse(data.uploadCommunityDatasheet));
          dispatch(closeModal(EPredefinedModalIds.MODAL_DEPLOY_PROCESS));
          dispatch(setActiveSCMStep(1));
          dispatch(setActiveSidebarMainTab(EActiveTabState.Registry));
          history.push(routesConfig.scmMapResults(activeConfigurationUuid, communityArea?.uuid));
        });
        zoomIntoConfiguration({ assetsValues: areaUuidToArea });
      }
    },
    onError: (error) => {
      const msg = error && error?.message ? error.message : 'Something went wrong';
      const modalData = {
        ...loadingModalData,
        title: 'Upload Failed',
        subTitle: msg,
        icon: 'close',
      };
      dispatch(updateModalContent(modalData));
    },
  });

  const closeUploadDataSheetModal = () => {
    batch(() => {
      dispatch(closeModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
      if (isCommunityAreaRepresentationEmpty) {
        dispatch(setActiveConfigurationUuid(undefined));
        discardCurrentConfiguration({ zoomOut: true });
        history.push(routesConfig.scmMap());
      }
    });
  };

  const handleDownloadModelSheet = async (e) => {
    await downloadFile('CommunityDataSheet.xlsx');
    e.stopPropagation();
  };

  const handleUploadDataSheet = () => {
    uploadCommunityDataSheetMutation({
      variables: {
        configUuid: activeConfigurationUuid as string,
        file,
      },
    });
    dispatch(closeModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
    const modalData = {
      ...loadingModalData,
      title: 'Processing Sheet',
    };
    dispatch(updateModalContent(modalData));
    dispatch(openModal(EPredefinedModalIds.MODAL_DEPLOY_PROCESS));
  };

  if (!isModalDeployOpened) return null;

  return (
    <>
      <a ref={downloadLinkRef} href="/dataSheet/CommunityDataSheet.xlsx" target="_blank" download />
      <div className={s.background} onClick={closeUploadDataSheetModal} />
      <BaseGenericModal
        size={EModalSize.XL820}
        modalId={EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET}
        className={s.modal}
        modalRef={modalRef}>
        <div className={classnames(s.modalContentWrapper, s.modalUploadDataSheet)}>
          <header className={s.header}>
            <h2 className={s.modalTitle}>{t('tooltips.UPLOAD_YOUR_COMMUNITY_DATASHEET')}</h2>
            <p>{t('tooltips.ADD_MULTIPLE_HOMES_FAST_CUSTOMISE_IT_LATER')}</p>
          </header>
          <div className={s.body}>
            <FileUploadDrop handleChange={(file) => setFile(file[0])}>
              <div className={s.uploadSection}>
                <img src={fileUpload} />
                <div className={s.title}>
                  {file ? file?.name : t('commands.DROP_YOUR_FILE_HERE')}
                </div>
                <div className={s.subTitle}>{file ? file?.type : 'Format: Xcel/CSV'}</div>
              </div>
            </FileUploadDrop>
            <BaseLink onClick={handleDownloadModelSheet} className={s.downloadLink}>
              {t('commands.DOWNLOAD_MODEL_SHEET')}
            </BaseLink>
            <BaseButton
              disabled={!Boolean(file)}
              className={s.uploadBtn}
              onClick={handleUploadDataSheet}>
              {t('commands.UPLOAD')}
            </BaseButton>
            <BaseLink to={'https://youtu.be/83aoZ6RHDck'} target="_blank" className={s.plainLink}>
              {t('commands.WATCH_COMMUNITY_DATASHEET_TUTORIAL')}
            </BaseLink>
          </div>
        </div>
      </BaseGenericModal>
    </>
  );
};
