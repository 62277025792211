import React from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseProgress } from 'src/components/BaseProgress';
import { InfoHelper } from 'src/components/InfoHelper';
import { TKeyResultProgressProps } from 'src/features/Results/SingleAreaResults/KeyResults/components/KeyResultProgress/KeyResultProgress.types';
import { selectSidebarExpanded } from 'src/redux/application/application.selectors';

import s from '../../KeyResults.module.scss';

export const KeyResultProgress: React.FC<TKeyResultProgressProps> = ({
  title,
  badges,
  percentage,
  className,
  info,
}) => {
  const sidebarExpanded = useSelector(selectSidebarExpanded);

  return (
    <div className={classNames(s.assetInfo, s.block, className)}>
      <header>
        <h3 className={s.earningStatus}>{title}</h3>
        <InfoHelper info={info} position="bottom" />
      </header>
      <div className={s.cost}>
        <div className={s.progressWrapper}>
          <BaseProgress
            showProgress={true}
            className={s.progressBenchmark}
            progress={percentage}
            barClassName={s.progressBenchmarkBar}
            expanded={sidebarExpanded}
          />
        </div>
        {badges.map((item) => (
          <BaseButton
            key={`${item.type}${item.count}index`}
            onClick={item.onClick}
            className={s.badge}>
            {`${item.count} ${item.type}${item.count > 1 ? 's' : ''}`}
          </BaseButton>
        ))}
      </div>
    </div>
  );
};
