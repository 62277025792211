import { ETabs } from 'src/components/Tabs';
import { TFieldsAssetArgs } from 'src/features/Assets/SingleAsset/SingleAsset.types';
import { ConfigType } from 'src/graphql';
import { TFieldsTemplateUnion } from 'src/utils/assetsFields/assetsFields.types';

const Battery = ({ configType }: TFieldsAssetArgs): TFieldsTemplateUnion[] => {
  return [
    {
      name: 'name',
      type: 'text',
      label: 'labels.ASSET_NAME',
      defaultValue: 'Battery',
      formView: ETabs.BASIC,
    },
    {
      name: 'prosumptionKWhProfile',
      type: 'file',
      label: 'labels.UPLOAD_PROFILE',
      tooltipText: 'Please upload storage prosumption profile here. ',
      formView: ETabs.BASIC,
      exclude: configType === ConfigType.CanaryNetwork,
    },
  ];
};

export default Battery;
