import React, { useContext } from 'react';

import WorldMapContext from 'src/contexts/WorldMapContext';
import { ListMembersTile } from 'src/features/Members/ListMembers/ListMembersTile';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useFlyTo } from 'src/hooks/useFlyTo';
import { setSelectedMemberUuid } from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';

import s from './ListMembers.module.scss';
import { TListMembersProps } from './ListMembers.types';

export const ListMembers: React.FC<TListMembersProps> = ({ searchValue }: TListMembersProps) => {
  const dispatch = useAppDispatch();

  const { mapService } = useContext(WorldMapContext);

  const { communityMembers } = useConfiguration();

  const { flyToAsset, assetFlyConfig } = useFlyTo({ mapService });

  const handleMemberClick = (memberUuid) => {
    if (memberUuid) {
      dispatch(setSelectedMemberUuid(memberUuid));
      flyToAsset(memberUuid, assetFlyConfig);
    }
  };

  return (
    <div className={s.usersList}>
      {communityMembers
        .filter((member) =>
          !!searchValue && searchValue.length > 0 ? member?.name?.includes(searchValue) : true,
        )
        .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? '', undefined, { numeric: true }))
        .map((member) => {
          return (
            <React.Fragment key={member.name}>
              <ListMembersTile data={member} handleClick={handleMemberClick} />
            </React.Fragment>
          );
        })}
    </div>
  );
};
