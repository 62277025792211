import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EResultsTab } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity/MapSidebarSingleCommunity.types';
import { ETabs } from 'src/components/Tabs';
import { TBillsDifference } from 'src/features/Results/SingleAreaResults';
import { MeasurementVerificationRequest } from 'src/graphql';
import { APP_FLOW } from 'src/hooks/useAppFlow';
import { ELsKey, ls } from 'src/utils/localStorage';

export type TSCMHome = {
  address: string;
  assetCount: number;
  coefficientPercentage: number;
  email: string;
  feedInTariff: number;
  fixedMonthlyFee: number;
  gridFeeConstant: number;
  marketMakerRate: number;
  marketplaceMonthlyFee: number;
  assistanceMonthlyFee: number;
  serviceMonthlyFee: number;
  contractedPowerMonthlyFee: number;
  contractedPowerCargoMonthlyFee: number;
  energyCargoFee: number;
  name: string;
  taxesSurcharges: number;
  electricityTax: number;
  uuid: string;
  zipCode: string;
  geoTagLocation: [number, number];
};

export type TMembers = {
  id?: string;
  name: string;
  email: string;
  address: string;
};

export type TMeasurementRequest = Pick<
  MeasurementVerificationRequest,
  'areaUuid' | 'configUuid' | 'status'
>;

export type TSCMState = {
  dataSheetResponse: unknown;
  activeSCMStep: number;
  isSCMFlow: boolean;
  isAppLoading: boolean;
  isOperationalCommunity: boolean;
  scmCommunityManager: string | undefined;
  scmHomeDetails: TSCMHome[];
  homeBillsDifferences: TBillsDifference | undefined;
  activeTabResults: EResultsTab;
  activeCommunityListTab: ETabs;
  newOperationalCommunityUuid: string | undefined;
  measurementRequests: Array<TMeasurementRequest>;
  activeLiveAccumulatedSubscriptionId: string | undefined;
  preventPushResultsScreen: boolean;
  unreadMessageCount: number | null | undefined;
};

const scmFlow = ls.get(ELsKey.APP_FLOW) === APP_FLOW.SCM;

export const initialSCMState: TSCMState = {
  dataSheetResponse: null,
  activeSCMStep: 0,
  isSCMFlow: scmFlow,
  isAppLoading: false,
  isOperationalCommunity: false,
  scmCommunityManager: 'contact@gridsingularity.com',
  scmHomeDetails: [],
  homeBillsDifferences: undefined,
  activeTabResults: EResultsTab.Home,
  activeCommunityListTab: ETabs.SIMULATIONS,
  newOperationalCommunityUuid: undefined,
  measurementRequests: [],
  activeLiveAccumulatedSubscriptionId: undefined,
  preventPushResultsScreen: false,
  unreadMessageCount: 0,
};

const scmSlice = createSlice({
  name: 'scm',
  initialState: initialSCMState,
  reducers: {
    setDataSheetResponse(state, action: PayloadAction<TSCMState['dataSheetResponse']>) {
      state.dataSheetResponse = action.payload;
    },
    setActiveSCMStep(state, action: PayloadAction<TSCMState['activeSCMStep']>) {
      state.activeSCMStep = action.payload;
      ls.set(ELsKey.SCM_STEP, String(action.payload));
    },
    setSCMFlow(state, action: PayloadAction<TSCMState['isSCMFlow']>) {
      state.isSCMFlow = action.payload;
    },
    setIsAppLoading(state, action: PayloadAction<TSCMState['isAppLoading']>) {
      state.isAppLoading = action.payload;
    },
    setSCMCommunityManager(state, action: PayloadAction<TSCMState['scmCommunityManager']>) {
      state.scmCommunityManager = action.payload;
    },

    setCommunityMembers(state, action: PayloadAction<TSCMState['scmHomeDetails']>) {
      state.scmHomeDetails = action.payload;
    },
    setHomeBillsDifferences(state, action: PayloadAction<TSCMState['homeBillsDifferences']>) {
      state.homeBillsDifferences = action.payload;
    },
    setActiveTabResults(state, action: PayloadAction<TSCMState['activeTabResults']>) {
      state.activeTabResults = action.payload;
    },
    setActiveCommunityListTab(state, action: PayloadAction<TSCMState['activeCommunityListTab']>) {
      state.activeCommunityListTab = action.payload;
    },
    setNewOperationalCommunityUuid(
      state,
      action: PayloadAction<TSCMState['newOperationalCommunityUuid']>,
    ) {
      state.newOperationalCommunityUuid = action.payload;
    },
    setIsOperationalCommunity(state, action: PayloadAction<TSCMState['isOperationalCommunity']>) {
      state.isOperationalCommunity = action.payload;
    },
    setMeasurementRequests(state, action: PayloadAction<TSCMState['measurementRequests']>) {
      state.measurementRequests = action.payload;
    },
    setActiveLiveAccumulatedSubscriptionId(
      state,
      action: PayloadAction<TSCMState['activeLiveAccumulatedSubscriptionId']>,
    ) {
      state.activeLiveAccumulatedSubscriptionId = action.payload;
    },
    clearSCMResults(state) {
      state.homeBillsDifferences = undefined;
      state.preventPushResultsScreen = false;
    },
    setPreventPushResultsScreen(
      state,
      action: PayloadAction<TSCMState['preventPushResultsScreen']>,
    ) {
      state.preventPushResultsScreen = action.payload;
    },
    setUnreadMessageCount(state, action: PayloadAction<TSCMState['unreadMessageCount']>) {
      state.unreadMessageCount = action.payload;
    },
  },
});

// Actions
export const {
  setDataSheetResponse,
  setActiveSCMStep,
  setSCMFlow,
  setIsAppLoading,
  setIsOperationalCommunity,
  setSCMCommunityManager,
  setCommunityMembers,
  setHomeBillsDifferences,
  setActiveTabResults,
  setActiveCommunityListTab,
  setNewOperationalCommunityUuid,
  setMeasurementRequests,
  setActiveLiveAccumulatedSubscriptionId,
  clearSCMResults,
  setPreventPushResultsScreen,
  setUnreadMessageCount,
} = scmSlice.actions;

// Reducer
export const scmReducer = scmSlice.reducer;
