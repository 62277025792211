import { TFormAssetsParamsProps } from 'src/components/FormAssetsParams';
import { TFormFieldsGeneratorProps } from 'src/components/FormFieldsGenerator';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { EFormVariant } from 'src/typings/base-types';
import { TConfigurationCharacteristic } from 'src/typings/configuration.types';
import { TCommunitySettingsFields, TSettingsData } from 'src/utils/assetsFields/assetsFields.types';
import {
  TFieldValuesByName,
  TValuesByFieldName,
} from 'src/utils/assetsFields/valuesByFieldName.types';

export type TSettingsSaveProps = {
  settingsData: TSettingsData;
  communityAssetSettings: Pick<
    NonNullable<TValuesByFieldName>,
    'gridFeeConstant' | 'useMarketMakerRate' | 'feedInTariff'
  >;
} & Pick<TConfigurationState, 'timezone'>;

export type TRelatedFormFields = Array<{
  fieldName: keyof TValuesByFieldName;
  relatedFields: Array<keyof TValuesByFieldName>;
}>;

export type TFormFieldsArgs = {
  values: Omit<TCommunitySettingsFields, 'startEndDate'> & Partial<TFieldValuesByName<'Area'>>;
  configurationCharacteristic: TConfigurationCharacteristic;
};

export type TFormSettingsDataProps = {
  className?: string;
  hasErrorsRef?: React.MutableRefObject<boolean>;
  formVariant?: EFormVariant;
  theme?: TFormFieldsGeneratorProps['theme'];
  onSubmit(payload: TSettingsSaveProps): void;
} & Omit<
  TFormFieldsGeneratorProps,
  'fields' | 'onChange' | 'errors' | 'onSubmit' | 'focusField' | 'readOnly'
>;

export type TMapSidebarSingleCommunityProps = {
  className?: string;
  style?: React.CSSProperties;
  showHeaderTabs?: boolean;
  onAssetValuesSave?: TFormAssetsParamsProps['onSubmit'];
  onAssetValuesSavePromise?: TFormAssetsParamsProps['onSubmitPromise'];
  onCommunityRemove?({
    projectUuid,
  }: {
    projectUuid: NonNullable<TConfigurationState['projectUuid']>;
  }): Promise<void>;
};
export enum EResultsTab {
  Home = 'Home',
  Community = 'Community',
}

export enum EActiveTabState {
  Community = 'community',
  Settings = 'settings',
  Registry = 'registry',
  CollaborationSettings = 'collaborationSettings',
  Profile = 'profile',
  GridMarketResults = 'gridmarketresults',
  GridMarketSettings = 'gridmarketsettings',
  Results = 'results',
  MessagingPortal = 'messagingPortal',
}

export type TMapSidebarSingleCommunityTabsProps = {
  activeTab: EActiveTabState;
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleTabChange: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleResultsTabClick: Function;
};
