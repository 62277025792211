import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { CanaryRegistryList } from 'src/components/MapSidebar/components/ListMembersAndAssets/components/RegistryList';
import { UserInvite } from 'src/components/MapSidebar/components/ListMembersAndAssets/components/UserInvite';
import { EditAsset } from 'src/features/Assets/EditAsset';
import { NewAsset } from 'src/features/Assets/NewAsset';
import { SingleArea } from 'src/features/Assets/SingleArea';
import { NewMember } from 'src/features/Members/NewMember';
import { SingleMember } from 'src/features/Members/SingleMember';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { selectSelectedMemberUuid } from 'src/redux/application/application.selectors';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import {
  selectSelectedAreaUuid,
  selectSelectedAssetUuid,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { EUserRoles } from 'src/typings/base-types';

import s from './ListMembersAndAssets.module.scss';

export enum ECommunityTabs {
  CommunityMembers = 'COMMUNITY_MEMBERS',
  CommunityAssets = 'COMMUNITY_ASSETS',
}
export const ListMembersAndAssets: React.FC = () => {
  const [activeTab, setActiveTab] = useState<ECommunityTabs>(ECommunityTabs.CommunityMembers);
  const [addWrapperTop, setAddWrapperTop] = useState(0);

  const userRole = useSelector(selectUserRole);
  const isOperationCommunity = useSelector(selectIsOperationalCommunity);
  const simulationStatus = useSelector(selectSimulationStatus);
  const selectedMemberUuid = useSelector(selectSelectedMemberUuid);
  const selectedAreaUuid = useSelector(selectSelectedAreaUuid);
  const selectedAssetUuid = useSelector(selectSelectedAssetUuid);

  const { isAdminInspecting } = useIsAdminInspecting();
  const { communityArea } = useConfiguration();

  const registryWrapperRef = useRef<HTMLDivElement>(null);

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    setAddWrapperTop(scrollTop);
  };

  useEffect(() => {
    if (!registryWrapperRef || !registryWrapperRef.current) return;
    registryWrapperRef.current.removeEventListener('scroll', handleScroll);
    //const relatedListener = registryWrapperRef.current.addEventListener('scroll', handleScroll);

    return () => {
      if (!registryWrapperRef || !registryWrapperRef.current) return;
      // remove the event listener with the same reference
      // eslint-disable-next-line react-hooks/exhaustive-deps
      registryWrapperRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [registryWrapperRef]);

  const renderSidebarContent = () => {
    if (selectedAssetUuid === 'NEW_ASSET') {
      return <NewAsset />;
    } else if (!!selectedAssetUuid) {
      return <EditAsset />;
    } else if (!!selectedAreaUuid && selectedAreaUuid !== communityArea?.uuid) {
      return <SingleArea />;
    } else if (selectedMemberUuid === 'NEW_MEMBER') {
      return <NewMember />;
    } else if (!!selectedMemberUuid) {
      return (
        <div className={s.withMargin}>
          <SingleMember />
        </div>
      );
    } else {
      return (
        <div className={s.withMargin}>
          {((userRole === EUserRoles.Admin && !isAdminInspecting) ||
            userRole === EUserRoles.ExchangeOperator) && (
            <div
              className={classnames({
                [s.disableContainer]: simulationStatus == 'running' && !isOperationCommunity,
              })}>
              <UserInvite wrapperTop={addWrapperTop} />
            </div>
          )}
          <CanaryRegistryList
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            wrapperTop={addWrapperTop}
          />
        </div>
      );
    }
  };

  return (
    <div className={s.registry} ref={registryWrapperRef}>
      <div className={s.container}>{renderSidebarContent()}</div>
    </div>
  );
};
