import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseSwitch } from 'src/components/BaseSwitch';
import { SWITCHER_ICON_SIZES } from 'src/constants/application';
import {
  selectConfiguration,
  selectReadOnly,
} from 'src/redux/configuration/configuration.selectors';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { routesConfig } from 'src/routes/routes.config';

import { BaseClose } from '../BaseClose';
import s from './ShareSimulation.module.scss';
import { TShareSimulationProps } from './ShareSimulation.types';

export const ShareSimulation: React.FC<TShareSimulationProps> = (props) => {
  const { locationVisible } = useSelector(selectConfiguration);

  const [linkCopied, setLinkCopied] = useState(false);
  const configurationUuid = useSelector(selectActiveConfigurationUuid);
  const readOnly = useSelector(selectReadOnly);
  useEffect(() => {
    let timerId;

    if (linkCopied) {
      timerId = setTimeout(() => setLinkCopied(false), 1000 * 2);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [linkCopied]);

  const urlForSharing = () => {
    return location.origin + routesConfig.embed(configurationUuid);
  };

  function copyLink() {
    setLinkCopied(true);
    navigator.clipboard.writeText(urlForSharing());
  }

  return (
    <>
      <div className={s.background} />
      <div className={s.main}>
        <BaseClose className={s.closeBtn} onClick={() => props.close()} />
        <div className={s.wrapper}>
          <h3>Share my results</h3>
          <div className={s.header}>
            <div className={s.status}>
              <span className={s.yourSimulation}>
                Your Simulation is
                <span className={locationVisible ? s.textStatusGreen : s.textStatusPink}>
                  {locationVisible ? ' Public' : ' Private'}
                </span>
              </span>
              <span className={classnames(s.description, locationVisible ? s.greenText : '')}>
                {locationVisible
                  ? 'This means that anyone can view your simulation results'
                  : 'Make it Public to enable sharing'}
              </span>
            </div>
            <BaseSwitch
              name="peak-analysis"
              options={[
                { icon: 'globe-no', iconSize: SWITCHER_ICON_SIZES.close, value: true },
                { icon: 'globe', iconSize: SWITCHER_ICON_SIZES.close, value: false },
              ]}
              theme={locationVisible ? 'green-contrast' : 'softred-contrast'}
              variant="horizontal-small"
              disabled={readOnly}
              value={!locationVisible}
              onChange={() => null}
            />
          </div>

          {/* <div className={s.textLink}>
            <span className={locationVisible ? '' : s.textDisabled}>Get Link </span>
          </div> */}
          <div className={s.buttonGetLink}>
            <BaseButton
              className={s.buttonCopyLink}
              icon="copy"
              size="small"
              type="submit"
              theme="quaternary"
              disabled={!locationVisible}
              onClick={() => copyLink()}>
              Copy Link
            </BaseButton>
          </div>
          {linkCopied && (
            <div className={s.linkCopiedBox}>
              <span>Link copied to clipboard</span>
              <BaseIcon className={s.icon} size={12} icon="circle-checked" />
            </div>
          )}
        </div>

        <div className={s.footer}>
          {/* <span className={classnames(s.footerTitle, locationVisible ? '' : s.textDisabled)}>
            Or share directly
          </span> */}
          <div className={classnames(s.shareActions, locationVisible ? '' : s.textDisabled)}>
            <LinkedinShareButton url={urlForSharing()}>
              <div className={s.circleIcons}>
                <BaseIcon size={16} icon="linkedin-outline" />
              </div>
            </LinkedinShareButton>

            <TwitterShareButton url={urlForSharing()}>
              <div className={s.circleIcons}>
                <BaseIcon size={16} icon="x" />
              </div>
            </TwitterShareButton>

            <FacebookShareButton url={urlForSharing()}>
              <div className={s.circleIcons}>
                <BaseIcon className="icon" size={16} icon="facebook-button" />
              </div>
            </FacebookShareButton>
          </div>
        </div>
      </div>
    </>
  );
};
