import { useCallback, useContext } from 'react';

import { QueryLazyOptions } from '@apollo/client';
import { useSelector } from 'react-redux';
import { TAssetsSaveProps } from 'src/components/FormAssetsParams';
import WorldMapContext from 'src/contexts/WorldMapContext';
import {
  Exact,
  ReadConfigurationQuery,
  useCreateAreaLiveEventMutation,
  useCreateCommunityMemberMutation,
  useReadConfigurationLazyQuery,
  useRemoveCommunityMemberMutation,
} from 'src/graphql';
import useBuildSavingTreeBranch from 'src/hooks/useBuildSavingTreeBranch';
import { useFlyTo } from 'src/hooks/useFlyTo';
import { setSelectedMemberUuid } from 'src/redux/application/application.slice';
import {
  selectActiveConfigurationUuid,
  selectCommunityAsset,
  selectCreatedSCMMemberUUID,
} from 'src/redux/configuration/configuration.selectors';
import {
  setConfiguration,
  setCreatedSCMMemberUUID,
  setDestructedConfigTree,
} from 'src/redux/configuration/configuration.slice';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { TSCMHome, setIsAppLoading, setCommunityMembers } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';
import { destructureConfigTree } from 'src/utils/configuration/destructureConfigTree';
import { getCommunityAsset } from 'src/utils/configuration/getCommunityAsset';

export type TuseMemberEventsProps = {
  toggleAssetView?: () => void;
  backToPreview?: () => void;
  handleReadConfigurationOnCompleted?: (data: ReadConfigurationQuery) => void;
};

export type TuseMemberEventsReturn = {
  handleReadConfiguration: (
    options?:
      | QueryLazyOptions<
          Exact<{
            uuid: string;
          }>
        >
      | undefined,
  ) => void;
  handleRemoveCommunityMember: (uuid: string) => void;
  handleCreateCommunityMember: (data: Record<string, string | undefined>) => void;
  handleCreateAreaLiveEvent: (args: {
    data: TAssetsSaveProps['values'];
    connectorAssetUuid: string;
    chosenAsset: any;
  }) => void;
};

export const useMemberEvents = ({
  //backToPreview,
  toggleAssetView,
  handleReadConfigurationOnCompleted,
}: TuseMemberEventsProps): TuseMemberEventsReturn => {
  const dispatch = useAppDispatch();
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const { mapService } = useContext(WorldMapContext);
  const communityAsset = useSelector(selectCommunityAsset);
  const createdSCMMemberUUID = useSelector(selectCreatedSCMMemberUUID);

  const { flyWithCoordinates, assetFlyConfig } = useFlyTo({ mapService });

  const setHomesToStoreAfterMemberAction = useCallback(
    (data: ReadConfigurationQuery) => {
      const results = data.readConfiguration;
      if (results) {
        const { scenarioData } = results;
        const configTree = destructureConfigTree(scenarioData?.latest?.serialized);
        const { assets, assetsTreeRelations, rootAssetUuid, assetsValues } = configTree;

        dispatch(
          setDestructedConfigTree({ assets, assetsTreeRelations, rootAssetUuid, assetsValues }),
        );
      }
      const scmHomeDetails = data.readConfiguration?.scenarioData?.homeInfo?.scmHomeDetails;
      if (scmHomeDetails) dispatch(setCommunityMembers(scmHomeDetails as TSCMHome[]));
    },
    [dispatch],
  );

  const setHomesToStore = useCallback(
    (data: ReadConfigurationQuery) => {
      const results = data.readConfiguration;
      if (results) {
        const { scenarioData, timezone, timestamp, project, settingsData, user } = results;
        const configTree = destructureConfigTree(scenarioData?.latest?.serialized);
        const { assets, assetsTreeRelations, rootAssetUuid, assetsValues } = configTree;
        const communityAssetUuid = getCommunityAsset({
          assets,
          rootAssetUuid,
          assetsTreeRelations,
          assetsValues,
        })?.uuid;
        dispatch(
          setConfiguration({
            user: user || '',
            timezone: timezone || '',
            projectUuid: project?.uuid || '',
            assets,
            assetsTreeRelations,
            assetsValues,
            rootAssetUuid,
            timestamp,
            selectedAssetUuid: communityAssetUuid,
            // TODO: Fix the type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            settingsData: settingsData || (undefined as any),
          }),
        );
      }
      const scmHomeDetails = data.readConfiguration?.scenarioData?.homeInfo?.scmHomeDetails;
      if (scmHomeDetails) dispatch(setCommunityMembers(scmHomeDetails as TSCMHome[]));
    },
    [dispatch],
  );

  const [handleReadConfiguration] = useReadConfigurationLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted(data) {
      setHomesToStore(data);
      if (handleReadConfigurationOnCompleted) handleReadConfigurationOnCompleted(data);
      dispatch(setIsAppLoading(false));
    },
  });

  const [handleReadConfigurationAfterAddingMember] = useReadConfigurationLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setHomesToStoreAfterMemberAction(data);
      if (createdSCMMemberUUID) {
        const results = data.readConfiguration;
        if (!results) return;
        const { scenarioData } = results;
        const configTree = destructureConfigTree(scenarioData?.latest?.serialized);
        const { assetsValues } = configTree;
        const coordinates = assetsValues[createdSCMMemberUUID]?.geoTagLocation;
        if (!coordinates) return;
        setTimeout(() => flyWithCoordinates(coordinates, assetFlyConfig), 500);
      }
      dispatch(setIsAppLoading(false));
      dispatch(setSelectedMemberUuid(createdSCMMemberUUID));
    },
  });

  const [handleReadConfigurationAfterRemoveMember] = useReadConfigurationLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      dispatch(setIsAppLoading(false));
      if (toggleAssetView) toggleAssetView();
      setHomesToStoreAfterMemberAction(data);
      dispatch(setSelectedMemberUuid(null));
      dispatch(setSelectedAssetUuid(undefined));
    },
  });

  const [removeCommunityMemberMutation] = useRemoveCommunityMemberMutation({
    onCompleted: () => {
      dispatch(setIsAppLoading(false));
      if (activeConfigurationUuid)
        handleReadConfigurationAfterRemoveMember({
          variables: { uuid: activeConfigurationUuid },
        });
    },
    onError: (error) => {
      dispatch(setIsAppLoading(false));
      const message = JSON.parse(error.graphQLErrors[0].message);
      const messageKeys = Object.keys(message);
      if (messageKeys.length === 0) return;

      dispatch(
        openToast({
          message: message[messageKeys[0]],
          type: 'error',
        }),
      );
    },
  });

  const [createAreaLiveMutation] = useCreateAreaLiveEventMutation();

  const [createCommunityMemberMutation] = useCreateCommunityMemberMutation({
    onCompleted: (result) => {
      if (result && result.createCommunityMember && result.createCommunityMember.uuid) {
        const newMemberUuid = result.createCommunityMember.uuid;
        dispatch(setCreatedSCMMemberUUID(newMemberUuid));
        handleReadConfigurationAfterAddingMember({
          variables: { uuid: activeConfigurationUuid || '' },
        });
      }
    },
  });

  const {
    createAreaInputFromSavingTreeBranch,
    createSavingTreeBranch,
  } = useBuildSavingTreeBranch();

  const handleCreateAreaLiveEvent = useCallback<
    (args: {
      data: TAssetsSaveProps['values'];
      connectorAssetUuid: string;
      chosenAsset: any;
    }) => void
  >(
    ({ data, connectorAssetUuid, chosenAsset }) => {
      const savingTreeBranch = createSavingTreeBranch(data, connectorAssetUuid, chosenAsset);

      createAreaLiveMutation({
        variables: {
          areaRepresentation: createAreaInputFromSavingTreeBranch(savingTreeBranch, communityAsset),
          configurationUuid: activeConfigurationUuid || '',
          parentUuid: connectorAssetUuid,
        },
      })
        .then((result) => {
          if (result.data?.createAreaLiveEvent?.latestConfig?.uuid) {
            const configUuid = result.data.createAreaLiveEvent.latestConfig.uuid;

            if (!configUuid) return;

            handleReadConfigurationAfterRemoveMember({
              variables: { uuid: configUuid },
            });

            dispatch(
              openToast({
                message: 'Asset created successfully',
                type: 'success',
              }),
            );
          }
        })
        .catch((error) => {
          if (error)
            dispatch(
              openToast({
                message: error,
                type: 'error',
              }),
            );
        });
    },
    [
      createAreaLiveMutation,
      createAreaInputFromSavingTreeBranch,
      activeConfigurationUuid,
      communityAsset,
      createSavingTreeBranch,
      dispatch,
      handleReadConfigurationAfterRemoveMember,
    ],
  );

  const handleRemoveCommunityMember = useCallback<(uuid: string) => void>(
    (uuid) => {
      if (!activeConfigurationUuid) return;
      dispatch(setIsAppLoading(true));
      removeCommunityMemberMutation({
        variables: {
          memberUuid: uuid,
          configUuid: activeConfigurationUuid,
        },
      });
    },
    [activeConfigurationUuid, removeCommunityMemberMutation, dispatch],
  );

  const handleCreateCommunityMember = useCallback<
    (data: Record<string, string | undefined>) => void
  >(
    (data) => {
      dispatch(setIsAppLoading(true));
      createCommunityMemberMutation({
        variables: {
          name: data.name || '',
          email: data.email || '',
          zipcode: data.zipCode || '',
          address: data.address || '',
          configUuid: activeConfigurationUuid || '',
          marketMakerRate: 0,
          feedInTariff: 0,
          gridFee: 0,
          taxesSurcharges: 0,
          electricityTax: 0,
          fixedMonthlyFee: 0,
          marketplaceMonthlyFee: 0,
          assistanceMonthlyFee: 0,
          serviceMonthlyFee: 0,
          contractedPowerMonthlyFee: 0,
          contractedPowerCargoMonthlyFee: 0,
          energyCargoFee: 0,
        },
      });
    },
    [activeConfigurationUuid, createCommunityMemberMutation, dispatch],
  );

  return {
    handleReadConfiguration,
    handleRemoveCommunityMember,
    handleCreateCommunityMember,
    handleCreateAreaLiveEvent,
  };
};
