import React, { useMemo } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { TAssetPopupProps } from 'src/components/AssetPopup/AssetPopup.types';
import { BaseIcon } from 'src/components/BaseIcon';
import { ConfigurationOutput, useReadConfigurationQuery } from 'src/graphql';
import { selectSelectedMemberUuid } from 'src/redux/application/application.selectors';
import {
  selectActiveConfigurationUuid,
  selectSelectedAreaUuid,
} from 'src/redux/configuration/configuration.selectors';
import { get2dAssetIcon } from 'src/utils/assetIcons';
import { formatter } from 'src/utils/formatter';
import { flattenChildren } from 'src/utils/general';
import { objectCamelCase } from 'src/utils/objectCamelCase';

import s from './AssetPopup.module.scss';

export const AssetPopup: React.FC<TAssetPopupProps> = ({ showMemberAddress }) => {
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const selectedAreaUuid = useSelector(selectSelectedAreaUuid);
  const selectedMemberUuid = useSelector(selectSelectedMemberUuid);

  const { data: configurationResponse } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !configUuid,
    variables: { uuid: configUuid! },
  });
  const configuration: ConfigurationOutput | null = useMemo(
    () => configurationResponse?.readConfiguration as ConfigurationOutput | null,
    [configurationResponse],
  );
  const communityArea = useMemo(() => {
    const scenario = configuration?.scenarioData?.latest?.serialized
      ? JSON.parse(configuration.scenarioData.latest.serialized)
      : null;

    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      !!scenario && objectCamelCase<any>(scenario?.children?.find((child) => child.type === 'Area'))
    );
  }, [configuration]);

  const area = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return objectCamelCase<any>(
      communityArea?.children?.find(
        (area) => area.uuid === selectedAreaUuid || area.uuid === selectedMemberUuid,
      ),
    );
  }, [communityArea, selectedAreaUuid, selectedMemberUuid]);

  const isAreaSelected =
    (!!selectedAreaUuid || !!selectedMemberUuid) && selectedMemberUuid !== 'NEW_MEMBER';

  const assets = useMemo(
    () =>
      isAreaSelected ? flattenChildren(area.children) : flattenChildren(communityArea?.children),
    [area, communityArea, isAreaSelected],
  );

  const assetTypes: Array<{ type: string; count: number }> = useMemo(() => {
    const groupedAssetsByType = assets.reduce((acc, asset) => {
      acc[asset.type] = ((acc[asset.type] || 0) + 1) as number;
      return acc;
    }, {} as Record<string, number>);
    return Object.entries(groupedAssetsByType).map(
      ([type, count]) => ({ type, count } as { type: string; count: number }),
    );
  }, [assets]);

  return (
    <div className={classNames(s.paddingAround, s.background, s.wrapper, s.modalTooltip)}>
      <header className={s.header}>
        <div className={s.typeIcon}>
          <BaseIcon icon={'house'} size={12} />
        </div>
        <div className={s.headerTextWrapper}>
          <h3 className={s.title}>{isAreaSelected ? area.name : configuration?.name}</h3>
          {showMemberAddress && <span className={s.address}>{area?.address}</span>}
        </div>
      </header>
      <ul className={s.assetsList}>
        {assetTypes.map((asset) => (
          <li key={asset.type}>
            <div className={s.assetSquareBtn}>
              <BaseIcon icon={get2dAssetIcon(asset.type)} size={16.25} />
              <label className={s.assetsAmount}>{formatter.prefixWithZeros(asset.count)}</label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
