import React, { useCallback, useContext, useRef, useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BuildCommunity from 'src/assets/images/build-community.png';
import { BaseButton } from 'src/components/BaseButton';
import { BaseClose } from 'src/components/BaseClose';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { LocationSearch, TLocationSearchChangeResult } from 'src/components/LocationSearch';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { useAppFlow } from 'src/hooks/useAppFlow';
import { useMapNavigation } from 'src/hooks/useMapNavigation';
import { setGuideStep, setOnBoardingStep } from 'src/redux/application/application.slice';
import { setActiveConfigurationUuid } from 'src/redux/configuration/configuration.slice';
import { updateSelectedLocation } from 'src/redux/map/map.slice';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { setActiveSCMStep, setDataSheetResponse } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';

import s from './ModalBuildCommunity.module.scss';

export const ModalBuildCommunity: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { mapService } = useContext(WorldMapContext);

  const modalRef = useRef<HTMLDivElement>(null);

  const [location, setLocation] = useState<TLocationSearchChangeResult>();

  const isModalOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY),
  );

  const { resetSCMSteps } = useAppFlow();
  const { discardCurrentConfiguration } = useMapNavigation();

  const closeBuildCommunityModal = useCallback(() => {
    dispatch(closeModal(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY));
  }, [dispatch]);

  const handleCloseButton = useCallback(() => {
    dispatch(setOnBoardingStep(0));
    dispatch(setGuideStep(0));
    dispatch(closeModal(EPredefinedModalIds.MODAL_ONBOARDING));
    discardCurrentConfiguration({ zoomOut: true });

    resetSCMSteps();
    dispatch(setActiveSCMStep(0));
    dispatch(closeModal(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY));
    dispatch(closeModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
    dispatch(setDataSheetResponse(null));
  }, [dispatch, resetSCMSteps, discardCurrentConfiguration]);

  const handleLocationChange = (value) => {
    setLocation(value);
    dispatch(updateSelectedLocation(value));
  };

  const handleNextButtonClick = () => {
    closeBuildCommunityModal();
    dispatch(openModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
    dispatch(setActiveConfigurationUuid('NEW_COMMUNITY'));

    if (
      location &&
      location.place_type.includes('place') &&
      location.bbox &&
      location.bbox.length >= 4
    ) {
      mapService?.map.fitBounds(
        [
          [location.bbox[0], location.bbox[1]],
          [location.bbox[2], location.bbox[3]],
        ],
        {
          speed: 1.5,
        },
      );
    } else {
      mapService?.map.flyTo({
        center: location?.center as [number, number],
        speed: 1.5,
        zoom: 18,
      });
    }
  };

  if (!isModalOpened) return null;

  return (
    <>
      <BaseGenericModal
        size={EModalSize.XL820}
        modalId={EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY}
        className={s.modal}
        modalRef={modalRef}>
        <div className={classnames(s.modalContentWrapper, s.modalBuildCommunity)}>
          <div className={s.imgContainer}>
            <img src={BuildCommunity} alt="build-community" />
          </div>
          <div className={s.container}>
            <BaseClose className={s.closeBtn} onClick={handleCloseButton} />
            <div className={s.heading}>Build your community</div>
            <div className={s.subHeading}>Where is the community located?</div>
            <LocationSearch
              name="location-search"
              // value={scmLocation?.place_name}
              className={s.search}
              onChange={handleLocationChange}
              flyAfterSelect={false}
            />
            <BaseButton
              disabled={!Boolean(location)}
              className={s.nextBtn}
              onClick={handleNextButtonClick}>
              {t('commands.SAVE')}
            </BaseButton>
          </div>
        </div>
      </BaseGenericModal>
    </>
  );
};
