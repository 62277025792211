import React, { useEffect, useMemo } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseInput } from 'src/components/BaseInput';
import { TRegistryListHeaderProps } from 'src/components/MapSidebar/components/ListMembersAndAssets/components/RegistryList';
import { ECommunityTabs } from 'src/components/MapSidebar/components/ListMembersAndAssets/ListMembersAndAssets';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import { EUserRoles } from 'src/typings/base-types';

import s from './RegistryList.module.scss';

export const RegistryListHeader: React.FC<TRegistryListHeaderProps> = ({
  setActiveTab,
  activeTab,
  searchValue,
  setSearchValue,
}) => {
  const { t } = useTranslation();

  const userRole = useSelector(selectUserRole);
  const { isAdminInspecting, isOwnerAdmin } = useIsAdminInspecting();

  useEffect(() => {
    if (isAdminInspecting && !isOwnerAdmin) setActiveTab(ECommunityTabs.CommunityAssets);
  }, [isAdminInspecting, isOwnerAdmin, setActiveTab]);

  const TABS: ECommunityTabs[] = useMemo(
    () => [ECommunityTabs.CommunityMembers, ECommunityTabs.CommunityAssets],
    [],
  );

  const tabs =
    userRole && [EUserRoles.Researcher, EUserRoles.DSO].includes(userRole)
      ? [TABS[0]]
      : isAdminInspecting && !isOwnerAdmin
      ? [TABS[1]]
      : TABS;

  return (
    <header className={s.listHeader}>
      <div className={s.tabsSelecor}>
        {tabs.map((item, i) => (
          <button
            key={i}
            type="button"
            onClick={() => {
              setActiveTab(item);
            }}
            className={classNames({
              [s.active]: activeTab === item,
            })}>
            {t(`navigation.${item}`)}
          </button>
        ))}
      </div>
      <div className={s.searchAndSelect}>
        <BaseInput
          type="text"
          name="search"
          className={s.input}
          inputHeight="2"
          theme="filled-gray"
          placeholder="Search"
          value={searchValue}
          onChange={({ value }) => setSearchValue(`${value}`)}
        />
      </div>
    </header>
  );
};
