import { Maybe } from 'src/graphql';
import { v4 } from 'uuid';

export function formatSubtitle(
  areaCountNumber: Maybe<number> | undefined,
  deviceCountNumber: Maybe<number> | undefined,
): string {
  return `${typeof areaCountNumber === 'number' ? `Markets: ${areaCountNumber}` : ''} ${
    typeof deviceCountNumber === 'number' ? `Assets: ${deviceCountNumber}` : ''
  }`.trim();
}

type customParameterKeys =
  | 'isCustomPV'
  | 'isCustomHouse'
  | 'isCustomLoad'
  | 'isCustomHeatPump'
  | 'isCustomStorage';

const getCustomParameters = (key: customParameterKeys, value: boolean) => {
  const params = {
    isCustomHouse: true,
    isCustomPV: false,
    isCustomLoad: false,
    isCustomHeatPump: false,
    isCustomStorage: false,
  };

  params[key] = value;

  return params;
};

const customHouseUuid = v4();
export const customHouse = {
  libraryUuid: customHouseUuid,
  uuid: customHouseUuid,
  icon: 'house-3d',
  title: 'Home',
  subtitle: formatSubtitle(0, 0),
  type: 'Area',
  key: customHouseUuid,
  ...getCustomParameters('isCustomHouse', true),
  description: 'A home with no assets attached - configure and add custom assets',
  disabled: true,
};

const customPVUuid = v4();
export const customPV = {
  libraryUuid: customPVUuid,
  uuid: customPVUuid,
  icon: 'solar-3d',
  title: 'Custom PV',
  subtitle: formatSubtitle(0, 1),
  type: 'PV',
  key: customPVUuid,
  ...getCustomParameters('isCustomPV', true),
  description: `Add Custom PV \n By default the generation is calculated according to the location and capacity, powered by energydatamap.com`,
  disabled: false,
};

const customLoadUuid = v4();
export const customLoad = {
  libraryUuid: customLoadUuid,
  uuid: customLoadUuid,
  icon: 'plug-3d',
  title: 'Custom Load',
  subtitle: formatSubtitle(0, 1),
  type: 'Load',
  key: customLoadUuid,
  ...getCustomParameters('isCustomLoad', true),
  description: `Add Custom Load \n By default the generation is calculated according to the location and capacity, powered by energydatamap.com`,
  disabled: false,
};

const customHeatPumpUuid = v4();
export const customHeatPump = {
  libraryUuid: customHeatPumpUuid,
  uuid: customHeatPumpUuid,
  icon: 'heatpump-3d',
  title: 'Custom Heat Pump',
  subtitle: formatSubtitle(0, 1),
  type: 'ScmHeatPump',
  key: customHeatPumpUuid,
  ...getCustomParameters('isCustomHeatPump', true),
  description: `Add Custom Heat Pump \n By default the generation is calculated according to the location and capacity, powered by energydatamap.com`,
  disabled: false,
};

const customScmStorageUuid = v4();
export const customScmStorage = {
  libraryUuid: customScmStorageUuid,
  uuid: customScmStorageUuid,
  icon: 'battery-3d',
  title: 'Custom Battery',
  subtitle: formatSubtitle(0, 1),
  type: 'ScmStorage',
  key: customScmStorageUuid,
  ...getCustomParameters('isCustomStorage', true),
  description: `Add Custom Battery \n By default the generation is calculated according to the location and capacity, powered by energydatamap.com`,
  disabled: false,
};

export const allCustomAssets = [
  customHouse,
  customPV,
  customLoad,
  customHeatPump,
  customScmStorage,
];
