export function arrayToObject<T>(
  array: T[],
  keyProperty: keyof T,
  field?: keyof T,
): Record<string, T[keyof T] | T> {
  return array.reduce((acc, item) => {
    const key = String(item[keyProperty]);
    acc[key] = field ? (item[field] as T[keyof T]) : item;
    return acc;
  }, {} as Record<string, T[keyof T] | T>);
}

export function sumOfField<T>(data: T[], field: keyof T): number {
  return data.reduce((acc, obj) => {
    const value = obj[field];
    if (typeof value === 'number') {
      return acc + value;
    }
    return acc;
  }, 0);
}

export const areObjectsEqualByFields = <T extends Record<string, unknown>>(
  obj1: T | null | undefined,
  obj2: T | null | undefined,
  fields?: Array<keyof T>,
  ignoreFields?: Array<keyof T>,
): boolean => {
  if (!obj1 || !obj2) {
    return false;
  }

  const isObject = (obj: unknown): obj is Record<string, unknown> =>
    obj !== null && typeof obj === 'object';

  const shouldIgnoreField = (field: keyof T): boolean =>
    ignoreFields ? ignoreFields.includes(field) : false;

  if (fields && fields.length > 0) {
    for (const field of fields) {
      if (shouldIgnoreField(field)) {
        continue;
      }

      const value1 = obj1[field];
      const value2 = obj2[field];

      if (isObject(value1) && isObject(value2)) {
        if (!areObjectsEqualByFields(value1 as T, value2 as T, undefined, ignoreFields)) {
          return false;
        }
      } else if (value1 !== value2) {
        return false;
      }
    }
  } else {
    const keys1 = Object.keys(obj1 as Record<string, T>);
    const keys2 = Object.keys(obj2 as Record<string, T>);
    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (shouldIgnoreField(key as keyof T)) {
        continue;
      }

      const value1 = (obj1 as Record<string, unknown>)[key];
      const value2 = (obj2 as Record<string, unknown>)[key];
      if (isObject(value1) && isObject(value2)) {
        if (!areObjectsEqualByFields(value1 as T, value2 as T, undefined, ignoreFields)) {
          return false;
        }
      } else if (value1 !== value2) {
        return false;
      }
    }
  }

  return true;
};

export const areArraysOfObjectsEqualByFields = <T extends Record<string, unknown>>(
  array1: T[],
  array2: T[],
  fields?: Array<keyof T>,
): boolean => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    const obj1 = array1[i];
    const obj2 = array2[i];

    if (!areObjectsEqualByFields(obj1, obj2, fields)) {
      return false;
    }
  }

  return true;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const flattenChildren = (children: any) => {
  if (!Array.isArray(children)) {
    return [];
  }
  return children.reduce((acc, child) => {
    acc.push(child);
    if (child.children) {
      acc = acc.concat(flattenChildren(child.children));
    }
    return acc;
  }, []);
};
