import { ConfigType } from 'src/graphql';
import { EFormVariant } from 'src/typings/base-types';
import { TFieldsTemplateUnion } from 'src/utils/assetsFields/assetsFields.types';

export type TLoadArgs = {
  configType: ConfigType;
};

export const Load = ({ configType }: TLoadArgs): TFieldsTemplateUnion[] => {
  return [
    {
      name: 'name',
      type: 'text',
      label: 'labels.ASSET_NAME',
      defaultValue: 'Load',
      formView: EFormVariant.Express,
    },
    {
      name: 'dailyLoadProfile',
      type: 'file',
      label: 'labels.UPLOAD_PROFILE',
      tooltipText: 'tooltips.UPLOAD_LOAD_PROFILE_INFO',
      formView: EFormVariant.Advanced,
      disabled: configType === ConfigType.CanaryNetwork,
    },
  ];
};
