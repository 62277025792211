import React, { useMemo, useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { ASSET_TYPE_TO_FIELDS_PARAMS_COMPONENT } from 'src/features/Assets/constants';
import { SingleAsset } from 'src/features/Assets/SingleAsset';
import { useCreateConfigurationAreaMutation } from 'src/graphql';
import { ReadConfigurationDocument } from 'src/graphql';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { selectSelectedMemberUuid } from 'src/redux/application/application.selectors';
import {
  selectActiveConfigurationUuid,
  selectSelectedAreaUuid,
} from 'src/redux/configuration/configuration.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { TAssetType } from 'src/typings/base-types';
import { TAreaInput } from 'src/utils/mutationsGates';
import { dispatchSuccessToast, dispatchErrorToast } from 'src/utils/toasts';

import { ChooseAsset } from './ChooseAsset';
import s from './NewAsset.module.scss';

export const NewAsset: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [newAsset, setNewAsset] = useState<TAreaInput | null>(null);

  const configUuid = useSelector(selectActiveConfigurationUuid);
  const selectedAreaUuid = useSelector(selectSelectedAreaUuid);
  const selectedMemberUuid = useSelector(selectSelectedMemberUuid);

  const { selectedMember } = useConfiguration();

  const areaUuid = useMemo(() => selectedAreaUuid || selectedMemberUuid, [
    selectedAreaUuid,
    selectedMemberUuid,
  ]);

  const [createConfigurationArea] = useCreateConfigurationAreaMutation({
    errorPolicy: 'none',
    refetchQueries: [{ query: ReadConfigurationDocument, variables: { uuid: configUuid } }],
    onCompleted: () => {
      dispatchSuccessToast();
    },
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSingleAsset = (values: any) => setNewAsset(values);

  const onCancelClick = () => dispatch(setSelectedAssetUuid(undefined));

  const onClickSave = () => {
    if (!!newAsset && !!areaUuid && !!configUuid) {
      const defaultValues = ASSET_TYPE_TO_FIELDS_PARAMS_COMPONENT[newAsset.type as TAssetType]
        ['fields']({})
        .reduce((acc, field) => {
          acc[field.name] = field.defaultValue;
          return acc;
        });
      const areaParams = ASSET_TYPE_TO_FIELDS_PARAMS_COMPONENT[newAsset.type as TAssetType][
        'params'
      ]({
        values: { ...defaultValues, ...newAsset, geoTagLocation: selectedMember?.geoTagLocation },
      });

      createConfigurationArea({
        variables: {
          configurationUuid: configUuid,
          parentUuid: areaUuid,
          areaRepresentation: areaParams,
        },
      });
    }
  };

  return (
    <div className={classnames(s.newAsset, { [s.withMarginTop]: !newAsset })}>
      <div className={s.container}>
        <header className={s.header}>
          <p>{t('commands.ADD_ASSET')}</p>
          <BaseButton theme="grey3" onClick={onCancelClick} className={s.cancelButton}>
            <BaseIcon icon="close" className={s.svgIcon} />
          </BaseButton>
        </header>

        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          !newAsset && <ChooseAsset setSelectedAsset={setNewAsset as any} />
        }

        {!!newAsset && <SingleAsset asset={newAsset} onChange={onChangeSingleAsset} />}
      </div>

      {newAsset && (
        <BaseButton
          type="submit"
          className={s.saveSettings}
          form={'asset-settings'}
          onClick={onClickSave}>
          Save
        </BaseButton>
      )}
    </div>
  );
};
