import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CardKeyResultDefault } from 'src/components/Cards/CardKeyResultDefault';
import { ChartSelf, EChartSelfType } from 'src/components/Charts/ChartSelf';
import { PieChart } from 'src/components/Charts/PieChart';
import { EnergyProfileComponent } from 'src/components/EnergyProfileComponent';
import { HomeEnergyInfo } from 'src/components/HomeEnergyInfo';
import { EPredefinedModalIds } from 'src/constants/modals';
import { DashboardHeader } from 'src/features/Results/DashboardHeader';
import { EViewType } from 'src/features/Results/SingleAreaResults/KeyResults';
import { useGetDetails } from 'src/features/Results/SingleAreaResults/KeyResults/KeyResultsHelpers';
import { LiteWorldMap } from 'src/features/Results/SingleCommunityResults/LiteWorldMap';
import {
  CommunityEnergyProfileInfoText,
  SCMMyCommunityNotification,
  SelfConsumptionInfoText,
  SelfSufficiencyInfoText,
} from 'src/features/Results/SingleCommunityResults/SingleCommunityConstants';
import { TableBills } from 'src/features/Results/SingleCommunityResults/TableBills';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { usePositionBGM } from 'src/hooks/usePositionBGM';
import { useResults } from 'src/hooks/useResults';
import { useSingleNotification } from 'src/hooks/useSingleNotification';
import {
  selectScreenMode,
  selectSelectedDataResolution,
} from 'src/redux/application/application.selectors';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { EScreenMode } from 'src/typings/configuration.types';
import { TKpi } from 'src/typings/simulation.types';
import { objectSnakeCase } from 'src/utils/objectSnakeCase';
import { pickKpiData } from 'src/utils/pickKpiData';

import s from './SingleCommunity.module.scss';
import { TSingleCommunityResultsProps } from './SingleCommunityResults.types';

export const SingleCommunityResults: React.FC<TSingleCommunityResultsProps> = () => {
  const { t } = useTranslation();

  const selectedDataResolution = useSelector(selectSelectedDataResolution);
  const isOperationCommunity = useSelector(selectIsOperationalCommunity);
  const screenMode = useSelector(selectScreenMode);

  const { currencySymbol } = useConfiguration();
  const { isUserACommunityMember } = useIsUserACommunityMember();
  const { isOwnerCM } = useIsAdminInspecting();
  const { profileResults, billsDifferences, kpiDifferences } = useResults();

  useSingleNotification({
    singleNotification: SCMMyCommunityNotification,
    checkStatement: !isOperationCommunity && !isUserACommunityMember && isOwnerCM,
  });

  usePositionBGM({
    modalId: EPredefinedModalIds.MODAL_MAP_SIDEBAR,
    top: screenMode === EScreenMode.Mobile ? 120 : isUserACommunityMember ? 73 : 120,
  });

  function normalizePercentage(value = 0) {
    return value.toFixed(2).replace('.', ',');
  }

  const { getDetails } = useGetDetails();

  return (
    <div className={s.container}>
      <DashboardHeader className={s.dashboardHeader} />
      <CardKeyResultDefault
        symbol={currencySymbol}
        symbolPosition="left"
        value={normalizePercentage(billsDifferences?.savings)}
        resultInfo={getDetails(EViewType.CommunitySavings)}
        useTooltip
        className={s.communitySavings}
      />
      <PieChart
        title="Energy Supply Share"
        items={[
          {
            label: 'Community Energy',
            value: kpiDifferences?.totalSelfConsumptionKwh || 0,
            info: '',
          },
          {
            label: 'Imported Energy (Utility)',
            value: billsDifferences?.boughtFromGrid || 0,
            info: '',
          },
        ]}
        isDecimalPercentage={true}
        isTitleInfoActive={true}
        isItemInfoActive={false}
        titleInfo={t(SelfSufficiencyInfoText) || ''}
        className={s.energySupplyShare}
      />

      {isUserACommunityMember && screenMode === EScreenMode.Desktop && (
        <div className={s.litewordMap}>
          <LiteWorldMap className={s.worldMap} />
        </div>
      )}

      <div className={s.tableBills}>{!isUserACommunityMember && <TableBills />}</div>
      <div className={s.selfSufficiency}>
        <ChartSelf
          kpi={objectSnakeCase<TKpi>(kpiDifferences || {})}
          type={EChartSelfType.Sufficiency}
          isDecimalPercentage={true}
          isTitleInfoActive={true}
          isItemInfoActive={false}
          titleInfo={t(SelfSufficiencyInfoText) || ''}
        />
      </div>
      <div className={s.selfConsumption}>
        <ChartSelf
          kpi={objectSnakeCase<TKpi>(kpiDifferences || {})}
          type={EChartSelfType.Consumption}
          isDecimalPercentage={true}
          isTitleInfoActive={true}
          isItemInfoActive={false}
          titleInfo={t(SelfConsumptionInfoText) || ''}
        />
      </div>
      <div className={s.homeEnergyInfo}>
        <HomeEnergyInfo
          generatedValue={`${
            kpiDifferences?.totalEnergyProducedKwh
              ? (kpiDifferences?.totalEnergyProducedKwh).toFixed(0)
              : 0
          } kWh`}
          consumedValue={`${
            kpiDifferences?.totalEnergyDemandedKwh
              ? (kpiDifferences?.totalEnergyDemandedKwh).toFixed(0)
              : 0
          } kWh`}
        />
      </div>
      <div className={s.energyProfile}>
        <EnergyProfileComponent
          title={t('COMMUNITY_ENERGY_PROFILE')}
          mode={selectedDataResolution}
          initialData={!!profileResults ? pickKpiData(profileResults) : []}
          info={t(CommunityEnergyProfileInfoText) || ''}
        />
      </div>
    </div>
  );
};
