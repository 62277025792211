import * as turf from '@turf/turf';
import { GRID_MARKET_ASSET_TYPES, NET_ENERGY_KEY } from 'src/constants/application';
import { DEFAULT_SETTINGS_DATA } from 'src/redux/configuration/configuration.constants';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { RootState } from 'src/redux/store';
import { TLngLat, TLngLatArray } from 'src/typings/base-types';
import { TAsset, TConfigurationCharacteristic, TUsedNames } from 'src/typings/configuration.types';
import { TValuesByFieldName } from 'src/utils/assetsFields/valuesByFieldName.types';
import { getCommunityAsset } from 'src/utils/configuration/getCommunityAsset';
import { recreateConfigTree } from 'src/utils/configuration/recreateConfigTree';
import { convertLngLat } from 'src/utils/worldMap/helpers';

export const selectAssets = (state: RootState): TConfigurationState['assets'] => {
  return state.configuration.assets;
};

export const selectAssetsTreeRelations = (
  state: RootState,
): TConfigurationState['assetsTreeRelations'] => {
  return state.configuration.assetsTreeRelations;
};

export const selectAssetsValues = (state: RootState): TConfigurationState['assetsValues'] => {
  return state.configuration.assetsValues;
};

export const selectConfigType = (state: RootState): TConfigurationState['configType'] => {
  return state.configuration.configType;
};

export const selectReadOnly = (state: RootState): TConfigurationState['readOnly'] => {
  return state.configuration.readOnly;
};

export const selectRootAssetUuid = (state: RootState): TConfigurationState['rootAssetUuid'] => {
  return state.configuration.rootAssetUuid;
};

/**
 * For this asset, we're getting results
 */
export const selectSelectedAssetUuid = (
  state: RootState,
): TConfigurationState['selectedAssetUuid'] => {
  return state.configuration.selectedAssetUuid;
};

/**
 * For this area, we're getting results
 */
export const selectSelectedAreaUuid = (
  state: RootState,
): TConfigurationState['selectedAreaUuid'] => {
  return state.configuration.selectedAreaUuid;
};

export const selectSettingsData = (
  state: RootState,
): NonNullable<TConfigurationState['settingsData']> => {
  const { settingsData } = state.configuration;
  return settingsData || DEFAULT_SETTINGS_DATA;
};

export const selectCommunityLogo = (state: RootState): string | null => {
  return state.configuration.settingsData?.logo ?? null;
};

export const selectCommunityUser = (state: RootState): string => {
  return state.configuration.user;
};

export const selectHasUserAllowedResultsLoss = (state: RootState): boolean => {
  return state.configuration.hasUserAllowedResultsLoss;
};

export const selectActiveConfigurationUuid = (
  state: RootState,
): TConfigurationState['activeConfigurationUuid'] => state.configuration.activeConfigurationUuid;

export const selectActiveConfigurationJobUuid = (
  state: RootState,
): TConfigurationState['activeConfigurationJobUuid'] =>
  state.configuration.activeConfigurationJobUuid;

export const selectSimulationResults = (
  state: RootState,
): TConfigurationState['simulationResults'] => state.configuration.simulationResults;

export const selectRawCommunitySimulationResults = (
  state: RootState,
): TConfigurationState['rawCommunitySimulationResults'] =>
  state.configuration.rawCommunitySimulationResults;

export const selectCommunitySimulationResults = (
  state: RootState,
): TConfigurationState['communitySimulationResults'] =>
  state.configuration.communitySimulationResults;

export const selectSimulationStatus = (state: RootState): TConfigurationState['simulationStatus'] =>
  state.configuration.simulationStatus;

export const selectSimulationProgress = (
  state: RootState,
): TConfigurationState['simulationProgress'] => state.configuration.simulationProgress;

export const selectFrontendSimulationStatus = (
  state: RootState,
): TConfigurationState['frontendSimulationStatus'] => state.configuration.frontendSimulationStatus;

export const selectCreatedSCMMemberUUID = (
  state: RootState,
): TConfigurationState['createdSCMMemberUUID'] => state.configuration.createdSCMMemberUUID;

// Extra Selectors
export const selectAssetValuesForUuid = (uuid: string) => (
  state: RootState,
): TValuesByFieldName | undefined => {
  return state.configuration.assetsValues[uuid];
};

export const selectActiveAssetPath = (state: RootState): TAsset['uuid'][] => {
  const { assets, selectedAssetUuid } = state.configuration;
  if (!selectedAssetUuid) return [];
  const path = [selectedAssetUuid];
  let parentUuid = assets[selectedAssetUuid]?.parentUuid;
  while (parentUuid) {
    path.push(parentUuid);
    parentUuid = assets[parentUuid]?.parentUuid;
  }
  return path;
};

export const selectConfigTree = (state: RootState): ReturnType<typeof recreateConfigTree> => {
  return recreateConfigTree(state.configuration);
};

export const selectAssetsUnderUuid = (underUuid: TAsset['uuid'] | undefined) => (
  state: RootState,
): TAsset[] => {
  const output: TAsset[] = [];
  const { assets, assetsTreeRelations } = state.configuration;

  if (!underUuid) return output;

  (assetsTreeRelations[underUuid] || []).forEach((uuid) => {
    output.push(assets[uuid]);
  });

  return output;
};

export const selectRootAsset = (state: RootState): TAsset | null => {
  const { assets, rootAssetUuid } = state.configuration;
  if (!rootAssetUuid) return null;
  return assets[rootAssetUuid];
};

// TODO: Used asset names should consider: representation (original config), latest (latest config) or (if isLibrary) devices in the library.
// The logic should be very similar to the logic existing in old repo: function fetchUsedNodesNames
export const selectUsedAssetsNames = (state: RootState): TUsedNames => {
  const output: TUsedNames = [NET_ENERGY_KEY]; // NET_ENERGY_KEY is a reserved special key
  const { assetsValues } = state.configuration;

  Object.values(assetsValues).forEach(({ name }) => {
    if (name) {
      output.push(name);
    }
  });

  return output;
};

export const selectCommunityAsset = (state: RootState): TAsset | undefined => {
  return getCommunityAsset(state.configuration);
};

export const selectGridMarketAsset = (state: RootState): TAsset | undefined => {
  const { assets, rootAssetUuid, assetsTreeRelations } = state.configuration;

  if (!rootAssetUuid) return;

  const rootChildrenAssets = assetsTreeRelations[rootAssetUuid].map((uuid) => assets[uuid]);
  const gridMarketAsset = rootChildrenAssets.find((asset) =>
    GRID_MARKET_ASSET_TYPES.includes(asset.type),
  );

  return gridMarketAsset;
};

export const selectConfiguration = (state: RootState): TConfigurationCharacteristic => {
  const { assetsValues, user, isLibrary, projectUuid, timezone, timestamp } = state.configuration;
  const gridMarketAsset = selectGridMarketAsset(state);
  const gridMarketValues = gridMarketAsset && assetsValues[gridMarketAsset.uuid];
  const marketMakerRate = gridMarketValues?.energyRate;
  const gridMakerHasUploadedProfile = Boolean(gridMarketValues?.energyRateProfile);

  return {
    user,
    projectUuid,
    gridMakerHasUploadedProfile,
    timezone,
    timestamp,
    marketMakerRate: isLibrary ? null : marketMakerRate || 0,
    transformerCapacityEnabled: false, // setting as default true as data unavailble from BE
    baselinePeakEnergyEnabled: false, // setting as default true as data unavailble from BE
  };
};

export const selectIsCommunityCreatedInDB = (state: RootState): boolean => {
  const { projectUuid, activeConfigurationUuid } = state.configuration;

  return Boolean(projectUuid && activeConfigurationUuid);
};

export const selectAssetsAmountBelowCommunity = (state: RootState): number => {
  const communityAsset = selectCommunityAsset(state);
  const { assetsTreeRelations } = state.configuration;

  return communityAsset ? assetsTreeRelations[communityAsset.uuid].length : 0;
};

export const selectConfigurationCenter = (state: RootState): TLngLat | undefined => {
  const communityAsset = selectCommunityAsset(state);

  if (!communityAsset) return;

  const assetsUnderUuid = selectAssetsUnderUuid(communityAsset?.uuid)(state);
  const { assetsValues } = state.configuration;

  const coordsArray = assetsUnderUuid.reduce((acc: TLngLatArray[], item) => {
    const { geoTagLocation } = assetsValues[item.uuid];
    if (!geoTagLocation?.length) return acc;
    acc.push(geoTagLocation);
    return acc;
  }, []);

  if (!coordsArray.length) return;

  const features = turf.points(coordsArray);
  const center = turf.center(features);
  return convertLngLat(center.geometry.coordinates as TLngLatArray);
};

export const selectResultsStartTime = (state: RootState): TConfigurationState['resultsStartTime'] =>
  state.configuration.resultsStartTime;

export const selectResultsEndTime = (state: RootState): TConfigurationState['resultsEndTime'] =>
  state.configuration.resultsEndTime;

export const selectResultsPeriod = (state: RootState): TConfigurationState['resultPeriod'] =>
  state.configuration.resultPeriod;

export const selectAssetLoadingInfo = (uuid: TAsset['uuid'] | undefined) => (
  state: RootState,
): boolean =>
  uuid && state.configuration.assetLoadingInfo[uuid]
    ? state.configuration.assetLoadingInfo[uuid]
    : false;

export const selectAllAssetLoadingInfo = (state: RootState): Record<string, boolean> =>
  state.configuration.assetLoadingInfo;

export const selectIsModalSummaryWithList = (state: RootState): boolean =>
  state.configuration.isModalSummaryWithList;

export const selectLastAddedAssetUuid = (
  state: RootState,
): TConfigurationState['lastAddedAssetUuid'] | undefined => state.configuration.lastAddedAssetUuid;

export const selectConfigurationTimestamp = (
  state: RootState,
): TConfigurationState['timestamp'] | undefined => state.configuration.timestamp;
