import React, { useContext, useEffect, useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { EditMember } from 'src/features/Members/EditMember';
import { UserTileShowData } from 'src/features/Members/SingleMember/UserTileShowData';
import { ReadConfigurationDocument } from 'src/graphql';
import { useRemoveCommunityMemberMutation } from 'src/graphql';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { useMapNavigation } from 'src/hooks/useMapNavigation';
import { selectSelectedMemberUuid } from 'src/redux/application/application.selectors';
import { setSelectedMemberUuid } from 'src/redux/application/application.slice';
import { selectReadOnly } from 'src/redux/configuration/configuration.selectors';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import {
  setSelectedAssetUuid,
  setSelectedAreaUuid,
} from 'src/redux/configuration/configuration.slice';
import { selectIsAppLoading } from 'src/redux/scm/scm.selectors';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { setIsAppLoading } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { dispatchErrorToast, dispatchSuccessToast } from 'src/utils/toasts';

import { AssetsCard } from './AssetsCard';
import s from './SingleMember.module.scss';
import { TSingleMemberProps } from './SingleMember.types';

export enum EAction {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export const SingleMember: React.FC<TSingleMemberProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { mapService } = useContext(WorldMapContext);

  const { zoomIntoConfiguration } = useMapNavigation();
  const { isUserACommunityMember } = useIsUserACommunityMember();
  const { selectedMember, areaUuidToArea, area, assets } = useConfiguration();

  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const readOnly = useSelector(selectReadOnly);
  const isAppLoading = useSelector(selectIsAppLoading);
  const selectedMemberUuid = useSelector(selectSelectedMemberUuid);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const [action, setAction] = useState<EAction>(EAction.VIEW);

  const [removeCommunityMemberMutation] = useRemoveCommunityMemberMutation({
    errorPolicy: 'none',
    refetchQueries: [{ query: ReadConfigurationDocument, variables: { uuid: configUuid } }],
    onCompleted: () => {
      dispatch(setIsAppLoading(false));
      dispatchSuccessToast();
      onClickAllMembers();
    },
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });

  useEffect(() => {
    if (isUserACommunityMember && mapService) {
      let isBusy = true;
      const customListener = setInterval(() => {
        isBusy =
          mapService.map.isEasing() || mapService.map.isMoving() || mapService.map.isRotating();
        if (isBusy === false) {
          clearInterval(customListener);
          mapService.set3DView(true);
        }
      }, 1000);
    }
  }, [isUserACommunityMember, mapService]);

  const handleAreaClick = () => {
    if (!!area) {
      dispatch(setSelectedAreaUuid(area.uuid));
    }
  };

  const onClickAllMembers = () => {
    batch(() => {
      dispatch(setSelectedMemberUuid(null));
      dispatch(setSelectedAssetUuid(undefined));
      dispatch(setSelectedAreaUuid(undefined));
      if (!!areaUuidToArea) {
        zoomIntoConfiguration({ assetsValues: areaUuidToArea });
      }
    });
  };

  const onClickAsset = (childUuid) => {
    batch(() => {
      if (!!area) {
        dispatch(setSelectedAreaUuid(area.uuid));
      }

      dispatch(setSelectedAssetUuid(childUuid));
    });
  };

  const onAddAssetClick = () => dispatch(setSelectedAssetUuid('NEW_ASSET'));

  return (
    <>
      <div className={classnames(s.header, className)} onClick={onClickAllMembers}>
        <BaseIcon size={14} icon="arrow-left-full" className={s.iconBack} />
        {t('navigation.ALL_MEMBERS')}
      </div>

      <div className={s.container}>
        {action === EAction.EDIT && <EditMember onClickClose={() => setAction(EAction.VIEW)} />}
        {action === EAction.VIEW && (
          <div className={classnames(s.userTileContainer)}>
            <div className={s.infoContainer}>
              {/* TODO: Put these action icons inline with the UserTileShowData name */}
              <div className={s.actionsContainer}>
                <BaseButtonSquare
                  onClick={() => setAction(EAction.EDIT)}
                  className={s.button}
                  icon="pencil-edit"
                  size="2"
                />
                <BaseButtonSquare
                  onClick={() =>
                    removeCommunityMemberMutation({
                      variables: { memberUuid: selectedMemberUuid!, configUuid: configUuid! },
                    })
                  }
                  className={s.button}
                  icon="trash"
                  size="2"
                />
              </div>
              {/* TODO: Put this card also in editMember */}
              <UserTileShowData
                name={selectedMember?.name ?? ''}
                email={selectedMember?.email ?? ''}
                address={selectedMember?.address ?? ''}
                setFormData={() => null}
                onSubmit={() => null}
              />
            </div>
          </div>
        )}
        <div className={s.listMemberAssets}>
          {area && (
            <div className={s.assetsContainer}>
              <AssetsCard
                isUserACommunityMember={isUserACommunityMember}
                data={area}
                onClick={() => handleAreaClick()}
                isMoreButtonActive={false}
              />
            </div>
          )}
          {assets && assets.length ? (
            <div className={s.assetsContainer}>
              {assets.map((asset) => {
                return (
                  <AssetsCard
                    isUserACommunityMember={isUserACommunityMember}
                    key={asset.uuid}
                    data={asset}
                    isMoreButtonActive={false}
                    onClick={onClickAsset}
                  />
                );
              })}
            </div>
          ) : (
            <div className={s.noAssetsContainer}>
              <div className={s.noAssetPill}>{t('messages.NO_ASSETS')}</div>
            </div>
          )}
          {!readOnly && !isUserACommunityMember && (
            <BaseButton
              isLoading={isAppLoading}
              disabled={isOperationalCommunity}
              onClick={onAddAssetClick}
              theme="grey2"
              className={s.addAssetButton}
              icon="plus-circle">
              {t('commands.ADD_ASSET')}
            </BaseButton>
          )}
        </div>
      </div>
    </>
  );
};
