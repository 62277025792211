import { useMemo, useRef } from 'react';

import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { EHeaderMode } from 'src/components/Header';
import { ConfigType } from 'src/graphql';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { selectIsEmbed } from 'src/redux/application/application.selectors';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { routesConfig } from 'src/routes/routes.config';

export enum ENavigation {
  // Simulations map view (default after enter)
  DefaultPreview = 'DEFAULT_PREVIEW',
  // Create New Community (before save to local storage)
  CreateNewCommunity = 'CREATE_NEW_COMMUNITY',
  // Simulation
  Simulation = 'SIMULATION',
  // Operational
  Operational = 'OPERATIONAL',
  // Embed mode
  Embed = 'EMBED',
}

export type TConfigurationFlowState = {
  navigation: ENavigation;
  modalsEmbedShow: boolean;
  headerMode: EHeaderMode;
  modalSimulationProgressShow: boolean;
};

export const useNavigation = (): TConfigurationFlowState => {
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const location = useAppLocation();
  const isEmbed = useSelector(selectIsEmbed);

  const prevConfigurationFlowState = useRef<ENavigation>(ENavigation.DefaultPreview);

  const { configuration } = useConfiguration();

  const navigation = useMemo(() => {
    let output: ENavigation = prevConfigurationFlowState.current;

    const createRouteMatch = matchPath(location.pathname, {
      path: routesConfig.scmMapCreate(),
    });

    // State conditions
    const isEmbedMode = isEmbed;

    const statesMap: Map<ENavigation, boolean> = new Map([
      [ENavigation.Embed, !!isEmbedMode],
      [ENavigation.DefaultPreview, !activeConfigurationUuid],
      [
        ENavigation.CreateNewCommunity,
        !!createRouteMatch || activeConfigurationUuid === 'NEW_COMMUNITY',
      ],
      [ENavigation.Simulation, !!configuration && configuration.type === ConfigType.Collaboration],
      [ENavigation.Operational, !!configuration && configuration.type === ConfigType.CanaryNetwork],
    ]);

    const thruthlyStatus: ENavigation | undefined = Array.from(statesMap).find(
      ([key, value]) => value && key,
    )?.[0];

    if (thruthlyStatus) {
      output = thruthlyStatus;
    }

    prevConfigurationFlowState.current = output;
    return output;
  }, [activeConfigurationUuid, isEmbed, location.pathname, configuration]);

  switch (navigation) {
    case ENavigation.Embed:
      return {
        navigation,
        modalSimulationProgressShow: false,
        headerMode: EHeaderMode.Community,
        modalsEmbedShow: true,
      };
    case ENavigation.CreateNewCommunity:
      return {
        navigation,
        modalSimulationProgressShow: false,
        headerMode: EHeaderMode.Community,
        modalsEmbedShow: false,
      };
    case ENavigation.Simulation:
      return {
        navigation,
        modalSimulationProgressShow: true,
        headerMode: EHeaderMode.Community,
        modalsEmbedShow: true,
      };
    case ENavigation.Operational:
      return {
        navigation,
        modalSimulationProgressShow: false,
        headerMode: EHeaderMode.Community,
        modalsEmbedShow: true,
      };
    default:
      return {
        navigation,
        modalSimulationProgressShow: false,
        headerMode: EHeaderMode.Default,
        modalsEmbedShow: true,
      };
  }
};
