import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Copyright } from 'src/components/Copyright';
import { TFormAssetsParamsProps } from 'src/components/FormAssetsParams';
import { ListMembersAndAssets } from 'src/components/MapSidebar/components/ListMembersAndAssets';
import { MobileInfoArea } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity/components/MobileInfoArea';
import { RegularNoResults } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity/components/RegularNoResults';
import { MessagingPortal } from 'src/components/MapSidebar/components/MessagingPortal';
import { UserProfile } from 'src/components/MapSidebar/components/UserProfile';
import { Tabs } from 'src/components/Tabs';
import { EDomIds } from 'src/constants/domSelectors';
import { ApplicationContext } from 'src/contexts/ApplicationContext';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { AdvancedSettingsForm } from 'src/features/Communities/EditCommunity/AdvancedSettingsForm';
import { BasicSettingsForm } from 'src/features/Communities/EditCommunity/BasicSettingsForm';
import { GridMarketForm } from 'src/features/Communities/EditCommunity/GridMarketForm/GridMarketForm';
import { ManageManagers } from 'src/features/Managers';
import { SingleAreaResults } from 'src/features/Results/SingleAreaResults';
import { SingleCommunityResults } from 'src/features/Results/SingleCommunityResults';
import { ConfigType } from 'src/graphql';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useFlyTo } from 'src/hooks/useFlyTo';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { useSimulationSubscription } from 'src/hooks/useSimulationSubscription';
import {
  selectActiveSidebarSettingsSubTab,
  selectCommunityNotFound,
  selectScreenMode,
  selectSelectedMemberUuid,
} from 'src/redux/application/application.selectors';
import { selectActiveSidebarMainTab } from 'src/redux/application/application.selectors';
import {
  setActiveSidebarSettingsSubTab,
  setSelectedMemberUuid,
  setSidebarExpanded,
} from 'src/redux/application/application.slice';
import { setActiveSidebarMainTab } from 'src/redux/application/application.slice';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import {
  selectConfigType,
  selectReadOnly,
  selectRootAssetUuid,
  selectSelectedAreaUuid,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import {
  setSelectedAreaUuid,
  setSelectedAssetUuid,
} from 'src/redux/configuration/configuration.slice';
import {
  selectActiveSCMStep,
  selectActiveTabResults,
  selectIsOperationalCommunity,
} from 'src/redux/scm/scm.selectors';
import { setActiveSCMStep, setPreventPushResultsScreen } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { EFormVariant } from 'src/typings/base-types';
import { EScreenMode } from 'src/typings/configuration.types';
import { v4 } from 'uuid';

import s from './MapSidebarSingleCommunity.module.scss';
import {
  EActiveTabState,
  EResultsTab,
  TMapSidebarSingleCommunityProps,
} from './MapSidebarSingleCommunity.types';
import { MapSidebarSingleCommunityTabs } from './MapSidebarSingleCommunityTabs';

export const MapSidebarSingleCommunity: React.FC<TMapSidebarSingleCommunityProps> = ({
  onAssetValuesSavePromise,
  onAssetValuesSave,
}) => {
  const dispatch = useAppDispatch();

  const { triggerResultsLossAlert } = useContext(ApplicationContext);
  const { mapService } = useContext(WorldMapContext);

  const activeSidebarMainTab = useSelector(selectActiveSidebarMainTab);
  const activeSidebarSettingsSubTab = useSelector(selectActiveSidebarSettingsSubTab);
  const loggedIn = useSelector(selectIsLoggedIn);
  const rootAssetUuid = useSelector(selectRootAssetUuid);
  const selectedAreaUuid = useSelector(selectSelectedAreaUuid);
  const selectedMemberUuid = useSelector(selectSelectedMemberUuid);
  const activeSCMStep = useSelector(selectActiveSCMStep);
  const activeResultsTab = useSelector(selectActiveTabResults);
  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const screenMode = useSelector(selectScreenMode);
  const configType = useSelector(selectConfigType);
  const communityNotFound = useSelector(selectCommunityNotFound);
  const readOnly = useSelector(selectReadOnly);
  const isOperationalTab = useSelector(selectIsOperationalCommunity);
  const simulationStatus = useSelector(selectSimulationStatus);
  const formId = useRef(v4());

  const hasConnectCommunityTooltipShown = useRef<boolean>(false);

  const { isUserACommunityMember } = useIsUserACommunityMember();
  const { configuration } = useConfiguration();
  const { showSimulationResults } = useSimulationSubscription();

  const { flyToAsset } = useFlyTo({ mapService });

  const [, setShowConnectCommunityTooltip] = useState<boolean>(false);
  // this check will be updated and used when the user is a community member
  // invited through the invitation link

  const handleTabChange = (value: EActiveTabState) => {
    dispatch(setActiveSidebarMainTab(value));

    if (
      ((!isOperationalCommunity && simulationStatus !== 'running') || isOperationalCommunity) &&
      [EActiveTabState.Registry, EActiveTabState.Settings].includes(value)
    ) {
      dispatch(setPreventPushResultsScreen(true));
    }
    const expandedTabs = [EActiveTabState.MessagingPortal, EActiveTabState.Community];

    dispatch(setSidebarExpanded(expandedTabs.includes(value)));

    if (isOperationalTab && !isUserACommunityMember) {
      dispatch(setActiveSCMStep(4));
      return;
    }
    if (value == EActiveTabState.Registry) {
      dispatch(setSelectedAreaUuid(undefined));
      dispatch(setSelectedMemberUuid(null));
      dispatch(setSelectedAssetUuid(undefined));
      dispatch(setActiveSCMStep(1));
    }
    if (value == EActiveTabState.Settings) dispatch(setActiveSCMStep(2));
    if (value == EActiveTabState.Community) dispatch(setActiveSCMStep(3));
  };

  const handleResultsTabClick = () => {
    handleTabChange(EActiveTabState.Community);
    dispatch(setSidebarExpanded(true));
  };

  const handleAssetValuesSavePromise: TFormAssetsParamsProps['onSubmitPromise'] = async (
    payload,
  ): Promise<boolean> => {
    await triggerResultsLossAlert();

    return onAssetValuesSavePromise
      ? onAssetValuesSavePromise(payload)
      : new Promise((resolve) => resolve(false));
  };

  const renderSettingsTabsContent = () => {
    switch (activeSidebarSettingsSubTab) {
      case EFormVariant.Express:
        return <BasicSettingsForm className={s.formMaxHeight} id={formId.current} theme="dark" />;
      case EFormVariant.Advanced:
        return <AdvancedSettingsForm />;
      case EFormVariant.GridMarket:
        return (
          <GridMarketForm
            handleAssetValuesSavePromise={handleAssetValuesSavePromise}
            hostAssetUuid={rootAssetUuid ?? ''}
            theme="dark"
            formId={formId}
            readOnly={readOnly}
          />
        );
      case EFormVariant.CommunityManagers:
        return <ManageManagers />;
    }
  };

  useEffect(() => {
    if (isOperationalTab && simulationStatus === 'running') {
      setTimeout(() => {
        dispatch(setActiveSCMStep(4));
        dispatch(setActiveSidebarMainTab(EActiveTabState.Community));
      }, 1000);
    }
  }, [simulationStatus, isOperationalTab, dispatch]);

  useEffect(() => {
    dispatch(
      setActiveSidebarMainTab(
        isUserACommunityMember ? EActiveTabState.Profile : EActiveTabState.Settings,
      ),
    );
  }, [isUserACommunityMember, dispatch]);

  useEffect(() => {
    if (selectedAreaUuid) flyToAsset(selectedAreaUuid, { specificZoom: 18 });
  }, [flyToAsset, selectedAreaUuid]);

  useEffect(() => {
    if (
      configType === ConfigType.CanaryNetwork &&
      activeSidebarMainTab === EActiveTabState.Settings &&
      activeSCMStep === 4 &&
      activeSidebarSettingsSubTab === EFormVariant.Express &&
      hasConnectCommunityTooltipShown.current === false
    ) {
      hasConnectCommunityTooltipShown.current = true;
      setShowConnectCommunityTooltip(true);
      return;
    }
    setShowConnectCommunityTooltip(false);
  }, [activeSCMStep, activeSidebarMainTab, configType, activeSidebarSettingsSubTab]);

  // TODO::this check needs to be updated once we have live results flag from BE
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showLiveResultsIndidcator = useMemo(() => configType !== ConfigType.Collaboration, [
    configType,
  ]);

  return (
    <div
      className={s.container}
      id={EDomIds.MODAL_MAP_SIDEBAR}
      style={{
        pointerEvents: 'auto',
      }}>
      <>
        {!loggedIn && (
          <div className={s.header}>
            <h3>{configuration?.name}</h3>
          </div>
        )}
        {loggedIn && (
          <MapSidebarSingleCommunityTabs
            activeTab={activeSidebarMainTab}
            handleTabChange={handleTabChange}
            handleResultsTabClick={handleResultsTabClick}
          />
        )}
        {/*
            Used conditional rendering instead of switching "display: none",
            because there is some issues with d3 charts when they are not visible.
            https://gridsingularity.atlassian.net/browse/PH-464
        */}
        {(() => {
          switch (activeSidebarMainTab) {
            case EActiveTabState.MessagingPortal:
              return (
                <div className={classNames(s.graphsList, s.scmMessagingPortal)}>
                  <MessagingPortal
                    hostAssetUuid={rootAssetUuid ?? ''}
                    theme="dark"
                    formId={formId}
                    readOnly={readOnly}
                  />
                  {isUserACommunityMember && screenMode === EScreenMode.Mobile && (
                    <Copyright mode="mobileRelative" />
                  )}
                </div>
              );
            case EActiveTabState.Community:
            case EActiveTabState.GridMarketResults: {
              if (!showSimulationResults) {
                return (
                  <div className={s.graphsList}>
                    <RegularNoResults communityNotFound={communityNotFound} />
                  </div>
                );
              }
              return (
                <div className={classNames(s.graphsList, s.scmResultsContainer)}>
                  {((isUserACommunityMember && activeResultsTab === EResultsTab.Home) ||
                    (!isUserACommunityMember && !!selectedMemberUuid)) && <SingleAreaResults />}
                  {((isUserACommunityMember && activeResultsTab === EResultsTab.Community) ||
                    (!isUserACommunityMember && !selectedMemberUuid)) && <SingleCommunityResults />}
                  {isUserACommunityMember && screenMode === EScreenMode.Mobile && (
                    <Copyright mode="mobileRelative" />
                  )}
                </div>
              );
            }
            case EActiveTabState.Registry:
              return <ListMembersAndAssets />;
            case EActiveTabState.Profile:
              return (
                <div className={s.profile}>
                  <UserProfile
                    onAssetValuesSave={onAssetValuesSave}
                    onAssetValuesSavePromise={onAssetValuesSavePromise}
                  />
                  {screenMode === EScreenMode.Mobile && (
                    <MobileInfoArea onClick={handleResultsTabClick} />
                  )}
                  {isUserACommunityMember && screenMode === EScreenMode.Mobile && (
                    <Copyright mode="mobileRelative" />
                  )}
                </div>
              );

            case EActiveTabState.Settings:
              return (
                <>
                  <Tabs
                    activeTab={activeSidebarSettingsSubTab}
                    tabs={[
                      EFormVariant.Express,
                      EFormVariant.Advanced,
                      EFormVariant.GridMarket,
                      EFormVariant.CommunityManagers,
                    ]}
                    handleClick={(tab) => dispatch(setActiveSidebarSettingsSubTab(tab))}
                    className={s.subtabHeader}
                  />
                  <div className={s.settingsList}>
                    <div className={s.formWrapper}>{renderSettingsTabsContent()}</div>
                  </div>
                </>
              );
          }
        })()}
      </>
    </div>
  );
};
