import { CURRENCY_OPTIONS, LANGUAGE_OPTIONS, TIMEZONE_OPTIONS } from 'src/constants/application';
import { ConfigType, ConfigurationOutput } from 'src/graphql';
import { TCommunitySettingsFields } from 'src/utils/assetsFields/assetsFields.types';
import { TFieldsUnionWithValue } from 'src/utils/assetsFields/assetsFields.types';
import { TValuesByFieldName } from 'src/utils/assetsFields/valuesByFieldName.types';
import { TValidators, rules } from 'src/utils/fieldValidation';
import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const basicSettingsFields = ({
  values,
}: {
  values: ConfigurationOutput | null;
}): TFieldsUnionWithValue[] => {
  return [
    {
      name: 'name',
      type: 'text',
      label: 'labels.COMMUNITY_NAME',
      defaultValue: 'Phoenix Community',
      value: values?.name,
    },
    {
      name: 'logo',
      type: 'file',
      label: 'labels.LOGO',
      defaultValue: '',
      value: values?.settingsData?.logo?.split('/').pop(),
      exclude: values?.type === ConfigType.Collaboration || values?.uuid === 'NEW_COMMUNITY',
    },
    {
      name: 'companyName',
      type: 'text',
      label: 'labels.COMPANY_NAME',
      defaultValue: '',
      value: values?.communityAdvancedSettings?.companyName,
      exclude: values?.type === ConfigType.Collaboration || values?.uuid === 'NEW_COMMUNITY',
    },
    {
      name: 'description',
      type: 'textarea',
      label: 'labels.NOTES',
      value: values?.description,
      defaultValue: '',
    },
    {
      name: 'startEndDate',
      type: 'dateRange',
      label: 'labels.SIMULATION_LENGTH',
      valueFormat: BACKEND_DATE_FORMATS.SETTINGS_DATA,
      defaultValue: {
        startDate: UTCMoment.utc(new Date()).format(BACKEND_DATE_FORMATS.SETTINGS_DATA),
        endDate: UTCMoment.utc(new Date())
          .add(7, 'days')
          .format(BACKEND_DATE_FORMATS.SETTINGS_DATA),
      },
      value: {
        startDate: values?.settingsData?.startDate,
        endDate: values?.settingsData?.endDate,
      },
    },
    {
      name: 'currency',
      type: 'enum',
      label:
        values?.type === ConfigType.Collaboration
          ? 'labels.SIMULATION_CURRENCY'
          : 'common.CURRENCY',
      options: CURRENCY_OPTIONS,
      value: values?.settingsData?.currency,
      defaultValue: CURRENCY_OPTIONS[1].value,
    },
    {
      name: 'timezone',
      type: 'enum',
      label: 'labels.COMMUNITY_TIME_ZONE',
      options: TIMEZONE_OPTIONS,
      value: values?.timezone,
    },
    {
      name: 'language',
      type: 'enum',
      label: 'labels.LANGUAGE',
      options: LANGUAGE_OPTIONS,
      defaultValue: LANGUAGE_OPTIONS[0].value,
      value: 0,
    },
  ];
};

export const validators = ({
  usedProjectNames,
  currentProjectName,
}: {
  usedProjectNames: string[];
  currentProjectName: string;
}): TValidators<
  keyof TCommunitySettingsFields & keyof Pick<TValuesByFieldName, 'gridFeeConstant'>
> => ({
  name: [
    ...((n) => [
      rules.required(n),
      rules.isNameUnique(usedProjectNames, currentProjectName),
      rules.maxLength(n, 50),
    ])('Name'),
  ],
});
