import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager';
import { TApplicationState } from 'src/redux/application/application.slice';
import { RootState } from 'src/redux/store';

export const selectModalAssetManagerActiveView = (
  state: RootState,
): TApplicationState['modalAssetManagerActiveView'] =>
  state.application.modalAssetManagerActiveView;

export const selectModalAssetManagerShowGuide = (
  state: RootState,
): TApplicationState['modalAssetManagerShowGuide'] => state.application.modalAssetManagerShowGuide;

export const selectModalCommunitySummaryView = (
  state: RootState,
): TApplicationState['modalCommunitySummaryView'] => state.application.modalCommunitySummaryView;

export const selectModalHouseSummaryView = (
  state: RootState,
): TApplicationState['modalHouseSummaryView'] => state.application.modalHouseSummaryView;

export const selectModalHouseSummaryFormVariant = (
  state: RootState,
): TApplicationState['modalHouseSummaryFormVariant'] =>
  state.application.modalHouseSummaryFormVariant;

export const selectActiveSidebarMainTab = (
  state: RootState,
): TApplicationState['activeSidebarMainTab'] => state.application.activeSidebarMainTab;

export const selectActiveSidebarSettingsSubTab = (
  state: RootState,
): TApplicationState['activeSidebarSettingsSubTab'] =>
  state.application.activeSidebarSettingsSubTab;

export const selectIsCustomHouseAddingContinuation = (
  state: RootState,
): TApplicationState['isCustomHouseAddingContinuation'] =>
  state.application.isCustomHouseAddingContinuation;

export const selectCanaryWaitlistDismissed = (
  state: RootState,
): TApplicationState['canaryWaitlistDismissed'] => state.application.canaryWaitlistDismissed;

export const selectModalMapAlertDismissed = (
  state: RootState,
): TApplicationState['modalMapAlertDismissed'] => state.application.modalMapAlertDismissed;

export const selectSidebarExpanded = (state: RootState): TApplicationState['sidebarExpanded'] =>
  state.application.sidebarExpanded;

export const selectIsCreateOrEditHouseFlow = (state: RootState): boolean =>
  (state.application.modalAssetManagerActiveView !== EModalAssetsManagerView.GridMarketAndAgent &&
    state.application.modalAssetManagerActiveView !== EModalAssetsManagerView.AddHouse &&
    state.application.modalAssetManagerActiveView !== EModalAssetsManagerView.AddCommunity) ||
  (state.application.modalCommunitySummaryView !== EModalAssetsManagerView.Summary &&
    state.application.modalCommunitySummaryView !== EModalAssetsManagerView.SaveOrSettings) ||
  state.application.modalHouseSummaryView !== EModalAssetsManagerView.Summary;

export const selectIsEmbed = (state: RootState): TApplicationState['isEmbed'] =>
  state.application.isEmbed;

export const selectAppMode = (state: RootState): TApplicationState['appMode'] =>
  state.application.appMode;

export const selectNewCommunity = (state: RootState): TApplicationState['newCommunity'] =>
  state.application.newCommunity;

export const selectOnboardingStep = (state: RootState): TApplicationState['step'] =>
  state.application.step;

export const selectGuideStep = (state: RootState): TApplicationState['guideStep'] =>
  state.application.guideStep;

export const selectTutorialStep = (state: RootState): TApplicationState['tutorialStep'] =>
  state.application.tutorialStep;

export const selectCommunityNotFound = (state: RootState): TApplicationState['communityNotFound'] =>
  state.application.communityNotFound;

export const selectUserCreatedCommunityBefore = (
  state: RootState,
): TApplicationState['userCreatedCommunityBefore'] => state.application.userCreatedCommunityBefore;

export const selectAvailableUserRoles = (
  state: RootState,
): TApplicationState['availableUserRoles'] => state.application.availableUserRoles;

export const selectActiveDashboard = (state: RootState): TApplicationState['activeDashboard'] =>
  state.application.activeDashboard;

export const selectActiveGridMarketTab = (
  state: RootState,
): TApplicationState['activeGridMarketTab'] => state.application.activeGridMarketTab;

export const selectActiveGridMarketForm = (
  state: RootState,
): TApplicationState['activeGridMarketForm'] => state.application.activeGridMarketForm;

export const selectAddNewLibrary = (state: RootState): TApplicationState['addNewLibrary'] =>
  state.application.addNewLibrary;

export const selectCollaborationStep = (state: RootState): TApplicationState['collaborationStep'] =>
  state.application.collaborationStep;

// create a selector for the isBottomRightCopyrightVisible state
export const selectIsBottomRightCopyrightVisible = (
  state: RootState,
): TApplicationState['isBottomRightCopyrightVisible'] =>
  state.application.isBottomRightCopyrightVisible;

export const selectLanguage = (state: RootState): TApplicationState['language'] =>
  state.application.language;

export const selectScreenMode = (state: RootState): TApplicationState['screenMode'] =>
  state.application.screenMode;

export const selectScreenOrientation = (state: RootState): TApplicationState['screenOrientation'] =>
  state.application.screenOrientation;

export const selectSelectedMemberUuid = (
  state: RootState,
): TApplicationState['selectedMemberUuid'] => state.application.selectedMemberUuid;

export const selectSelectedStartDatetime = (
  state: RootState,
): TApplicationState['selectedStartDatetime'] => state.application.selectedStartDatetime;

export const selectSelectedEndDatetime = (
  state: RootState,
): TApplicationState['selectedEndDatetime'] => state.application.selectedEndDatetime;

export const selectSelectedDataResolution = (
  state: RootState,
): TApplicationState['selectedDataResolution'] => state.application.selectedDataResolution;
