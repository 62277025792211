import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BaseIcon } from 'src/components/BaseIcon';
import { getSubtitleProperty, getPropertyValue } from 'src/features/Assets/SingleAsset/utils';
import { TAssetCardProps } from 'src/features/Members/SingleMember/AssetsCard/AssetCard.types';
import { get3dAssetIcon } from 'src/utils/assetIcons';

import s from './AssetsCard.module.scss';

export const AssetsCard: React.FC<TAssetCardProps> = ({
  data,
  onClick,
  isMoreButtonActive = true,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(s.asset, s.gridItem, {
        [s.pointerNone]: !onClick,
      })}
      onClick={() => onClick?.(data.uuid!)}>
      {isMoreButtonActive && (
        <button className="moreMenu" type="button" title="More">
          <BaseIcon icon="more-horizontal" size={10} />
        </button>
      )}
      {false && (
        <div className={s.plugIcon}>
          <BaseIcon icon="plug" size={12} />
        </div>
      )}

      <div className="content">
        <div className={s.assetIcon}>
          <BaseIcon icon={get3dAssetIcon(data.type || '')} size={100} />
        </div>
        <h6 className={s.title}>{data.name}</h6>
        {['PV', 'AREA'].includes(data?.type ?? '') && (
          <p>{`${t(getSubtitleProperty(data))}: ${getPropertyValue(data) || '--'}`}</p>
        )}
      </div>
    </div>
  );
};
