import { ScmCoefficientAlgorithm, ScmSelfConsumptionTypeEnum } from 'src/graphql';
import { TEnumFieldTemplate } from 'src/utils/assetsFields/assetsFields.types';

export const SHARING_COEFFICIENT_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  {
    label: 'labels.FIXED_COEFFICIENTS',
    value: ScmCoefficientAlgorithm.Static,
  },
  {
    label: 'Variable Coefficient',
    value: ScmCoefficientAlgorithm.Dynamic,
  },
];

// see https://github.com/gridsingularity/gsy-framework/blob/95194f1e92937ceb0bce782ced0c67e04a66a140/gsy_framework/enums.py#L127
export const SELF_CONSUMPTION_TYPE_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  {
    label: 'labels.SIMPLIFIED_COLLECTIVE_SELF_CONSUMPTION',
    value: ScmSelfConsumptionTypeEnum.SimplifiedCollectiveSelfConsumption_41,
  },
  {
    label: 'labels.COLLECTIVE_SELF_CONSUMPTION_SURPLUS',
    value: ScmSelfConsumptionTypeEnum.CollectiveSelfConsumptionSurplus_42,
  },
  {
    label: 'labels.NO_SELF_CONSUMPTION',
    value: ScmSelfConsumptionTypeEnum.NoSelfConsumption,
  },
];
