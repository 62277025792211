import { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { selectAssetsValues } from 'src/redux/configuration/configuration.selectors';
import { TFlyToOptions, WorldMapService } from 'src/services/map/map.service';
import { TLngLat, TLngLatArray } from 'src/typings/base-types';

export type TUseFlyToArgs = {
  mapService?: WorldMapService;
};

export type TUseFlyToResponse = {
  flyWithCoordinates: (
    coordinates: TLngLatArray | null | undefined,
    options?: TFlyToOptions,
  ) => void;
  flyToAsset: (assetId: string, options?: TFlyToOptions) => void;
  assetFlyConfig: TFlyToOptions;
};

export const useFlyTo = ({ mapService }: TUseFlyToArgs): TUseFlyToResponse => {
  const assetsValues = useSelector(selectAssetsValues);

  const assetFlyConfig: TUseFlyToResponse['assetFlyConfig'] = { pitch: 45, specificZoom: 18 };
  // transform data from TLngLatArray to TLngLat
  const getCoordinatesFromLngLatArray = useCallback((coordinates: TLngLatArray): TLngLat => {
    return {
      lat: coordinates[1],
      lng: coordinates[0],
    };
  }, []);

  const getAssetCoordinatesFromAssetsValues = useCallback(
    (assetId: string) => {
      const asset = assetsValues[assetId];
      if (!asset) return null;
      return asset.geoTagLocation;
    },
    [assetsValues],
  );

  const flyWithCoordinates = useCallback(
    (coordinates: TLngLatArray | null | undefined, options?: TFlyToOptions) => {
      if (!mapService || !coordinates) return;
      mapService.flyTo(getCoordinatesFromLngLatArray(coordinates), options);
    },
    [mapService, getCoordinatesFromLngLatArray],
  );

  const flyToAsset = useCallback(
    (assetId: string, options?: TFlyToOptions) => {
      if (!mapService) return;
      const coordinates = getAssetCoordinatesFromAssetsValues(assetId);

      if (!coordinates) return;
      mapService.flyTo(getCoordinatesFromLngLatArray(coordinates), options);
    },
    [mapService, getAssetCoordinatesFromAssetsValues, getCoordinatesFromLngLatArray],
  );

  return {
    flyWithCoordinates,
    flyToAsset,
    assetFlyConfig,
  };
};
