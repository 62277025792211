import { camelCase as loashCamelCase } from 'lodash';

const camelCase = (str: string): string => {
  if (str === 'capacity_kW') return 'capacityKw';
  if (str === 'consumption_kWh_profile') return 'consumptionKwhProfile';
  return loashCamelCase(str);
};
export const objectCamelCase = <T>(obj: Record<string, unknown>): T => {
  const tmpObject: Record<string, unknown> = {};
  if (!obj) return {} as T;
  Object.keys(obj).forEach((item) => {
    tmpObject[camelCase(item)] = obj[item];
  });
  return tmpObject as T;
};
