import React, { useRef } from 'react';

import classNames from 'classnames';
import { BaseIcon } from 'src/components/BaseIcon';
import { get2dAssetIcon } from 'src/utils/assetIcons';

import s from './SingleArea.module.scss';
import { TSingleAreaAssetsTileProps } from './SingleArea.types';

export const SingleAreaAssetsTile: React.FC<TSingleAreaAssetsTileProps> = ({
  onClick,
  data,
  menuActive,
  onMenuClick,
}) => {
  const modalRefObject = useRef<HTMLDivElement>(null);

  return (
    <div className={classNames(s.assetListItem)}>
      <BaseIcon icon={get2dAssetIcon(data.type || 'Load')} size={16} />
      <p onClick={onClick}>{data.name}</p>
      {menuActive && (
        <div
          ref={modalRefObject}
          id={data.uuid}
          onClick={() => {
            if (modalRefObject.current) {
              onMenuClick?.(modalRefObject.current);
            }
          }}>
          <BaseIcon icon="more-horizontal" size={16} />
        </div>
      )}
    </div>
  );
};
