import React, { useContext } from 'react';

import WorldMapContext from 'src/contexts/WorldMapContext';
import { TableBillRow } from 'src/features/Results/SingleCommunityResults/TableBills/TableBillRow';
import { TableBillsHeader } from 'src/features/Results/SingleCommunityResults/TableBills/TableBillsHeader';
import { useFlyTo } from 'src/hooks/useFlyTo';
import { useResults } from 'src/hooks/useResults';
import { setSelectedMemberUuid } from 'src/redux/application/application.slice';
import { setIsAppLoading } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';

import s from './TableBills.module.scss';

export const TableBills: React.FC = () => {
  const dispatch = useAppDispatch();

  const { mapService } = useContext(WorldMapContext);

  const { flyToAsset, assetFlyConfig } = useFlyTo({ mapService });

  const { billsDifferencesCommunity } = useResults();

  const handleBillClick = (uuid: string) => {
    dispatch(setIsAppLoading(true));
    dispatch(setSelectedMemberUuid(uuid));

    flyToAsset(uuid, assetFlyConfig);

    setTimeout(() => {
      dispatch(setIsAppLoading(false));
    }, 300);
  };

  return (
    <div className={s.scrollableTable}>
      <table className={s.tableBills}>
        <TableBillsHeader />
        <tbody>
          {Object.entries(billsDifferencesCommunity).map(([uuid, bill]) => (
            <TableBillRow
              key={uuid}
              uuid={uuid}
              name={bill.name}
              bill={bill.bills}
              handleBillClick={handleBillClick}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
