import React, { useRef, useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseClose } from 'src/components/BaseClose';
import { BaseOverflowMenu } from 'src/components/BaseOverflowMenu';
import { FormEditPassword } from 'src/components/FormEditPassword';
import { ProfilePicture } from 'src/components/ProfilePicture';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { useMapNavigation } from 'src/hooks/useMapNavigation';
import { useModal } from 'src/hooks/useModal';
import { selectActiveDashboard } from 'src/redux/application/application.selectors';
import { setActiveDashboard } from 'src/redux/application/application.slice';
import { selectTokenAuth } from 'src/redux/auth/auth.selectors';
import { setInitialCommunity, setTokenAuth } from 'src/redux/auth/auth.slice';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { v4 } from 'uuid';

import s from './UserAvatar.module.scss';
import { TProfilePictureIconProps, TUserAvatarProps } from './UserAvatar.types';

export const ProfilePictureIcon: React.FC<TProfilePictureIconProps> = ({
  avatarUrl,
  name,
  username,
  className = {},
  forceRenderKey,
}: TProfilePictureIconProps) => {
  const finalName = name || username;

  if (avatarUrl) {
    return (
      // Add date as parameter to the end of the src, because src is permanent and dose not be changed by updating profile picture
      <img src={`${avatarUrl}?${forceRenderKey}`} className={className.picture} />
    );
  }

  if (finalName) {
    // User is logedin but doesn't have picture yet, show first letter of user's name
    return (
      <span className={className.nameInitials || s.nameInitials}>
        {finalName.charAt(0).toUpperCase()}
      </span>
    );
  }

  return null;
};

export const UserAvatar: React.FC<TUserAvatarProps> = () => {
  const { t } = useTranslation();
  const [forceRenderKey, setForceRenderKey] = useState<string>(v4());
  const modalRef = useRef<HTMLButtonElement>(null);
  const { id: userMenuModalId } = useModal();
  const { discardCurrentConfiguration } = useMapNavigation();
  const isUserMenuOpened = useSelector(selectIsModalOpened(userMenuModalId));
  const isModalProfilePictureCropperOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_PROFILE_PICTURE_CROPPER),
  );
  const [showEditPassword, setShowEditPassword] = useState(false);
  const dispatch = useAppDispatch();
  const userInfo = useSelector(selectTokenAuth);
  const activeDashboard = useSelector(selectActiveDashboard);

  const dropDownItems = [
    {
      title: 'navigation.EDIT_PASSWORD',
      onClick: () => setShowEditPassword(true),
    },
  ];

  const Logout = () => {
    batch(() => {
      discardCurrentConfiguration({
        zoomOut: true,
      });
      dispatch(setTokenAuth(null));
      dispatch(setInitialCommunity(null));

      if (activeDashboard) {
        dispatch(setActiveDashboard(undefined));
      }
    });
  };
  const EditPasswordComponent = (
    <div>
      <BaseClose className={s.closeBtn} onClick={() => setShowEditPassword(false)} />
      <FormEditPassword />
    </div>
  );

  const AvatarDropdownComponent = (
    <>
      <span className={classnames(s.menuItem, s.logout)} onClick={Logout}>
        {t('LOGOUT')}
      </span>
      <div className={s.picture}>
        <ProfilePicture setForceRenderKey={setForceRenderKey} />
      </div>
      <span className={classnames(s.menuItem, s.username, s.menuItemBottomBorder)}>
        {userInfo?.name || userInfo?.username}
      </span>
      {dropDownItems.map((item, index) => (
        <BaseButton
          className={classnames(s.menuItem, {
            [s.menuItemBottomBorder]: index !== dropDownItems.length - 1,
          })}
          key={index}
          onClick={item.onClick}>
          {t(item.title)}
        </BaseButton>
      ))}
    </>
  );

  return (
    <>
      <button
        ref={modalRef}
        className={s.profileBtn}
        type="button"
        onClick={() => {
          if (isUserMenuOpened) {
            dispatch(closeModal(userMenuModalId));
          } else {
            dispatch(openModal(userMenuModalId));
          }
        }}>
        {userInfo && (
          <ProfilePictureIcon
            avatarUrl={userInfo.profilePicture}
            name={userInfo.name}
            username={userInfo.username}
            forceRenderKey={forceRenderKey}
          />
        )}
      </button>
      {/* User menu */}
      <BaseOverflowMenu
        relativeElement={modalRef}
        modalId={userMenuModalId}
        position={{
          side: 'bottom',
          anchorPosition: 85,
        }}
        singleActionModal={isModalProfilePictureCropperOpened ? false : true}
        size={showEditPassword ? EModalSize.M250 : EModalSize.S115}
        className={s.profileMenu}>
        {showEditPassword ? EditPasswordComponent : AvatarDropdownComponent}
      </BaseOverflowMenu>
    </>
  );
};
