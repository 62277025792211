import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Header } from 'src/components/Header';
import { HeaderSteps } from 'src/components/HeaderSteps';
import { useReadConfigurationQuery } from 'src/graphql';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { selectScreenMode } from 'src/redux/application/application.selectors';
import { selectUsername } from 'src/redux/auth/auth.selectors';
import { selectCommunityUser } from 'src/redux/configuration/configuration.selectors';
import { EScreenMode } from 'src/typings/configuration.types';

import s from './TemplateDefault.module.scss';
import { TTemplateDefaultProps } from './TemplateDefault.types';

const path = window.location.pathname;
const match = path.match(/results\/([a-f0-9-]+)\//i);
const uuid = match ? match[1] : null;

export const TemplateDefault: React.FC<TTemplateDefaultProps> = ({ className, children }) => {
  const communityUser = useSelector(selectCommunityUser);
  const userName = useSelector(selectUsername);
  const screenMode = useSelector(selectScreenMode);

  const { isUserACommunityMember } = useIsUserACommunityMember();

  // Prefecthing
  useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !uuid,
    variables: { uuid: uuid! },
  });

  return (
    <div className={classnames(s.container, className)}>
      <Header />
      {!isUserACommunityMember &&
        (userName === communityUser || communityUser == '') &&
        screenMode !== EScreenMode.Mobile && <HeaderSteps />}
      {children}
    </div>
  );
};
