import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import {
  TFilterItem,
  AssetsListWithSearch,
} from 'src/features/Assets/ListAssets/ListAssetsWithSearch';
import { TLibrariesTags } from 'src/typings/base-types';

import { allCustomAssets, customHouse } from './ChooseAsset.constants';
import s from './NewAsset.module.scss';
import { TChooseAssetProps } from './NewAsset.types';

export const ChooseAsset: React.FC<TChooseAssetProps> = ({ setSelectedAsset }) => {
  const { t } = useTranslation();

  const [activeFilter, setActiveFilter] = useState<TLibrariesTags[]>(LIBRARY_FILTERS_MAPPING.All);

  const onAssetSelect = (data) => {
    setSelectedAsset({ name: data.title, type: data.type, uuid: data.uuid });
  };

  const onFilterChange = (filter: TFilterItem) => setActiveFilter(filter.value);

  const filteredAssets = useMemo(() => {
    if ([LIBRARY_FILTERS_MAPPING.All, LIBRARY_FILTERS_MAPPING.Custom].includes(activeFilter)) {
      return allCustomAssets;
    } else if (activeFilter === LIBRARY_FILTERS_MAPPING.Area) {
      return [customHouse];
    }
    return allCustomAssets.filter((asset) => asset.type.includes(activeFilter[0]));
  }, [activeFilter]);

  return (
    <div className={s.chooseAsset}>
      <div className={s.content}>
        <div className={s.typeSelector}>
          <h5>{t('tooltips.WHICH_ASSET')}</h5>
          <p>{t('tooltips.ADD_ASSET_INFO')}</p>
        </div>
        <AssetsListWithSearch
          disableDescription={true}
          disableAssetDescription={false}
          assets={filteredAssets as any}
          isAllButtonRequiredInFilter={false}
          searchQuery={''}
          onSearchQueryChange={() => null}
          onAssetChoose={onAssetSelect}
          loading={false}
          error={undefined}
          activeFilter={activeFilter}
          onFilterChange={onFilterChange}
          theme="light"
          listViewProp="3grid"
          searchPosition="top"
          showCustomAssetFilter={true}
        />
      </div>
    </div>
  );
};
