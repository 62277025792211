import React, { useContext, useRef, useState, useMemo } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { EPredefinedModalIds } from 'src/constants/modals';
import { ApplicationContext } from 'src/contexts/ApplicationContext';
import {
  BasicSettingsForm,
  TBasicSettingsFormProps,
} from 'src/features/Communities/EditCommunity/BasicSettingsForm';
import { SpotMarketType, useCreateConfigurationMutation } from 'src/graphql';
import { ListScmDocument } from 'src/graphql';
import { AllowedConfigType } from 'src/graphql';
import { useCreateProjectMutation } from 'src/graphql';
import { ConfigurationFieldsFragment } from 'src/graphql';
import { ScenarioInput } from 'src/graphql';
import { setActiveConfigurationUuid } from 'src/redux/configuration/configuration.slice';
import { selectSelectedLocation } from 'src/redux/map/map.selectors';
import { openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { dispatchErrorToast } from 'src/utils/toasts';
import { v4 } from 'uuid';

import s from './NewCommunity.module.scss';
import { EActiveTabState } from './NewCommunity.types';

export const NewCommunity: React.FC = () => {
  const dispatch = useAppDispatch();

  const { triggerResultsLossAlert } = useContext(ApplicationContext);

  const selectedLocation = useSelector(selectSelectedLocation);

  const [activeTab, setActiveTab] = useState<EActiveTabState>(EActiveTabState.General);
  const [newCommunity, setNewCommunity] = useState<ConfigurationFieldsFragment | null>(null);

  const formId = useRef(v4());

  const centreCoordinates = useMemo(() => {
    return {
      longitude: selectedLocation?.lng,
      latitude: selectedLocation?.lat,
    };
  }, [selectedLocation]);

  const [createConfiguration] = useCreateConfigurationMutation({
    errorPolicy: 'none',
    refetchQueries: [
      { query: ListScmDocument, variables: { configType: AllowedConfigType.Collaboration } },
    ],
    onCompleted: (data) => {
      if (data.createConfiguration && data.createConfiguration.uuid) {
        setNewCommunity(data.createConfiguration);
        dispatch(setActiveConfigurationUuid(data.createConfiguration.uuid));
      }
    },
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });

  const [createProject] = useCreateProjectMutation({
    errorPolicy: 'none',
    onCompleted: (response) => {
      if (newCommunity && response.createProject && newCommunity.settingsData) {
        createConfiguration({
          variables: {
            name: newCommunity.name,
            description: newCommunity.description,
            projectUuid: response.createProject.uuid!,
            settingsData: {
              slotLengthMinutes: 15,
              startDate: newCommunity.settingsData.startDate,
              endDate: newCommunity.settingsData.endDate,
              spotMarketType: SpotMarketType.Coefficients,
              currency: newCommunity.settingsData.currency,
            },
            scenarioData: { representation: null } as ScenarioInput,
            configType: AllowedConfigType.Collaboration,
            centreCoordinates,
          },
        });
        dispatch(openModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
      }
    },
  });

  const onSubmit: TBasicSettingsFormProps['onSubmit'] = async (payload) => {
    await triggerResultsLossAlert();

    if (payload && newCommunity === null) {
      setNewCommunity(payload);
      createProject({
        variables: {
          name: payload.name || '',
          description: payload.description,
          locationVisible: true,
          private: false,
          centreCoordinates,
        },
      });
    } else {
      dispatch(openModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
    }
  };

  return (
    <div className={s.container}>
      <div className={s.header}>
        <ul className={s.headerNav}>
          <li
            className={classnames({
              [s.active]: activeTab === EActiveTabState.General,
            })}>
            <button
              type="button"
              onClick={() => setActiveTab(EActiveTabState.General)}
              title="Profile">
              General Information
            </button>
          </li>
        </ul>
      </div>
      <BasicSettingsForm className={s.basic} id={formId.current} onSubmit={onSubmit} theme="dark" />
    </div>
  );
};
