import React, { useCallback, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import * as d3 from 'd3';
import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import { EventsTooltip } from 'src/components/SimulationProgressModal/components/EventsTooltip/EventsTooltip';
import { useListLiveEventsLazyQuery } from 'src/graphql';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { UTCMoment } from 'src/utils/UTCMoment';

import s from './TimelineEventChart.module.scss';
import { TTimelineEventChartProps } from './TimelineEventChart.types';
import { prepareDataForTooltip } from './utils';

export const TimelineEventChart: React.FC<TTimelineEventChartProps> = ({
  isSidebarOpened,
  graphWidth,
  durationFilter,
  graphStartDate,
  graphEndDate,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [isEventClicked, setIsEventClicked] = useState<boolean>(false);
  const [, setTimeStamp] = useState<string | null>(null);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const [listLiveEvents, { data }] = useListLiveEventsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const hideTooltip = (isTooltipOpened) => {
    const tooltip = tooltipRef?.current;
    if (tooltip) {
      tooltip.style.display = 'none';
      if (isTooltipOpened) {
        setIsEventClicked(false);
        setTimeStamp(null);
      }
    }
  };

  const showTooltip = (evt, data, isEventActive = false, isSidebarOpened) => {
    const timeStamp = data[0];
    const events = data[1];
    const tooltipData = prepareDataForTooltip(events);
    const tooltip = tooltipRef?.current;
    setTimeStamp(timeStamp);
    if (tooltip) {
      tooltip.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <EventsTooltip isActive={isEventActive} data={tooltipData} dateTime={timeStamp} />,
      );
      tooltip.style.display = 'block';
      tooltip.style.left = isSidebarOpened ? evt.pageX - 470 + 'px' : evt.pageX - 95 + 'px';
      tooltip.style.top = isEventActive ? 100 - tooltipData.length * 25 + 'px' : '112px';
    }
  };

  const renderChart = useCallback(
    (durationFilter, graphStartDate, graphEndDate, isSidebarOpened) => {
      const eventChartProperty = {
        All: { startTick: 7, iconPosition: 20 },
        '1W': { startTick: 7, iconPosition: 20 },
        '1D': { startTick: 24, iconPosition: 20 },
        '1M': { startTick: 30, iconPosition: 20 },
      };
      const filter = durationFilter ? durationFilter : '1M';
      const currentFilter = eventChartProperty[filter];
      const margin = { top: 0, right: 0, bottom: 90, left: 0 };
      const width = graphWidth - 20;
      const height = 90 - margin.top - margin.bottom;
      // update date here.
      const startdate = UTCMoment.fromBackend(graphStartDate);
      const senddate = UTCMoment.fromBackend(graphEndDate);
      // ticks for main dates
      const startTicks = currentFilter.startTick;
      // x scale
      const xScale = d3
        .scaleUtc()
        .nice(d3.timeDay)
        .domain([startdate, senddate])
        .range([0, width - 110]);
      // x scale for hours
      const hoursAxis = d3
        .axisBottom(xScale)
        .ticks(d3.timeHour, 2)
        .tickPadding(1)
        .tickSize(15)
        // @ts-ignore
        .tickFormat('');

      const eventsData = data?.listLiveEvents?.length ? data.listLiveEvents : [];

      // @ts-ignore
      const grouped_data = d3.group(eventsData, (d) => d.timeStamp);

      /*const getIconLink = (d, isHover) => {
        const events = d[1];
        const eventType = events[0]?.eventType;
        const parameters = JSON.parse(events[0].diff);
        const assetType = parameters.new_representation
          ? parameters.new_representation?.type
          : parameters.old_representation?.type;
        const iconFileName = eventType + '_' + assetType;
        const iconFile = isHover
          ? assetTypeStaticIconsHover[iconFileName]
          : assetTypeStaticIcons[iconFileName];

        return iconFile;
      };*/
      // svg
      const svg = d3
        .select('#eventchart')
        .append('svg')
        .attr('width', width - 100)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      // clippath to contain the graph
      // svg.append('defs').attr('x', 0).attr('width', width).attr('height', height);

      // x-axis
      svg
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(xScale).ticks(startTicks).tickSize(30));

      svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .attr('class', 'x-axis')
        .classed('hours', true)
        .call(hoursAxis);

      const icons = svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .classed('hours', true)
        .attr('class', 'y-axis')
        .selectAll('g')
        .data(grouped_data)
        .enter();

      // circle with counts
      const multicircle = icons
        .filter((d) => {
          const events = d[1];
          return events.length > 1;
        })
        .append('g')
        .on('mouseover', (e) => {
          d3.select(e.target.parentNode).select('text').style('fill', '#6e6');
          d3.select(e.target.parentNode).select('circle').style('fill', 'black');
        })
        .on('mouseout', (e) => {
          d3.select(e.target.parentNode).select('text').style('fill', 'black');
          d3.select(e.target.parentNode).select('circle').style('fill', 'transparent');
        })
        .attr('transform', 'translate(0,' + height + ')');

      multicircle
        .append('circle')
        .attr('class', classnames('event-icon', s.cursorPointer))
        .attr('r', 14)
        .attr('cx', (e) => {
          const timestamp = e[0];
          const sss = UTCMoment.fromBackend(timestamp);
          return xScale(sss) - 15;
        })
        .attr('cy', 60)
        .attr('stroke', 'black')
        .style('stroke-width', 2)
        .attr('fill', 'transparent')
        .on('click', (e, data) => {
          //  Click logic goes here
          showTooltip(e, data, true, isSidebarOpened);
          setIsEventClicked(true);
        });

      multicircle
        .append('text')
        .attr('class', classnames('event-icon', s.cursorPointer))
        .attr('x', (e) => {
          const timestamp = e[0];
          const sss = UTCMoment.fromBackend(timestamp);
          return xScale(sss) - 20;
        })
        .attr('y', 65)
        .style('fill', 'black')
        .style('font-size', '16px')
        .style('font-weight', 'bold')

        .text((d) => {
          const events = d[1];
          return events.length;
        })
        .on('click', (e, data) => {
          //  Click logic goes here
          showTooltip(e, data, true, isSidebarOpened);
          setIsEventClicked(true);
        });

      // icons only
      /*const imageIcons = icons
        .filter((d) => {
          const events = d[1];
          return events.length === 1;
        })
        .append('image')
        .attr('x', (e) => {
          const timestamp = e[0];
          const sss = UTCMoment.fromBackend(timestamp);
          return xScale(sss) - currentFilter.iconPosition;
        })
        .attr('y', 40)
        .attr('class', classnames('event-icon', s.cursorPointer, 'event-icon-hover'))
        .attr('xlink:href', (d) => {
          const timestampClicked = d[0] == currentTimeStamp && isEventClicked;
          let icon = getIconLink(d, timestampClicked);
          return icon;
        })
        .attr('width', 40)
        .attr('height', 40)
        .on('click', (e, data) => {
          //  Click logic goes here
          showTooltip(e, data, true, isSidebarOpened);
          setIsEventClicked(true);
          // if event type is create, show results of asset in sidebar
          if (data[1][0]?.eventType === EEventType.added) {
            updateSidebarData(data[1][0]);
          }
        });*/

      // tooltip on mouse hover
      if (!isEventClicked) {
        d3.selectAll('.event-icon')
          .on('mouseover', (e, data) => showTooltip(e, data, false, isSidebarOpened))
          .on('mouseout', () => hideTooltip(isEventClicked));
      }

      // updated line color here
      d3.select('#eventchart').selectAll('line').style('stroke', '#a0a0ac');
    },
    [isEventClicked, data?.listLiveEvents, graphWidth],
  );

  useEffect(() => {
    if (activeConfigurationUuid) {
      listLiveEvents({
        variables: {
          configUUID: activeConfigurationUuid,
        },
      });
    }
  }, [listLiveEvents, activeConfigurationUuid]);

  useEffect(() => {
    const elem = document.querySelector('#eventchart svg');
    elem?.remove();
    renderChart(durationFilter, graphStartDate, graphEndDate, isSidebarOpened);
  }, [durationFilter, graphStartDate, graphEndDate, renderChart, isSidebarOpened]);

  // close tooltip onClick is handled here:
  // https://stackoverflow.com/questions/36323336/onclick-handler-not-registering-with-reactdomserver-rendertostring
  useEffect(() => {
    if (isEventClicked) {
      const interval = setInterval(() => {
        const elememt = document.getElementById('tooltip-close');
        if (elememt) {
          elememt.onclick = () => hideTooltip(isEventClicked);
        }
      });
      return () => clearInterval(interval);
    }
  }, [isEventClicked]);

  // close tooltip when the price/volume or duration filter is changed:
  useEffect(() => {
    hideTooltip(true);
  }, [durationFilter, graphStartDate, graphEndDate]);

  return (
    <div className={s.content}>
      <div ref={tooltipRef} className={s.tooltip} style={{ display: 'none' }} />
      <div className={s.chart}>
        <div id="eventchart"></div>
      </div>
    </div>
  );
};
