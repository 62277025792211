import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import { ContextMenu } from 'src/components/ContextMenu';
import { Tabs } from 'src/components/Tabs';
import { InputDataStreamId } from 'src/features/Assets/InputDataStreamId';
import { useRemoveConfigurationAreaMutation } from 'src/graphql';
import { ReadConfigurationDocument } from 'src/graphql';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useModal } from 'src/hooks/useModal';
import {
  selectActiveConfigurationUuid,
  selectReadOnly,
} from 'src/redux/configuration/configuration.selectors';
import {
  setSelectedAreaUuid,
  setSelectedAssetUuid,
} from 'src/redux/configuration/configuration.slice';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { get3dAssetIcon } from 'src/utils/assetIcons';
import { dispatchSuccessToast, dispatchErrorToast } from 'src/utils/toasts';

import s from './SingleArea.module.scss';
import { SingleAreaAssetsTile } from './SingleAreaAssetsTile';

export const SingleArea: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { id: modalId } = useModal();
  const { area, assets } = useConfiguration();

  const modalIsOpen = useSelector(selectIsModalOpened(modalId));
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const readOnly = useSelector(selectReadOnly);

  const [menuModalTriggerRef, setMenuModalTriggerRef] = useState<
    React.RefObject<HTMLButtonElement | null>
  >({
    current: null,
  });

  const [removeConfigurationArea] = useRemoveConfigurationAreaMutation({
    refetchQueries: [{ query: ReadConfigurationDocument, variables: { uuid: configUuid } }],
    onCompleted: () => {
      dispatchSuccessToast();
    },
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });

  const closeActiveModal = () => {
    dispatch(closeModal(modalId));
  };

  const onCloseClick = () => dispatch(setSelectedAreaUuid(undefined));

  const onMenuClick = (menuTriggerRef) => {
    if (modalIsOpen) {
      closeActiveModal();
    } else {
      setMenuModalTriggerRef({ current: menuTriggerRef });
      dispatch(openModal(modalId));
    }
  };

  return (
    <div className={s.container}>
      <header className={s.header}>
        <h3>{`${area?.name}'s ${t('common.HOME')}`}</h3>
        <BaseButtonSquare size="2" icon="close" theme="flat-gray-light" onClick={onCloseClick} />
      </header>
      {area && (
        <div className={s.wrapper}>
          <div className={s.hero}>
            <header>
              <div className={s.userPill}>
                <span>{area.name}</span>
              </div>
              <div className={s.buttonContainer}>
                <BaseButtonSquare
                  onClick={() => {
                    if (!configUuid || !area.uuid) return;

                    removeConfigurationArea({
                      variables: {
                        configurationUuid: configUuid,
                        areaUuid: area.uuid!,
                      },
                    });
                  }}
                  className={s.button}
                  icon="trash"
                  size="2"
                />
              </div>
            </header>
            <div className={s.icon}>
              <BaseIcon icon={get3dAssetIcon(area?.type || 'Area')} />
            </div>
            <p>{area.name}</p>
            <p>
              {t('common.ASSETS')}: {assets?.length || 0}
            </p>
          </div>
          <InputDataStreamId className={s.dataStreamId} />
          <Tabs
            tabs={['common.ASSETS']}
            activeTab={'common.ASSETS'}
            handleClick={() => null}
            className={s.tabs}
          />
          <div className={s.assetsList}>
            <div className={s.content}>
              {assets?.length ? (
                assets.map((asset) => (
                  <React.Fragment key={asset.name}>
                    <SingleAreaAssetsTile
                      key={asset?.name}
                      data={asset}
                      onClick={() => null}
                      menuActive={true}
                      onMenuClick={onMenuClick}
                    />
                  </React.Fragment>
                ))
              ) : (
                <p style={{ textAlign: 'center', opacity: 0.6 }}>
                  <strong>{area?.name}</strong> {t('messages.NO_CHILD_ASSETS')}
                </p>
              )}
              {/* PH-1265-Hide add asset button */}
              {!readOnly && (
                <button
                  type="button"
                  className={s.addAssetButton}
                  onClick={() => dispatch(setSelectedAssetUuid('NEW_ASSET'))}>
                  <BaseIcon icon="plus" size={9} /> {t('commands.ADD_ASSET')}
                  {area && <span>({area.name})</span>}
                </button>
              )}
            </div>
          </div>
          <ContextMenu
            relativeElement={menuModalTriggerRef}
            modalId={modalId}
            position={{
              side: 'right',
              anchorPosition: 50,
            }}
            items={[
              {
                title: t('commands.EDIT'),
                icon: 'pencil-edit',
                onClick: () => {
                  dispatch(setSelectedAssetUuid(menuModalTriggerRef.current?.id));
                },
              },
              {
                title: t('commands.REMOVE'),
                icon: 'custom-trash',
                onClick: () => {
                  if (configUuid && !!menuModalTriggerRef?.current?.id) {
                    removeConfigurationArea({
                      variables: {
                        configurationUuid: configUuid,
                        areaUuid: menuModalTriggerRef.current.id,
                      },
                    });
                  }
                },
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};
