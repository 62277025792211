import React, { useCallback, useEffect, useRef, useState } from 'react';

import { batch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ETabs } from 'src/components/Tabs';
import { ListItemBlock } from 'src/features/Communities/ListCommunities/ListItem/CommunityListItem/ListItemBlock';
import { ListItemDefault } from 'src/features/Communities/ListCommunities/ListItem/CommunityListItem/ListItemDefault';
import { ConfigType, useReadConfigurationLazyQuery } from 'src/graphql';
import { selectAppMode } from 'src/redux/application/application.selectors';
import { selectIsLoggedIn, selectUsername } from 'src/redux/auth/auth.selectors';
import {
  setActiveConfigurationJobUuid,
  setActiveConfigurationUuid,
} from 'src/redux/configuration/configuration.slice';
import { selectActiveCommunityListTab } from 'src/redux/scm/scm.selectors';
import { setActiveSCMStep } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';
import { EAppMode } from 'src/utils/appMode';
import { getPlaceNameFromCoords } from 'src/utils/worldMap/helpers';

import { TCommunityListItemProps } from './CommunityListItem.types';
export const CommunityListItem: React.FC<TCommunityListItemProps> = function CommunityListItem({
  item,
  className,
  style,
  description,
  onMenuClick,
}) {
  const dispatch = useAppDispatch();

  const belongsToLoggedUser = item.user === useSelector(selectUsername);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const appMode = useSelector(selectAppMode);
  const activeCommunitiesListTab = useSelector(selectActiveCommunityListTab);

  const modalRefObject = useRef<HTMLButtonElement>(null);

  const [place, setPlace] = useState('');
  const [jobId] = useState<string>();
  const [communityId] = useState<string>();

  const history = useHistory();
  const [getConfiguration] = useReadConfigurationLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      if (!!data.readConfiguration) {
        batch(() => {
          dispatch(setActiveConfigurationUuid(item.uuid?.toString()));
          if (jobId) {
            dispatch(setActiveConfigurationJobUuid(jobId));
          }
          history.push(routesConfig.scmMapResults(item.uuid?.toString(), ''));
        });
      }
    },
  });

  const goToResults = useCallback(() => {
    if (!!item.uuid) {
      getConfiguration({ variables: { uuid: item.uuid.toString() } });
    }
  }, [item, getConfiguration]);

  const handleCommunityClick = useCallback(() => {
    goToResults();
    if (activeCommunitiesListTab === ETabs.OPERATIONAL) {
      dispatch(setActiveSCMStep(4));
    }
  }, [goToResults, activeCommunitiesListTab, dispatch]);

  const handleMenuClick = useCallback(
    (buttonRef: HTMLButtonElement) => {
      onMenuClick(buttonRef, {
        communityUuid: item.uuid?.toString(),
      });
    },
    [item, onMenuClick],
  );

  const commonProps = {
    onCommunityClick: handleCommunityClick,
    onMenuClick: handleMenuClick,
    communityId: communityId || '',
    jobId: jobId || '',
    className,
    style,
    place,
    title: item.name ?? '',
    isLoggedIn,
    modalRefObject,
    belongsToLoggedUser,
    configType: (item.type as ConfigType) ?? ('defaultConfigType' as ConfigType),
  };

  useEffect(() => {
    let isComponentMounted = true;
    const asyncAction = async () => {
      if (item && item.coordinates?.longitude && item.coordinates?.latitude) {
        const v = await getPlaceNameFromCoords([
          item.coordinates.longitude,
          item.coordinates.latitude,
        ]);
        if (isComponentMounted) {
          setPlace(v?.place_name || '');
        }
      }
    };

    asyncAction();

    return () => {
      isComponentMounted = false;
    };
  }, [item]);

  /**
   * ListItemDefault => Default view for desktop mode
   * ListItemBlock => Default view for mobile mode
   */
  return appMode === EAppMode.Desktop ? (
    <ListItemDefault
      item={item}
      activeCommunitiesListTab={activeCommunitiesListTab}
      description={description}
      onResultsClick={goToResults}
      {...commonProps}
    />
  ) : (
    <ListItemBlock loading={false} {...commonProps} />
  );
};
