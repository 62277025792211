import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseClose } from 'src/components/BaseClose';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { ContextMenu } from 'src/components/ContextMenu';
import { ListCommunityAssetsTile } from 'src/features/Assets/ListAssets/ListCommunityAssets/ListCommunityAssetsTile';
import { AreaOutput } from 'src/graphql';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useModal } from 'src/hooks/useModal';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import {
  setSelectedAreaUuid,
  setSelectedAssetUuid,
} from 'src/redux/configuration/configuration.slice';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import {
  selectIsOperationalCommunity,
  selectMeasurementRequests,
} from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';
import { objectCamelCase } from 'src/utils/objectCamelCase';

import s from './ListCommunityAssets.module.scss';
import { TListCommunityAssetsProps } from './ListCommunityAssets.types';

export const ListCommunityAssets: React.FC<TListCommunityAssetsProps> = ({ searchValue }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { id: modalId } = useModal();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const modalIsOpen = useSelector(selectIsModalOpened(modalId));
  const [menuModalTriggerRef, setMenuModalTriggerRef] = useState<
    React.RefObject<HTMLButtonElement | null>
  >({
    current: null,
  });
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const measurementRequests = useSelector(selectMeasurementRequests);

  const [selectedAreaUuids, setSelectedAreaUuids] = useState<string[]>([]);
  const [, setOpenedAssetConfigUuid] = useState<string | null>(null);

  const { isOwnerAdmin, isOwnerCM } = useIsAdminInspecting();
  const { communityArea, communityAreas } = useConfiguration();

  const dropDownItems: {
    title: string;
    icon: TIconNames;
    onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  }[] = [];

  if (isLoggedIn && (isOwnerAdmin || isOwnerCM)) {
    dropDownItems.push({
      title: t('commands.REMOVE'),
      icon: 'custom-trash',
      onClick: () => {
        dispatch(closeModal(modalId));
      },
    });
  }

  const closeActiveModal = () => {
    setOpenedAssetConfigUuid(null);
    setMenuModalTriggerRef({ current: null });
    dispatch(closeModal(modalId));
  };

  const onMenuClick = (menuTriggerRef, data) => {
    if (modalIsOpen) {
      closeActiveModal();
    } else {
      setOpenedAssetConfigUuid(data.itemUuid);
      setMenuModalTriggerRef({ current: menuTriggerRef });
      dispatch(openModal(modalId));
    }
  };

  const onAreaClick = (area: AreaOutput) => {
    if (selectedAreaUuids.includes(area.uuid)) {
      return setSelectedAreaUuids(selectedAreaUuids.filter((item) => item !== area.uuid));
    }
    return setSelectedAreaUuids(selectedAreaUuids.concat(area.uuid));
  };

  const onAssetClick = (areaUuid: string, assetUuid) => {
    dispatch(setSelectedAreaUuid(areaUuid));
    dispatch(setSelectedAssetUuid(assetUuid));
  };

  return (
    <div className={s.assetsList}>
      <React.Fragment key={0}>
        <ListCommunityAssetsTile
          asset={communityArea}
          childrenCount={communityArea?.children?.length || 0}
          selected={false}
          handleClick={undefined}
          onMenuClick={undefined}
          onExpandClick={undefined}
          measurementStatus={null}
        />
      </React.Fragment>
      {communityAreas
        .filter((area) =>
          !!searchValue && searchValue.length > 0 ? area.name.includes(searchValue) : true,
        )
        .sort((a, b) => a?.name?.localeCompare(b.name, undefined, { numeric: true }))
        .map((area) => {
          const isSelected = selectedAreaUuids.includes(area.uuid);

          const parentMeasurementStatus =
            measurementRequests.find((item) => item.areaUuid === area.uuid)?.status || null;

          return (
            <React.Fragment key={area.uuid}>
              <ListCommunityAssetsTile
                asset={area}
                childrenCount={area?.children?.length || 0}
                selected={isSelected}
                handleClick={() => onAreaClick(area)}
                onMenuClick={
                  dropDownItems.length > 0 && !isOperationalCommunity ? onMenuClick : undefined
                }
                measurementStatus={parentMeasurementStatus}
              />
              {isSelected &&
                area.children?.map((asset) => {
                  if (asset) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    asset = objectCamelCase<any>(asset);
                  }
                  const childMeasurementStatus =
                    measurementRequests.find((item) => asset && item.areaUuid === asset.uuid)
                      ?.status || null;

                  return (
                    !!asset &&
                    !!asset.uuid && (
                      <ListCommunityAssetsTile
                        asset={asset}
                        key={asset.uuid}
                        isChildren
                        selected={false}
                        handleClick={() => onAssetClick(area!.uuid, asset!.uuid)}
                        measurementStatus={childMeasurementStatus}
                      />
                    )
                  );
                })}
            </React.Fragment>
          );
        })}
      <ContextMenu
        relativeElement={menuModalTriggerRef}
        modalId={modalId}
        position={{
          side: 'right',
          anchorPosition: 50,
        }}
        items={dropDownItems}
      />
      {errorMessage && (
        <div
          className={s.customToast}
          style={{
            top: menuModalTriggerRef.current?.getBoundingClientRect().top
              ? menuModalTriggerRef.current?.getBoundingClientRect().top - 45
              : 0,
          }}>
          {errorMessage} <BaseClose className={s.closeBtn} onClick={() => setErrorMessage(null)} />
        </div>
      )}
    </div>
  );
};
