import React, { useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { InputFieldWithOk } from 'src/components/InputField';
import {
  ConfigType,
  ListMeterDataStreamsDocument,
  MeterDataStream,
  useSetMeterDataStreamsMutation,
} from 'src/graphql';
import { useConfiguration } from 'src/hooks/useConfiguration';
import {
  selectActiveConfigurationUuid,
  selectSelectedAreaUuid,
  selectSelectedAssetUuid,
} from 'src/redux/configuration/configuration.selectors';
import { areArraysOfObjectsEqualByFields } from 'src/utils/general';
import { dispatchSuccessToast, dispatchErrorToast } from 'src/utils/toasts';

type TInputDataStreamIdProps = {
  className: string;
};
export const InputDataStreamId: React.FC<TInputDataStreamIdProps> = ({ className }) => {
  const { configuration, meterDataStreams } = useConfiguration();

  const selectedAssetUuid = useSelector(selectSelectedAssetUuid);
  const selectedAreaUuid = useSelector(selectSelectedAreaUuid);
  const configUuid = useSelector(selectActiveConfigurationUuid);

  const [newMeterDataStreams, setNewMeterDataStreams] = useState<MeterDataStream[] | null>(
    meterDataStreams,
  );
  const [originalMeterDataStream, setOriginalMeterDataStream] = useState<MeterDataStream[] | null>(
    meterDataStreams,
  );

  const areaUuid: string = useMemo(
    () =>
      /* When asset is selected both selectedAreaUuid and selectedAssetUuid are not None */
      !!selectedAssetUuid ? selectedAssetUuid : selectedAreaUuid || '',
    [selectedAreaUuid, selectedAssetUuid],
  );

  const [setMeterDataStream] = useSetMeterDataStreamsMutation({
    refetchQueries: [
      { query: ListMeterDataStreamsDocument, variables: { configUuid: configUuid! } },
    ],
    onCompleted: () => {
      dispatchSuccessToast();
      setOriginalMeterDataStream(newMeterDataStreams);
    },
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });

  const onChangeMeterDataStreamId = ({ value }) => {
    if (!!selectedAreaUuid) {
      /* When asset is selected both selectedAreaUuid and selectedAssetUuid are not None */
      const dataStream = newMeterDataStreams?.find(
        (dataStream) => dataStream.areaUuid === areaUuid,
      );
      return setNewMeterDataStreams((prevStreams) => {
        if (!prevStreams) return null;
        if (!!dataStream) {
          return prevStreams?.map((stream) => {
            return stream.areaUuid === areaUuid
              ? { areaUuid, streamId: value }
              : { areaUuid: stream.areaUuid, streamId: stream.streamId };
          });
        }
        return [...prevStreams, { areaUuid, streamId: value }];
      });
    }
  };
  const onSubmitMeterDataStreamId = () => {
    if (newMeterDataStreams && configUuid) {
      setMeterDataStream({
        variables: {
          configUuid,
          meterIds: newMeterDataStreams,
        },
      });
    }
  };

  if (configuration?.type !== ConfigType.CanaryNetwork) return null;

  return (
    <InputFieldWithOk
      className={className}
      type="text"
      name="datastreamId"
      label="labels.DATASTREAM_ID"
      value={newMeterDataStreams?.find((stream) => stream.areaUuid === areaUuid)?.streamId}
      onChange={onChangeMeterDataStreamId}
      onClick={onSubmitMeterDataStreamId}
      disabled={
        !newMeterDataStreams ||
        !originalMeterDataStream ||
        areArraysOfObjectsEqualByFields(newMeterDataStreams, originalMeterDataStream)
      }
    />
  );
};
