import { useCallback, useEffect, useRef } from 'react';

import { useApolloClient } from '@apollo/client';
import { batch, useSelector } from 'react-redux';
import { matchPath, useHistory, useRouteMatch } from 'react-router';
import {
  ListMeasurementVerificationRequestsQuery,
  Maybe,
  ReadConfigurationDocument,
  ReadConfigurationQuery,
  ReadConfigurationQueryResult,
  ReadConfigurationQueryVariables,
  SpotMarketType,
} from 'src/graphql';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { useMapNavigation } from 'src/hooks/useMapNavigation';
import { useMeasurementQueries } from 'src/hooks/useMeasurementQueries';
import { selectIsEmbed } from 'src/redux/application/application.selectors';
import { setCommunityNotFound } from 'src/redux/application/application.slice';
import { selectIsAdmin, selectUserRole } from 'src/redux/auth/auth.selectors';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import {
  configurationReset,
  setActiveConfigurationJobUuid,
  setActiveConfigurationUuid,
  setConfiguration,
  updateConfigType,
  updateReadOnly,
} from 'src/redux/configuration/configuration.slice';
import { setMeasurementNotificationList } from 'src/redux/notifications/notificaitons.slice';
import { TMeasurementNotification } from 'src/redux/notifications/notifications.types';
import {
  setIsAppLoading,
  setIsOperationalCommunity,
  setSCMFlow,
  setCommunityMembers,
} from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';
import { routesConfig } from 'src/routes/routes.config';
import { EUserRoles } from 'src/typings/base-types';
import { TAsset } from 'src/typings/configuration.types';
import { NN } from 'src/typings/helpers';
import { destructureConfigTree } from 'src/utils/configuration/destructureConfigTree';
import { getCommunityAsset } from 'src/utils/configuration/getCommunityAsset';
import { parseSimulationResults } from 'src/utils/parseSimulationResults';

export type TSimulationResultData = ReturnType<typeof parseSimulationResults> & {
  assetUuid: TAsset['uuid'];
};

/**
 *
 *  __useConfigurationEffects__
 *
 *  This hook is important part of app flow. It's used for:
 *  - Fetching configuration on activeConfigurationUuid change
 *  - Parsing configuration and storing in redux
 *  - Fetching simulation results
 *  - Simulation subscription handling
 *  - Parsing and passing configuration results to redux
 *  - Catching route change, matching and setting activeConfigurationUuid
 *
 */
export function useConfigurationEffects(): void {
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const history = useHistory();
  const location = useAppLocation();
  const isEmbed = useSelector(selectIsEmbed);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const activeConfigurationRef = useRef<string | undefined>(undefined);
  const userRole = useSelector(selectUserRole);
  const isAdmin = useSelector(selectIsAdmin);
  const { isUserACommunityMember } = useIsUserACommunityMember();

  const { zoomIntoConfiguration, discardCurrentConfiguration } = useMapNavigation();

  const listMeasurementVerificationRequestsOnCompleted = useCallback(
    (data: ListMeasurementVerificationRequestsQuery) => {
      if ((isAdmin || userRole === EUserRoles.ExchangeOperator) && data) {
        const notificationData = data.listMeasurementVerificationRequests?.filter(
          (item) => item !== null && item?.status,
        ) as Maybe<Array<TMeasurementNotification>> | undefined;

        if (notificationData && notificationData.length > 0) {
          dispatch(setMeasurementNotificationList(notificationData));
        }
      }
    },
    [isAdmin, userRole, dispatch],
  );

  const { listMeasurementVerificationRequests } = useMeasurementQueries({
    listMeasurementVerificationRequestsOnCompleted,
  });

  /**
   *
   * Match route with map results path. It helps us to get configuration and asset uuids
   *
   */
  const paramsMatch = useRouteMatch<{ configurationUuid: string; assetUuid: string }>({
    path: isEmbed
      ? routesConfig.embed('')
      : [
          routesConfig.scmMapResults(undefined, ''),
          routesConfig.scmMapResults(undefined, undefined),
        ],
  });
  /**
   *
   *  __updateConfigurationStoreData__
   *
   *  Update configuration in redux store
   *  @param locationVisible Location visible param
   *  @param configurationData Parsed configuration data object
   *
   */
  const updateConfigurationStoreData = useCallback(
    (
      locationVisible,
      {
        jobUuid,
        type,
        readOnly,
        user,
        timezone,
        project,
        assets,
        assetsValues,
        assetsTreeRelations,
        rootAssetUuid,
        selectedAssetUuid,
        settingsData,
        timestamp,
      }: ReturnType<typeof parseConfigurationData>,
      scmHomeDetails,
    ) => {
      batch(() => {
        dispatch(setActiveConfigurationJobUuid(jobUuid || undefined));
        dispatch(updateConfigType(type || undefined));
        dispatch(setCommunityMembers(scmHomeDetails));
        dispatch(updateReadOnly(!!readOnly));
        dispatch(
          setConfiguration({
            user: user || '',
            timezone: timezone || '',
            projectUuid: project?.uuid || '',
            assets,
            assetsTreeRelations,
            assetsValues,
            rootAssetUuid,
            selectedAssetUuid,
            timestamp,
            // TODO: Type has to be fixed
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            settingsData: settingsData || (undefined as any),
          }),
        );
      });
    },
    [dispatch],
  );

  /**
   *
   *  __parseConfigurationData__
   *
   *  Parse configuration object
   *  @param configuration Configuration object from backend
   *  @returns Parsed configuration data with destructured tree and communityAssetUuid
   *
   */
  function parseConfigurationData(
    configuration: NN<NN<ReadConfigurationQueryResult['data']>['readConfiguration']>,
    assetUuidFromUrl?: string,
  ) {
    const { scenarioData, simulationResults } = configuration;
    const jobUuid = simulationResults;
    const configTree = destructureConfigTree(scenarioData?.latest?.serialized);
    const { assets, assetsTreeRelations, rootAssetUuid, assetsValues } = configTree;

    const communityAssetUuid = getCommunityAsset({
      assets,
      rootAssetUuid,
      assetsTreeRelations,
      assetsValues,
    })?.uuid;
    return {
      ...configuration,
      ...configTree,
      jobUuid,
      selectedAssetUuid: assetUuidFromUrl || communityAssetUuid,
    };
  }

  /**
   *
   *  __callForConfiguration__
   *
   *  Read configuration data from backend
   *  @param uuid Configuration uuid
   *  @returns Configuration object
   *
   */
  const callForConfiguration = useCallback(
    async (uuid: string) => {
      const configurationResult = await client.query<
        ReadConfigurationQuery,
        ReadConfigurationQueryVariables
      >({
        query: ReadConfigurationDocument,
        variables: { uuid },
        fetchPolicy: 'no-cache',
      });

      return configurationResult.data.readConfiguration;
    },
    [client],
  );

  /**
   *
   *  __getConfiguration__
   *
   *  Main function for configuration reading.
   *  @param configurationUuid Uuid of configuration
   *
   */
  const getConfiguration = useCallback(
    async (configurationUuid) => {
      dispatch(setIsAppLoading(true));
      const configuration = await callForConfiguration(configurationUuid);

      if (configuration) {
        const scmHomeDetails = configuration?.scenarioData?.homeInfo?.scmHomeDetails || [];

        const configurationData = parseConfigurationData(
          configuration,
          paramsMatch?.params.assetUuid,
        );

        const isSCM =
          configuration.type === 'COLLABORATION' &&
          configuration.settingsData?.spotMarketType === SpotMarketType.Coefficients;

        const isOperationalSCM =
          configuration.type === 'CANARY_NETWORK' &&
          configuration.settingsData?.spotMarketType === SpotMarketType.Coefficients;

        if (isSCM || isOperationalSCM) {
          //updateAppFlow(APP_FLOW.SCM);
          dispatch(setSCMFlow(true));
        }
        if (isOperationalSCM && !isUserACommunityMember) {
          listMeasurementVerificationRequests({
            variables: {
              configUuid: configurationUuid,
            },
          });
        }

        dispatch(setIsOperationalCommunity(isOperationalSCM));

        const { project, selectedAssetUuid, assetsValues } = configurationData;

        if (project?.uuid && selectedAssetUuid) {
          const locationVisible = true;

          updateConfigurationStoreData(locationVisible, configurationData, scmHomeDetails);
          const routeMatch = matchPath(location.pathname, {
            path: isEmbed
              ? routesConfig.embed(activeConfigurationUuid)
              : routesConfig.scmMapResults(activeConfigurationUuid, selectedAssetUuid),
          });

          if (!routeMatch?.isExact) {
            history.push(
              isEmbed
                ? routesConfig.embed(activeConfigurationUuid)
                : routesConfig.scmMapResults(activeConfigurationUuid, selectedAssetUuid),
            );
          }
          try {
            zoomIntoConfiguration({ assetsValues });
          } catch (err) {
            discardCurrentConfiguration();
            dispatch(
              openToast({
                message: 'Something went wrong',
                type: 'error',
              }),
            );
          }
        }
        dispatch(setIsAppLoading(false));
      }
    },
    [
      activeConfigurationUuid,
      callForConfiguration,
      updateConfigurationStoreData,
      discardCurrentConfiguration,
      dispatch,
      history,
      isEmbed,
      location.pathname,
      paramsMatch?.params.assetUuid,
      zoomIntoConfiguration,
      isUserACommunityMember,
      listMeasurementVerificationRequests,
    ],
  );

  /**
   *
   *  Fetch configuration and results after uuid change. Reset if configuration fetching fails
   *
   */
  useEffect(() => {
    async function makeRequest() {
      if (
        activeConfigurationRef.current == activeConfigurationUuid ||
        activeConfigurationUuid === 'NEW_COMMUNITY'
      )
        return;

      activeConfigurationRef.current = activeConfigurationUuid;

      if (activeConfigurationUuid) {
        try {
          await getConfiguration(activeConfigurationUuid);
        } catch (error) {
          if (!isEmbed) {
            discardCurrentConfiguration();
          } else {
            dispatch(setCommunityNotFound(true));
          }
        }
      }
    }

    makeRequest();
  }, [
    activeConfigurationUuid,
    discardCurrentConfiguration,
    dispatch,
    getConfiguration,
    isEmbed,
    activeConfigurationRef,
  ]);

  /**
   *
   *  Get and set configuration uuid from URL
   *
   *  Notes:
   *  - setActiveConfigurationUuid dispatch will trigger readConfigurationQuery
   *
   */
  useEffect(() => {
    if (paramsMatch?.params.configurationUuid) {
      dispatch(setActiveConfigurationUuid(paramsMatch?.params.configurationUuid));
    }
  }, [dispatch, history, paramsMatch]);

  /**
   *
   *  Zoom into community on select community asset
   *
   */
  // useEffect(() => {
  //   if (selectedAssetUuid && selectedAssetUuid === communityAsset?.uuid && assetsValues) {
  //     try {
  //       console.log({ selectedAssetUuid, u: communityAsset?.uuid, assetsValues });

  //       zoomIntoConfiguration({ assetsValues });
  //     } catch (err) {
  //       discardCurrentConfiguration();
  //       dispatch(
  //         openToast({
  //           message: 'Something went wrong',
  //           type: 'error',
  //         }),
  //       );
  //     }
  //   }
  // }, [
  //   selectedAssetUuid,
  //   assetsValues,
  //   communityAsset,
  //   zoomIntoConfiguration,
  //   discardCurrentConfiguration,
  //   dispatch,
  // ]);

  /**
   *
   *  On configuration quit
   *
   */
  useEffect(() => {
    if (!activeConfigurationUuid) {
      dispatch(configurationReset());
    }
  }, [activeConfigurationUuid, dispatch]);
}
