import React from 'react';

import classNames from 'classnames';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseTooltipTrigger } from 'src/components/BaseTooltip';
import { ETabs } from 'src/components/Tabs';
import { ConfigType } from 'src/graphql';

import s from './ListItemDefault.module.scss';
import { TListItemDefaultProps } from './ListItemDefault.types';

export const ListItemDefault: React.FC<TListItemDefaultProps> = ({
  item,
  style,
  place,
  belongsToLoggedUser,
  isLoggedIn,
  title,
  modalRefObject,
  className,
  description,
  configType,
  onCommunityClick,
  onResultsClick,
  onMenuClick,
  activeCommunitiesListTab,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`${s.blockOuter} ${className}`} onClick={onCommunityClick} style={style}>
      <div className={s.upperRow}>
        <div
          className={classnames(s.iconMainWrapper, {
            [s.iconMainWrapperCanary]: configType === ConfigType.CanaryNetwork,
          })}>
          <BaseIcon
            icon={configType === ConfigType.CanaryNetwork ? 'canary' : 'simulation'}
            className={s.iconMain}
            size={configType === ConfigType.CanaryNetwork ? 13 : 11}
          />
        </div>
        <div className={s.infoWrapper}>
          <BaseTooltipTrigger
            disabled={title.length < 30}
            position="top"
            tooltipChildren={title}
            offset={20}>
            <p className={s.title}>{title}</p>
          </BaseTooltipTrigger>
          <p className={s.description}>{description}</p>
        </div>
        <div className={s.iconsWrapper} onClick={(e) => e.stopPropagation()}>
          {belongsToLoggedUser && (
            <button type="button">
              <BaseIcon icon="user" className={s.iconUser} />
            </button>
          )}
          {isLoggedIn ? (
            <button
              type="button"
              ref={modalRefObject}
              onClick={() => onMenuClick(modalRefObject.current!)}>
              {<BaseIcon icon="more-horizontal" className={s.iconMore} />}
            </button>
          ) : null}
        </div>
      </div>
      <div className={s.lowerRow}>
        <BaseIcon icon="globe" className={s.iconLocation} />
        <p className={s.location}>{place}</p>
      </div>
      {activeCommunitiesListTab === ETabs.SIMULATIONS && (
        <div className={classNames(s.actionButtons, s.oneButton)}>
          {belongsToLoggedUser && (
            <button
              type="button"
              className={classnames(s[`action-play`])}
              onClick={() => (item.resultsStatus === 'finished' ? onResultsClick() : null)}>
              <BaseIcon
                icon={item.resultsStatus === 'finished' ? 'play' : 'spinner'}
                className={s.iconMain}
              />
            </button>
          )}
        </div>
      )}
      {activeCommunitiesListTab === ETabs.OPERATIONAL ? (
        item.scenarioData?.forecastStreamAreaMapping ? (
          <div className={classnames(s.connectAssetApi, s.connected)}>
            <BaseIcon icon="wifi" size={12} />
            {t('messages.ASSET_APIS_CONNECTED')}
          </div>
        ) : (
          <div className={classnames(s.connectAssetApi, s.disconnected)}>
            <BaseIcon icon="wifi" size={12} />
            {t('messages.CONNECT_ASSET_APIS')}
          </div>
        )
      ) : null}
    </div>
  );
};
