import React, { useCallback } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { BaseIcon } from 'src/components/BaseIcon';
import { TCustomResultHeaderProps } from 'src/features/Results/DashboardHeader/CustomResultHeader/CustomResultHeader.types';
import { HeaderSimulationFlag } from 'src/features/Results/DashboardHeader/CustomResultHeader/HeaderSimulationFlag';
import { HomeCommunityTab } from 'src/features/Results/DashboardHeader/CustomResultHeader/HomeCommunityTab';
import { SelectDay } from 'src/features/Results/DashboardHeader/CustomResultHeader/SelectDay';
import { SelectMonth } from 'src/features/Results/DashboardHeader/CustomResultHeader/SelectMonth';
import { DataAggregationResolution } from 'src/graphql';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { useResults } from 'src/hooks/useResults';
import { selectSelectedDataResolution } from 'src/redux/application/application.selectors';
import {
  setSelectedDataResolution,
  setSelectedEndDatetime,
  setSelectedStartDatetime,
} from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { UTCMoment } from 'src/utils/UTCMoment';

import s from './CustomResultHeader.module.scss';

export const CustomResultHeader: React.FC<TCustomResultHeaderProps> = ({
  onClickGoBack,
  flag = 'Operation',
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { isUserACommunityMember } = useIsUserACommunityMember();
  const { maxDate } = useResults();
  const { selectedMember } = useConfiguration();

  const selectedDataResolution = useSelector(selectSelectedDataResolution);

  const handleResolutionButton = useCallback<(value: DataAggregationResolution) => void>(
    (value) => {
      batch(() => {
        const date = UTCMoment.utc(maxDate);
        if (value === DataAggregationResolution.Daily) {
          const startOfDay = date.startOf('day').toDate().toISOString();
          const endOfDay = date.endOf('day').toDate().toISOString();
          dispatch(setSelectedStartDatetime(startOfDay));
          dispatch(setSelectedEndDatetime(endOfDay));
        } else if (value === DataAggregationResolution.Monthly) {
          const startOfMonth = date.startOf('month').toDate().toISOString();
          const endOfMonth = date.endOf('month').toDate().toISOString();
          dispatch(setSelectedStartDatetime(startOfMonth));
          dispatch(setSelectedEndDatetime(endOfMonth));
        }

        dispatch(setSelectedDataResolution(value));
      });
    },
    [dispatch, maxDate],
  );

  return (
    <div className={s.customResultHeaderWrapper}>
      {!!selectedMember && (
        <div className={s.homeName} onClick={onClickGoBack}>
          <div className={s.iconContainer}>
            <div className={s.borderIcon} />
            <BaseIcon icon="arrow-left-full" size={15} />
          </div>
          <span>{selectedMember.name}</span>
        </div>
      )}
      {!isUserACommunityMember && !selectedMember && (
        <div className={s.communityName}>
          <span>{t('COMMUNITY_RESULTS')}</span>
        </div>
      )}
      {isUserACommunityMember && <HomeCommunityTab />}
      <div className={s.topRightArea}>
        {selectedDataResolution === DataAggregationResolution.Monthly && <SelectMonth />}
        {selectedDataResolution === DataAggregationResolution.Daily && <SelectDay />}
        <div className={s.dailyMonthlyContainer}>
          <button
            type="button"
            className={classNames(s.text, {
              [s.selected]: selectedDataResolution === DataAggregationResolution.Monthly,
            })}
            onClick={() => handleResolutionButton(DataAggregationResolution.Monthly)}>
            {t('MONTHLY')}
          </button>
          <button
            type="button"
            className={classNames(s.text, {
              [s.selected]: selectedDataResolution === DataAggregationResolution.Daily,
            })}
            onClick={() => handleResolutionButton(DataAggregationResolution.Daily)}>
            {t('DAILY')}
          </button>
        </div>
        {flag === 'Operation' && !isUserACommunityMember && (
          <div className={s.liveResultsContainer}>
            <span className={s.text}>{t('OPERATION_RESULTS')}</span>
          </div>
        )}
        {flag === 'Simulation' && (
          <HeaderSimulationFlag
            className={s.simulationResults}
            isIconActive={true}
            title={t('SIMULATION_RESULTS')}
          />
        )}
      </div>
    </div>
  );
};
