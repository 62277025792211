import { useMemo } from 'react';

import { useSubscription } from '@apollo/client';
import { useSelector } from 'react-redux';
import { EActiveTabState } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity';
import { ConfigType } from 'src/graphql';
import { SimulationAreaResultsPartial } from 'src/graphql/subscriptions/simAreaResultsPartial';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { setActiveSidebarMainTab } from 'src/redux/application/application.slice';
import {
  selectActiveConfigurationJobUuid,
  selectRawCommunitySimulationResults,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import {
  setRawCommunitySimulationResults,
  setSimulationProgress,
  setSimulationStatus,
} from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';

export type TUseSimulationSubscription = {
  showSimulationResults: boolean;
};

export function useSimulationSubscription(): TUseSimulationSubscription {
  const dispatch = useAppDispatch();

  const activeJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const simulationResults = useSelector(selectRawCommunitySimulationResults);
  const simulationStatus = useSelector(selectSimulationStatus);

  const { communityArea, resultsStatus, configType, refetchReadConfiguration } = useConfiguration();

  const mustSkip: boolean = useMemo(
    () =>
      !communityArea?.uuid ||
      !activeJobUuid ||
      (!!simulationStatus && ['finished', 'failed'].includes(simulationStatus)),
    [communityArea, activeJobUuid, simulationStatus],
  );
  const shouldResubscribe: boolean = useMemo(
    () =>
      (resultsStatus !== null && ['running', 'quueued'].includes(resultsStatus)) ||
      (!!simulationStatus && ['running', 'quueued'].includes(simulationStatus)),
    [resultsStatus, simulationStatus],
  );
  const showSimulationResults: boolean = useMemo(
    () =>
      resultsStatus === 'finished' ||
      (configType === ConfigType.CanaryNetwork &&
        (resultsStatus === 'running' || resultsStatus == 'stopped')),
    [resultsStatus, configType],
  );

  // Use subscription to receive live updates
  useSubscription(SimulationAreaResultsPartial, {
    shouldResubscribe: shouldResubscribe,
    skip: mustSkip,
    variables: {
      jobId: activeJobUuid,
      uuid: communityArea?.uuid,
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const results = subscriptionData.data.simulationAreaResultsPartial;

      if (!!results && results.length > 0) {
        const result = results[0];
        const newMarketSummary = [
          ...(simulationResults?.marketSummary || []),
          ...result.marketSummary,
        ].filter(
          (value, index, self) => index === self.findIndex((t) => t.timestamp === value.timestamp),
        );

        // Update simulation market summary
        dispatch(
          setRawCommunitySimulationResults({
            ...result,
            marketSummary: newMarketSummary,
          }),
        );

        // Update simulation status
        dispatch(setSimulationStatus(result.status));
        dispatch(setSimulationProgress(result.progressInfo));

        if (result.progressInfo.percentageCompleted >= 100) {
          refetchReadConfiguration();
          dispatch(setActiveSidebarMainTab(EActiveTabState.Community));
        }
      }
    },
  });

  return {
    showSimulationResults,
  };
}
