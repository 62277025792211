import { useCallback } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';
import { TAssetsSaveProps } from 'src/components/FormAssetsParams';
import { AreaInput } from 'src/graphql';
import {
  selectAssetsValues,
  selectConfiguration,
  selectSettingsData,
  selectUsedAssetsNames,
} from 'src/redux/configuration/configuration.selectors';
import { TAsset, TTreeBranch } from 'src/typings/configuration.types';
import { destructureConfigTree } from 'src/utils/configuration/destructureConfigTree';
import { TDeviceTypesInput, prepareAreaInput } from 'src/utils/mutationsGates';
import {
  optionalOrNull,
  prepareDeviceInputFunctions,
} from 'src/utils/mutationsGates/prepareAreaInput/prepareDeviceInputFunctions';
import { v4 } from 'uuid';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type HandleNewAssetValues = any;

type TUseBuildSavingTreeBranch = {
  buildCustomHouseTree: (
    values: HandleNewAssetValues,
    connectorAssetUuid: string,
    chosenAsset: any,
  ) => TTreeBranch;
  buildRegularTree: (
    values: HandleNewAssetValues,
    connectorAssetUuid: string,
    chosenAsset: any,
  ) => TTreeBranch;
  buildCustomPVTree: (
    values: HandleNewAssetValues,
    connectorAssetUuid: string,
    chosenAsset: any,
  ) => TTreeBranch;
  createAreaInputFromSavingTreeBranch: (
    savingTreeBranch: TTreeBranch,
    gridMarketInTreeBranch: TAsset | undefined,
  ) => AreaInput | null;
  createSavingTreeBranch: (
    values: HandleNewAssetValues,
    connectorAssetUuid: string,
    chosenAsset: any,
  ) => TTreeBranch;
  createAreaInputFromSaveProps: (payload: TAssetsSaveProps) => TDeviceTypesInput | undefined;
};

export default function useBuildSavingTreeBranch(): TUseBuildSavingTreeBranch {
  const usedAssetsNames = useSelector(selectUsedAssetsNames);
  const settingsData = useSelector(selectSettingsData);
  const configurationCharacteristic = useSelector(selectConfiguration);
  const assetsValuesState = useSelector(selectAssetsValues);

  const buildCustomPVTree = useCallback<
    (values: HandleNewAssetValues, connectorAssetUuid: string, chosenAsset: any) => TTreeBranch
  >((values, connectorAssetUuid, chosenAsset) => {
    const customPVUuid = v4();

    values.isCustomPV = true;

    const savingTreeBranch = {
      assets: {
        [customPVUuid]: {
          parentUuid: connectorAssetUuid,
          type: chosenAsset.type,
          uuid: customPVUuid,
          isCustomPV: true,
        },
      },
      assetsTreeRelations: { [customPVUuid]: [] },
      rootAssetUuid: customPVUuid,
      assetsValues: { [customPVUuid]: values },
    };
    return savingTreeBranch;
  }, []);

  const buildCustomHeatPumpTree = useCallback<
    (values: HandleNewAssetValues, connectorAssetUuid: string, chosenAsset: any) => TTreeBranch
  >((values, connectorAssetUuid, chosenAsset) => {
    const customHeatPumpUuid = v4();

    values.isCustomHeatPump = true;

    const savingTreeBranch = {
      assets: {
        [customHeatPumpUuid]: {
          parentUuid: connectorAssetUuid,
          type: chosenAsset.type,
          uuid: customHeatPumpUuid,
          isCustomHeatPump: true,
        },
      },
      assetsTreeRelations: { [customHeatPumpUuid]: [] },
      rootAssetUuid: customHeatPumpUuid,
      assetsValues: { [customHeatPumpUuid]: values },
    };
    return savingTreeBranch;
  }, []);

  const buildCustomLoadTree = useCallback<
    (values: HandleNewAssetValues, connectorAssetUuid: string, chosenAsset: any) => TTreeBranch
  >((values, connectorAssetUuid, chosenAsset) => {
    const customLoadUuid = v4();

    values.isCustomLoad = true;

    const savingTreeBranch = {
      assets: {
        [customLoadUuid]: {
          parentUuid: connectorAssetUuid,
          type: chosenAsset.type,
          uuid: customLoadUuid,
          isCustomPV: true,
        },
      },
      assetsTreeRelations: { [customLoadUuid]: [] },
      rootAssetUuid: customLoadUuid,
      assetsValues: { [customLoadUuid]: values },
    };
    return savingTreeBranch;
  }, []);

  const buildCustomStorageTree = useCallback<
    (values: HandleNewAssetValues, connectorAssetUuid: string, chosenAsset: any) => TTreeBranch
  >((values, connectorAssetUuid, chosenAsset) => {
    const customUuid = v4();

    values.isCustomStorage = true;

    const savingTreeBranch = {
      assets: {
        [customUuid]: {
          parentUuid: connectorAssetUuid,
          type: chosenAsset.type,
          uuid: customUuid,
          isCustomStorage: true,
        },
      },
      assetsTreeRelations: { [customUuid]: [] },
      rootAssetUuid: customUuid,
      assetsValues: { [customUuid]: values },
    };
    return savingTreeBranch;
  }, []);

  const buildRegularTree = useCallback<
    (values: HandleNewAssetValues, connectorAssetUuid: string, chosenAsset: any) => TTreeBranch
  >(
    (values, connectorAssetUuid, chosenAsset) => {
      const destructuredTree = destructureConfigTree(
        chosenAsset.scenarioData?.representation?.serialized,
        {
          assignNewUuids: true,
          parentUuid: connectorAssetUuid,
          assignGeoTagLocation: values.geoTagLocation,
          considerUsedAssetsNames: usedAssetsNames,
        },
      );
      destructuredTree.assetsValues[destructuredTree.rootAssetUuid as string] = values;

      return {
        ...destructuredTree,
        rootAssetUuid: destructuredTree.rootAssetUuid as string,
      };
    },
    [usedAssetsNames],
  );

  const buildCustomHouseTree = useCallback<
    (values: HandleNewAssetValues, connectorAssetUuid: string, chosenAsset: any) => TTreeBranch
  >((values, connectorAssetUuid, chosenAsset) => {
    const customHouseUuid = v4();
    return {
      assets: {
        [customHouseUuid]: {
          parentUuid: connectorAssetUuid,
          type: chosenAsset.type,
          uuid: customHouseUuid,
        },
      },
      assetsTreeRelations: { [customHouseUuid]: [] },
      rootAssetUuid: customHouseUuid,
      assetsValues: { [customHouseUuid]: values },
    };
  }, []);

  const createSavingTreeBranch = useCallback<
    (values: HandleNewAssetValues, connectorAssetUuid: string, chosenAsset: any) => TTreeBranch
  >(
    (values, connectorAssetUuid, chosenAsset) => {
      if (chosenAsset.isCustomPV) {
        return buildCustomPVTree(values, connectorAssetUuid, chosenAsset);
      } else if (chosenAsset.isCustomHeatPump) {
        return buildCustomHeatPumpTree(values, connectorAssetUuid, chosenAsset);
      } else if (chosenAsset.isCustomLoad) {
        return buildCustomLoadTree(values, connectorAssetUuid, chosenAsset);
      } else if (chosenAsset.isCustomStorage) {
        return buildCustomStorageTree(values, connectorAssetUuid, chosenAsset);
      } else if (!chosenAsset.isCustomHouse) {
        return buildRegularTree(values, connectorAssetUuid, chosenAsset);
      }

      return buildCustomHouseTree(values, connectorAssetUuid, chosenAsset);
    },
    [
      buildCustomPVTree,
      buildRegularTree,
      buildCustomHouseTree,
      buildCustomLoadTree,
      buildCustomHeatPumpTree,
      buildCustomStorageTree,
    ],
  );

  const createAreaInputFromSavingTreeBranch = useCallback<
    (savingTreeBranch: TTreeBranch, gridMarketInTreeBranch: TAsset | undefined) => AreaInput | null
  >(
    (savingTreeBranch, gridMarketInTreeBranch) => {
      return prepareAreaInput(
        gridMarketInTreeBranch
          ? {
              rootAssetUuid: savingTreeBranch.rootAssetUuid,
              assets: _.omit(savingTreeBranch.assets, [gridMarketInTreeBranch.uuid]),
              assetsTreeRelations: {
                ..._.omit(savingTreeBranch.assetsTreeRelations, [gridMarketInTreeBranch.uuid]),
                [savingTreeBranch.rootAssetUuid]: savingTreeBranch.assetsTreeRelations[
                  savingTreeBranch.rootAssetUuid
                ].filter((item) => item !== gridMarketInTreeBranch.uuid),
              },
              assetsValues: _.omit(savingTreeBranch.assetsValues, [gridMarketInTreeBranch.uuid]),
              settingsData,
              configurationCharacteristic,
            }
          : { ...savingTreeBranch, settingsData, configurationCharacteristic },
      );
    },
    [configurationCharacteristic, settingsData],
  );

  const createAreaInputFromSaveProps = useCallback<
    (payload: TAssetsSaveProps) => TDeviceTypesInput | undefined
  >(
    (payload: TAssetsSaveProps) => {
      const assetType: string = payload.assetType || 'Area';
      const updateIntervalValue = assetsValuesState[payload.assetUuid]?.updateInterval;
      return prepareDeviceInputFunctions[assetType]({
        type: assetType,
        uuid: payload.assetUuid,
        assetsValues: {
          [payload.assetUuid]: {
            updateInterval: updateIntervalValue,
            ...payload.values,
          },
        },
        settingsData,
        optionalMethod: optionalOrNull,
      });
    },
    [settingsData, assetsValuesState],
  );

  return {
    buildCustomHouseTree,
    buildRegularTree,
    buildCustomPVTree,
    createAreaInputFromSavingTreeBranch,
    createSavingTreeBranch,
    createAreaInputFromSaveProps,
  };
}
