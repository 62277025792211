import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { BaseIcon } from 'src/components/BaseIcon';
import { InViewPositioner } from 'src/components/InViewPositioner';
import { Loader } from 'src/components/LoadingWrapper';
import { EPredefinedModalIds } from 'src/constants/modals';
import { useAssetsInside } from 'src/hooks/useAssetsInside';
import { useCurrentRefs } from 'src/hooks/useCurrentRefs';
import { useMergedState } from 'src/hooks/useMergedState';
import { useModal } from 'src/hooks/useModal';
import {
  selectGuideStep,
  selectIsCustomHouseAddingContinuation,
  selectIsEmbed,
} from 'src/redux/application/application.selectors';
import {
  setIsCustomHouseAddingContinuation,
  setModalAssetManagerActiveView,
  setNewCommunity,
} from 'src/redux/application/application.slice';
import {
  selectActiveConfigurationUuid,
  selectAllAssetLoadingInfo,
  selectAssets,
  selectAssetsValues,
  selectCommunityAsset,
  selectConfiguration,
  selectIsModalSummaryWithList,
  selectLastAddedAssetUuid,
} from 'src/redux/configuration/configuration.selectors';
import {
  setLastAddedAssetUuid,
  setSelectedAssetUuid,
} from 'src/redux/configuration/configuration.slice';
import { updateSelectedLocation } from 'src/redux/map/map.slice';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { EFormVariant } from 'src/typings/base-types';
import { TAsset } from 'src/typings/configuration.types';
import { formatter } from 'src/utils/formatter';
import { v4 } from 'uuid';

import s from './ModalAssetsManager.module.scss';
import { EModalAssetsManagerView, TModalAssetsManagerProps } from './ModalAssetsManager.types';

enum EFormModes {
  NewChosenLibraryConfiguration = 'NewChosenLibraryConfiguration',
  NewCustomHouseConfiguration = 'NewCustomHouseConfiguration',
  NewCustomPVConfiguration = 'NewCustomPVConfiguration',
  NewCustomHeatPumpConfiguration = 'NewCustomHeatPumpConfiguration',
  NewCommunityConfiguration = 'CreateNewCommunity',
  EditCommunity = 'EditCommunity',
  EditAsset = 'EditAsset',
}

export const ModalAssetsManager: React.FC<TModalAssetsManagerProps> = React.memo(
  function ModalAssetsManager({
    placeName,
    summaryIcon = 'house',
    hostAssetUuid,
    view: viewFromProps,
    formVariant: formVariantFromProps = EFormVariant.Express,
    /*
     * viewOnDestroy - We need to restore view to initial state in order to tell the application that a user is not in create or edit house flow (see: selectIsCreateOrEditHouseFlow)
     */
    viewOnDestroy,
    onViewChange,
    onFormVariantChange,
    rootElRefProp,
  }: TModalAssetsManagerProps) {
    const step = useSelector(selectGuideStep);
    const communityAsset = useSelector(selectCommunityAsset);
    const dispatch = useAppDispatch();
    const rootElRef = useRef<HTMLDivElement>(rootElRefProp?.current || null);

    const isEmbed = useSelector(selectIsEmbed);
    const assets = useSelector(selectAssets);
    const assetsValues = useSelector(selectAssetsValues);
    const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
    const configurationCharacteristic = useSelector(selectConfiguration);

    const isCustomHouseAddingContinuation = useSelector(selectIsCustomHouseAddingContinuation);
    const [view, setView] = useState<EModalAssetsManagerView>(viewFromProps);
    const [stickyElementsHeight, setStickyElementsHeight] = useState(0);
    const viewHistory = useRef<EModalAssetsManagerView[]>([view]);
    const [formVariant, __setFormVariant] = useState<EFormVariant>(formVariantFromProps);
    const [chosenLibrary, setChosenLibrary] = useState<undefined>();
    const isModalSummaryWithList = useSelector(selectIsModalSummaryWithList);
    const [editingAssetUuid, setEditingAssetUuid] = useState<TAsset['uuid'] | undefined>(
      hostAssetUuid,
    );
    const lastAddedAssetUuid = useSelector(selectLastAddedAssetUuid);

    const mostBeginingView = hostAssetUuid
      ? EModalAssetsManagerView.Summary
      : EModalAssetsManagerView.AddHouse;
    const isHostedByCommunity = communityAsset?.uuid === hostAssetUuid;

    const loadingInfoCheckKeyRef = useRef<string | undefined>(v4());

    const allloadingInfo = useSelector(selectAllAssetLoadingInfo);

    const assetLoadingInfo =
      allloadingInfo &&
      loadingInfoCheckKeyRef.current &&
      allloadingInfo[loadingInfoCheckKeyRef.current]
        ? allloadingInfo[loadingInfoCheckKeyRef.current]
        : false;

    const assetLoadingInfoRef = useRef<boolean>(assetLoadingInfo);

    const [contextMenuState, setContextMenuState] = useMergedState<{
      modalRef: React.RefObject<HTMLElement | null>;
      assetUuid: TAsset['uuid'] | undefined;
    }>({ modalRef: { current: null }, assetUuid: hostAssetUuid });

    const { id: contextMenuModalId } = useModal();

    const currentFormMode = useMemo(() => {
      if (chosenLibrary) {
        return EFormModes.NewCustomHouseConfiguration;
      }

      if (editingAssetUuid === communityAsset?.uuid && !activeConfigurationUuid) {
        dispatch(setNewCommunity(false));

        return EFormModes.NewCommunityConfiguration;
      }

      if (editingAssetUuid === communityAsset?.uuid) {
        return EFormModes.EditCommunity;
      }

      return EFormModes.EditAsset;
    }, [dispatch, activeConfigurationUuid, chosenLibrary, communityAsset?.uuid, editingAssetUuid]);

    const closeContextMenu = useCallback(() => {
      dispatch(closeModal(contextMenuModalId));
    }, [contextMenuModalId, dispatch]);

    const startAssetEdition = useCallback(
      (assetUuid: TAsset['uuid']) => {
        setEditingAssetUuid(assetUuid);
        setView(EModalAssetsManagerView.Form);
        closeContextMenu();
        dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.Form));
        dispatch(setSelectedAssetUuid(assetUuid));
      },
      [setView, setEditingAssetUuid, dispatch, closeContextMenu],
    );

    // Sync currentFormMode >>>
    useEffect(() => {
      if (currentFormMode == EFormModes.NewCustomHouseConfiguration) {
        dispatch(setNewCommunity(true));
      } else {
        dispatch(setNewCommunity(false));
      }
    }, [dispatch, view, currentFormMode]);

    // Sync view >>>
    useEffect(() => {
      setView(viewFromProps);
    }, [viewFromProps]);
    useEffect(() => {
      if (step == 90) {
        setView(EModalAssetsManagerView.AddHouse);
      }
    }, [step]);

    const currRefs = useCurrentRefs({
      onViewChange,
      onFormVariantChange,
      viewOnDestroy,
      dispatch,
      mostBeginingView,
      setChosenLibrary,
      hostAssetUuid,
    });

    useEffect(() => {
      currRefs.current.onViewChange?.(view);
    }, [currRefs, view]);
    // <<< Sync view

    useEffect(() => {
      if (!editingAssetUuid && lastAddedAssetUuid) {
        setEditingAssetUuid(lastAddedAssetUuid);
        dispatch(setLastAddedAssetUuid(undefined));
      }
    }, [editingAssetUuid, lastAddedAssetUuid, dispatch]);

    // Sync formVariant >>>
    useEffect(() => {
      __setFormVariant(formVariantFromProps);
    }, [formVariantFromProps]);

    useEffect(() => {
      currRefs.current.onFormVariantChange?.(formVariant);
    }, [currRefs, formVariant]);
    // <<< Sync formVariant

    useEffect(
      () =>
        function onDestroy() {
          currRefs.current.onViewChange?.(currRefs.current.viewOnDestroy);
          currRefs.current.onFormVariantChange?.(EFormVariant.Express);
        },
      [currRefs],
    );

    const inViewPositionerRecalculateKey = String(view + formVariant + currentFormMode);
    useEffect(() => {
      if (!rootElRef.current) return;

      const formEl = rootElRef.current.querySelector('.js-custom-scrollbar');

      if (!formEl) return;

      const height =
        rootElRef.current.getBoundingClientRect().height - formEl.getBoundingClientRect().height;

      setStickyElementsHeight(height);
    }, [inViewPositionerRecalculateKey]);

    const { assetsInside } = useAssetsInside({ hostAssetUuid });

    const goBack = useCallback(
      ({ until }: { until?: EModalAssetsManagerView } = {}) => {
        if (!until) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const currView = viewHistory.current.pop();
          const prevView = viewHistory.current.pop();

          setView(prevView || mostBeginingView);
        } else {
          let prevView = mostBeginingView;

          while (prevView !== until || !viewHistory.current.length) {
            const poppedView = viewHistory.current.pop();
            if (poppedView) {
              prevView = poppedView;
            }
          }

          setView(prevView);
        }
      },
      [mostBeginingView],
    );

    const changeViewAfterSuccessfullSave = useCallback(() => {
      const addedAssetInside = viewHistory.current.includes(EModalAssetsManagerView.AssetsInside);
      const editedAssetInside = hostAssetUuid !== contextMenuState.assetUuid;
      //const editedSingleDevice =
      //  hostAssetUuid && ['Load', 'PV', 'Storage'].includes(assets[hostAssetUuid].type);
      const editedGridMarket =
        hostAssetUuid && !['Load', 'PV', 'Storage'].includes(assets[hostAssetUuid].type);

      if (isCustomHouseAddingContinuation) {
        setView(EModalAssetsManagerView.ChooseAsset);
        dispatch(setIsCustomHouseAddingContinuation(false));
      } else if (addedAssetInside) {
        goBack({ until: EModalAssetsManagerView.AssetsInside });
      } else if (editedAssetInside) {
        goBack();
      } else if (editedGridMarket) {
        dispatch(setSelectedAssetUuid(undefined));
      } else {
        // dispatch(setIsCustomHouseAddingContinuation(false));
        //  dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.Summary));
        setView(EModalAssetsManagerView.Summary);
      }
    }, [
      assets,
      contextMenuState.assetUuid,
      dispatch,
      goBack,
      hostAssetUuid,
      isCustomHouseAddingContinuation,
    ]);

    useEffect(() => {
      if (assetLoadingInfoRef.current === assetLoadingInfo) return;
      assetLoadingInfoRef.current = assetLoadingInfo;
      if (assetLoadingInfo) {
        setView(EModalAssetsManagerView.AssetLoading);
        return;
      }
      if (lastAddedAssetUuid) {
        setEditingAssetUuid(lastAddedAssetUuid);
        setContextMenuState((prev) => ({
          ...prev,
          assetUuid: lastAddedAssetUuid,
        }));
        dispatch(setLastAddedAssetUuid(undefined));
      }
      changeViewAfterSuccessfullSave();
    }, [
      assetLoadingInfo,
      lastAddedAssetUuid,
      dispatch,
      setContextMenuState,
      changeViewAfterSuccessfullSave,
    ]);

    const LoadingView = () => (
      <div className={s.loadingAroundWrapper}>
        <Loader />
      </div>
    );

    const CommunityPin = () => (
      <div className={s.scmPinContainer}>
        <BaseIcon icon="pin-empty" size={16} />
        <div className={s.address}>{placeName || 'Unknown location'}</div>
        <div
          className={s.changeLink}
          onClick={() => {
            dispatch(updateSelectedLocation(null));
            dispatch(openModal(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY));
          }}>
          Change
        </div>
      </div>
    );

    const assetName =
      editingAssetUuid && assetsValues[editingAssetUuid]
        ? assetsValues[editingAssetUuid].name
        : undefined;

    return (
      <>
        <InViewPositioner
          className={classnames(s.modal, {
            [s.modalTooltip]:
              view === EModalAssetsManagerView.AddCommunity ||
              view === EModalAssetsManagerView.Summary,
          })}
          innerClassName={classnames(s.modalInner, 'gradient-dark', s.pointerEvents)}
          recalculateKey={inViewPositionerRecalculateKey}
          reservedHeight={stickyElementsHeight}
          rootElRef={rootElRef}>
          {(() => {
            switch (view) {
              case EModalAssetsManagerView.AssetLoading:
                return <LoadingView />;
              case EModalAssetsManagerView.AddCommunity: {
                return <CommunityPin />;
              }
              case EModalAssetsManagerView.Summary:
                if (isModalSummaryWithList) {
                  return (
                    <div className={s.paddingAround}>
                      <header className={s.header}>
                        <div className={s.typeIcon}>
                          <BaseIcon icon={summaryIcon} size={12} />
                        </div>

                        <h3 className={s.title}>
                          {isHostedByCommunity ? configurationCharacteristic.name : assetName}
                        </h3>
                      </header>

                      <ul className={s.assetsList}>
                        {assetsInside.map((asset, index) => (
                          <li key={index}>
                            <div className={s.assetSquareBtn}>
                              <BaseIcon icon={asset.icon} size={16.25} />
                              <label className={s.assetsAmount}>
                                {formatter.prefixWithZeros(asset.amount)}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                }

                if (!isHostedByCommunity) {
                  return (
                    <div className={classnames(s.paddingAround, s.scmUserTitle)}>
                      <div className={s.summaryUserCircle} />
                      <h3 className={s.title}>{`${assetName}'s Home`}</h3>
                    </div>
                  );
                }

                return (
                  <div className={s.paddingAround}>
                    {/* view {JSON.stringify(view)} */}
                    <header className={s.header}>
                      <div className={s.typeIcon}>
                        <BaseIcon icon={summaryIcon} size={12} />
                      </div>

                      <h3 className={s.title}>
                        {isHostedByCommunity ? configurationCharacteristic.name : assetName}
                      </h3>

                      <button
                        type="button"
                        className={s.mla}
                        onClick={() => {
                          if (contextMenuState.assetUuid) {
                            startAssetEdition(contextMenuState.assetUuid);
                            return;
                          }
                          if (editingAssetUuid) {
                            startAssetEdition(editingAssetUuid);
                          }
                        }}></button>
                    </header>

                    <ul className={s.assetsList}>
                      {assetsInside.map((asset, index) => (
                        <li key={index}>
                          <button type="button" className={s.assetSquareBtn} disabled={isEmbed}>
                            <BaseIcon icon={asset.icon} size={16.25} />
                            <label className={s.assetsAmount}>
                              {formatter.prefixWithZeros(asset.amount)}
                            </label>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                );
            }
          })()}
        </InViewPositioner>
      </>
    );
  },
);
