import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { BaseSearch } from 'src/components/BaseSearch';
import { ContextMenu } from 'src/components/ContextMenu';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { useRemoveConfigurationAreaMutation } from 'src/graphql';
import { ReadConfigurationDocument } from 'src/graphql';
import { useReadConfigurationQuery, ConfigurationOutput } from 'src/graphql';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useModal } from 'src/hooks/useModal';
import {
  selectActiveConfigurationUuid,
  selectAssetValuesForUuid,
  selectReadOnly,
  selectSelectedAreaUuid,
} from 'src/redux/configuration/configuration.selectors';
import {
  removeAssetByUuidWithDelete,
  setIsModalSummaryWithList,
  setSelectedAssetUuid,
} from 'src/redux/configuration/configuration.slice';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { selectCommunityMembers } from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';
import { get2dAssetIcon, get3dAssetIcon } from 'src/utils/assetIcons';
import { intervalListener } from 'src/utils/intervalListener';
import { objectCamelCase } from 'src/utils/objectCamelCase';

import s from './UserAssetPreview.module.scss';
import {
  EUserAssetPreviewTabs,
  TAssetListItemProps,
  TAssetsListData,
  TUserAssetPreviewProps,
} from './UserAssetPreview.types';

const NAVIGATION: Array<EUserAssetPreviewTabs> = [
  EUserAssetPreviewTabs.Assets,
  EUserAssetPreviewTabs.Advanced,
];

const AssetListItem: React.FC<TAssetListItemProps> = (props) => {
  const modalRefObject = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classNames(s.assetListItem, {
        [s.isSelected]: props.isSelected,
        [s.isSelectable]: props.isSelectable,
      })}
      style={props.style}>
      <BaseIcon icon={props.icon} size={16} />
      <p onClick={props.onClick}>{props.title}</p>
      {props.menuActive && (
        <div
          ref={modalRefObject}
          onClick={() => {
            if (props.uuid) {
              props.onMenuClick(modalRefObject.current, { itemUuid: props.uuid });
            }
          }}>
          <BaseIcon icon="more-horizontal" size={16} />
        </div>
      )}
    </div>
  );
};

export const UserAssetPreview: React.FC<TUserAssetPreviewProps> = ({
  selectedItem,
  selectedAsset,
  handleSetMode,
  setSelectedAsset,
  backToDashboard,
  onRemove,
  menuActive = true,
  isCreateAssetView,
  setAssetsList,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { children } = selectedItem;
  const { id: modalId } = useModal();
  const [data] = useState(selectedItem.data);

  const scmMembers = useSelector(selectCommunityMembers);
  const assetValues = useSelector(selectAssetValuesForUuid(data?.uuid || ''));
  const modalIsOpen = useSelector(selectIsModalOpened(modalId));
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const selectedAreaUuid = useSelector(selectSelectedAreaUuid);

  const [openedAssetConfigUuid, setOpenedAssetConfigUuid] = useState<string | null>(null);
  const [menuModalTriggerRef, setMenuModalTriggerRef] = useState<
    React.RefObject<HTMLButtonElement | null>
  >({
    current: null,
  });
  const { isOwnerAdmin, isOwnerCM } = useIsAdminInspecting();

  const { data: configurationResponse } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !activeConfigurationUuid,
    variables: { uuid: activeConfigurationUuid! },
  });
  const configuration: ConfigurationOutput | null = useMemo(
    () => configurationResponse?.readConfiguration as ConfigurationOutput | null,
    [configurationResponse],
  );
  const area = useMemo(() => {
    const scenario = configuration?.scenarioData?.latest?.serialized
      ? JSON.parse(configuration.scenarioData.latest.serialized)
      : null;
    const communityArea = scenario?.children.find((child) => child.type === 'Area');
    return communityArea?.children.find((area) => area.uuid === selectedAreaUuid);
  }, [configuration, selectedAreaUuid]);
  const assets = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return area?.children?.map((asset) => objectCamelCase<any>(asset));
  }, [area]);

  const [removeConfigurationArea] = useRemoveConfigurationAreaMutation({
    refetchQueries: [
      { query: ReadConfigurationDocument, variables: { uuid: activeConfigurationUuid } },
    ],
    onCompleted: (data) => {
      if (data.removeConfigurationArea && selectedItem.data.uuid) {
        dispatch(openToast({ message: 'Asset deleted successfully', type: 'success' }));
        if (onRemove) onRemove();
        dispatch(removeAssetByUuidWithDelete(selectedItem.data.uuid));
      }
    },
    onError: (error) => {
      const message = JSON.parse(error.message);

      dispatch(openToast({ message: message.remove_configuration_area[0], type: 'error' }));
    },
  });

  const isStandaloneAsset = data?.tags?.includes('DefaultLib');

  const { mapService } = useContext(WorldMapContext);

  // Create a state for displayButtons
  const [displayButtons, setDisplayButtons] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<EUserAssetPreviewTabs>(EUserAssetPreviewTabs.Basic);
  const [searchValue, setSearchValue] = useState<string>('');

  // TODO::We will integrate the results from this API
  //const [libraryData, setLibraryData] = useState(data);

  const tabsNavigation = NAVIGATION.filter((item) => {
    if (isStandaloneAsset) {
      return item !== EUserAssetPreviewTabs.Assets;
    }
    if (isCreateAssetView) {
      return true;
    }
    return item !== EUserAssetPreviewTabs.Advanced;
  });

  // Create a useEffect to set the displayButtons state
  // to false when the ActiveTab is not Assets
  useEffect(() => {
    setDisplayButtons(activeTab === EUserAssetPreviewTabs.Assets);
  }, [activeTab]);

  const onAddAssetClick = (item) => {
    handleSetMode('addNewAsset');
    setAssetsList?.(item);
  };

  const selectListItem = (value) => {
    setSelectedAsset(selectedAsset === value ? undefined : value);
  };

  const onSearchChange = ({ value }) => {
    setSearchValue(value);
  };

  //create a useEffect to update the isModalSummaryWithList state
  //if isCreateAssetView is false
  //when mounted as true
  //and unmounted as false
  useEffect(() => {
    if (!isCreateAssetView) {
      dispatch(setIsModalSummaryWithList(true));
    }
    return () => {
      dispatch(setIsModalSummaryWithList(false));
    };
  }, [dispatch, isCreateAssetView]);

  useEffect(() => {
    // PH-1276-Fixed::No auto-zoom in on houses while changing their features
    const assetCordinates = {
      lng: assetValues?.geoTagLocation?.[0],
      lat: assetValues?.geoTagLocation?.[1],
    };
    mapService?.flyTo(assetCordinates, { specificZoom: 18 });
    intervalListener(
      () => mapService?.set3DView(true),
      () => {
        const isBusy =
          mapService?.map.isEasing() || mapService?.map.isMoving() || mapService?.map.isRotating();

        return isBusy !== undefined ? isBusy : true;
      },
    );
  }, [assetValues?.geoTagLocation, mapService]);

  const closeActiveModal = () => {
    setOpenedAssetConfigUuid(null);
    setMenuModalTriggerRef({ current: null });
    dispatch(closeModal(modalId));
  };

  const onMenuClick = (menuTriggerRef, data) => {
    if (modalIsOpen) {
      closeActiveModal();
    } else {
      setOpenedAssetConfigUuid(data.itemUuid);
      setMenuModalTriggerRef({ current: menuTriggerRef });
      dispatch(openModal(modalId));
    }
  };

  const renderAssetsList = (items: TAssetsListData[], level = 0) => {
    return assets.map(({ children, ...item }, i) => (
      <React.Fragment key={i}>
        {(searchValue ? item.title.toLowerCase().includes(searchValue) : true) && (
          <AssetListItem
            key={i}
            uuid={item.uuid}
            title={item.name}
            icon={get2dAssetIcon(item.type)}
            isSelectable={!!item?.children}
            style={{ marginLeft: `${20 * level}px` }}
            isSelected={selectedAsset === item.uuid}
            onClick={() => children && !searchValue.length && selectListItem(item.uuid)}
            menuActive={menuActive}
            onMenuClick={onMenuClick}
          />
        )}
        {children &&
          (searchValue.length || selectedAsset === item.uuid) &&
          renderAssetsList(children, level + 1)}
      </React.Fragment>
    ));
  };

  const assetsList = useMemo<TAssetsListData[]>(() => {
    if (!children) return [];

    const createDataItem = (item): TAssetsListData => ({
      uuid: item.uuid,
      title: `${item.name}`,
      icon: get2dAssetIcon(item.type),
      isSelected: false,
      isSelectable: !!item?.children,
      children: item.children ? createList(item.children) : undefined,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createList = (items: any[]) =>
      items.reduce<TAssetsListData[]>((acc, item) => {
        acc.push(createDataItem(item));
        return acc;
      }, []);

    return children.length ? createList(children) : [];
  }, [children]);

  useEffect(() => {
    if (isStandaloneAsset) setActiveTab(EUserAssetPreviewTabs.Basic);

    setActiveTab(EUserAssetPreviewTabs.Assets);
  }, [isStandaloneAsset]);

  const readOnly = useSelector(selectReadOnly);

  const DashboardHeader = ({ onClickAction = backToDashboard }) => {
    if (onClickAction) {
      return (
        <header className={s.header}>
          <h3 onClick={() => handleSetMode('assetPreview')}>{`${area?.name}'s ${t(
            'common.HOME',
          )}`}</h3>
          <div>
            <BaseButtonSquare
              className={s.theXButton}
              icon="close"
              theme="flat-gray-light"
              onClick={onClickAction}
            />
          </div>
        </header>
      );
    }
    return null;
  };

  const dropDownItems: {
    title: string;
    icon: TIconNames;
    onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  }[] = [];

  dropDownItems.push({
    title: t('commands.EDIT'),
    icon: 'pencil-edit',
    onClick: () => {
      if (openedAssetConfigUuid) {
        handleSetMode('editAsset');
        dispatch(setSelectedAssetUuid(openedAssetConfigUuid));
        setSelectedAsset(openedAssetConfigUuid);
      }
    },
  });

  dropDownItems.push({
    title: t('commands.REMOVE'),
    icon: 'custom-trash',
    onClick: () => {
      if (openedAssetConfigUuid && activeConfigurationUuid) {
        removeConfigurationArea({
          variables: {
            configurationUuid: activeConfigurationUuid,
            areaUuid: openedAssetConfigUuid,
          },
        });
      }
    },
  });

  const currentMember = useMemo(() => scmMembers.find((item) => item.name === data?.name), [
    data?.name,
    scmMembers,
  ]);

  const dashboardOnClick = () => {
    return activeTab !== EUserAssetPreviewTabs.Assets
      ? () => setActiveTab(EUserAssetPreviewTabs.Assets)
      : backToDashboard;
  };

  return (
    <div className={s.container}>
      {!isCreateAssetView && <DashboardHeader onClickAction={dashboardOnClick()} />}
      {area && (
        <div className={s.wrapper}>
          <div className={s.hero}>
            <header>
              <div className={s.userPill}>
                <span>{area.name}</span>
              </div>
              {displayButtons && !isCreateAssetView && (
                <div className={s.buttonContainer}>
                  {(isOwnerAdmin || isOwnerCM) && (
                    <BaseButtonSquare
                      onClick={() => {
                        if (!activeConfigurationUuid || !area.uuid) return;

                        removeConfigurationArea({
                          variables: {
                            configurationUuid: activeConfigurationUuid,
                            areaUuid: area.uuid || '',
                          },
                        });
                      }}
                      className={s.button}
                      icon="trash"
                      size="2"
                    />
                  )}
                </div>
              )}
            </header>
            <div className={s.icon}>
              <BaseIcon icon={get3dAssetIcon(area?.type || 'canary')} />
            </div>
            {isCreateAssetView && <p>{area.name}</p>}
            {currentMember && <div className={s.address}>{currentMember.address}</div>}
            <p>
              {t('common.ASSETS')}: {assetsList?.length || 0}
            </p>
          </div>
          <nav className={s.navigation}>
            {tabsNavigation.map((item, i) => (
              <button
                key={i}
                type="button"
                className={classNames({
                  [s.active]: activeTab === item,
                })}
                onClick={() => {
                  setActiveTab(item);
                }}>
                {t(item)}
              </button>
            ))}
          </nav>
          {activeTab === EUserAssetPreviewTabs.Assets && (
            <div className={s.assetsList}>
              <div className={s.content}>
                {children?.length ? (
                  <>
                    <BaseSearch
                      className={s.search}
                      name="search"
                      iconRight="search"
                      label={`${t('commands.SEARCH')}`}
                      value={searchValue}
                      onChange={onSearchChange}
                    />
                    {renderAssetsList(assetsList)}
                  </>
                ) : (
                  <p style={{ textAlign: 'center', opacity: 0.6 }}>
                    <strong>{area?.name}</strong> {t('messages.NO_CHILD_ASSETS')}
                  </p>
                )}
                {/* PH-1265-Hide add asset button */}
                {!readOnly && (
                  <button type="button" className={s.addAssetButton} onClick={onAddAssetClick}>
                    <BaseIcon icon="plus" size={9} /> {t('commands.ADD_ASSET')}
                    {area && <span>({area.name})</span>}
                  </button>
                )}
              </div>
            </div>
          )}
          <ContextMenu
            relativeElement={menuModalTriggerRef}
            modalId={modalId}
            position={{
              side: 'right',
              anchorPosition: 50,
            }}
            items={dropDownItems}
          />
        </div>
      )}
    </div>
  );
};
