import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { matchPath, Route, Switch } from 'react-router';
import { BottomRightCopyright } from 'src/components/BottomRightCopyright';
import { Copyright } from 'src/components/Copyright';
import { InformationBox } from 'src/components/InformationBox';
import { Loader } from 'src/components/LoadingWrapper';
import { MapSidebar } from 'src/components/MapSidebar';
import { ModalAddUser } from 'src/components/ModalAddUser';
import { ModalCollaborationDeploy } from 'src/components/ModalCollaborationDeploy';
import { ModalDeployProcess } from 'src/components/ModalDeployProcess';
import { ModalMoveToCollabs } from 'src/components/ModalMoveToCollabs';
import { ModalOperateProcess } from 'src/components/ModalOperateProcess';
import { SimulationProgressModal } from 'src/components/SimulationProgressModal';
import { WorldMap } from 'src/components/WorldMap';
import { EPredefinedModalIds } from 'src/constants/modals';
import { WorldMapContextProvider } from 'src/contexts/WorldMapContext';
import { ModalBuildCommunity } from 'src/features/Communities/NewCommity/ModalBuildCommunity';
import { ModalUploadDataSheet } from 'src/features/Communities/NewCommity/ModalUploadDataSheet';
import { useUploadCommunityDatasheetMutation } from 'src/graphql';
import { APP_FLOW, useAppFlow } from 'src/hooks/useAppFlow';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useConfigurationEffects } from 'src/hooks/useConfigurationEffects';
import { useInitialSCMCommunity } from 'src/hooks/useInitialSCMCommunity';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { useNavigation } from 'src/hooks/useNavigation';
import { useScreenMode } from 'src/hooks/useScreenMode';
import { selectScreenMode } from 'src/redux/application/application.selectors';
import { setOnBoardingStep } from 'src/redux/application/application.slice';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { selectSimulationStatus } from 'src/redux/configuration/configuration.selectors';
import { openModal } from 'src/redux/modals/modals.slice';
import { selectIsAppLoading } from 'src/redux/scm/scm.selectors';
import { setSCMFlow } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';
import { TemplateDefault } from 'src/templates/TemplateDefault';
import { EScreenMode } from 'src/typings/configuration.types';
import { v4 } from 'uuid';

import PageEffectsContainer from '../../components/PageEffectsContainer/PageEffectsContainer';
import s from './SingularityMap.module.scss';

export const SingularityMap: React.FC = () => {
  const { updateAppFlow } = useAppFlow();
  const dispatch = useAppDispatch();

  useInitialSCMCommunity();

  useEffect(() => {
    updateAppFlow(APP_FLOW.SCM);
    dispatch(setSCMFlow(true));
  }, [dispatch, updateAppFlow]);

  return (
    <WorldMapContextProvider>
      <SingularityMapContent />
    </WorldMapContextProvider>
  );
};

const SingularityMapContent: React.FC = () => {
  const dispatch = useAppDispatch();

  useConfigurationEffects(); // This hook should be used only once per page
  const { modalSimulationProgressShow } = useNavigation();
  const { isUserACommunityMember } = useIsUserACommunityMember();
  const { isLoadingReadConfiguration, configuration, communityArea } = useConfiguration();
  const [, { loading: loadingUploadCDS }] = useUploadCommunityDatasheetMutation();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isAppLoading = useSelector(selectIsAppLoading);
  const simulationStatus = useSelector(selectSimulationStatus);

  const [showResultsTabOnClick, setShowResultsTabOnClick] = useState<boolean>(false);
  const [] = useState<string>(v4());

  const showResultsTab = typeof simulationStatus !== 'undefined';

  useEffect(() => {
    const seenOnboardingModal = localStorage.getItem('seenOnboardingModal');
    if (!seenOnboardingModal) {
      localStorage.setItem('seenOnboardingModal', 'true');
      dispatch(setOnBoardingStep(1));
    }
  }, [dispatch]);

  useScreenMode();

  const screenMode = useSelector(selectScreenMode);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN));
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    const routeMatch = !!matchPath(location.pathname, {
      path: [routesConfig.scmMapCreate()],
      exact: true,
    });
    if (!!configuration && !communityArea && !routeMatch && !loadingUploadCDS) {
      dispatch(openModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
    }
  }, [configuration, communityArea, loadingUploadCDS, dispatch, isLoggedIn]);

  return (
    <PageEffectsContainer>
      <TemplateDefault>
        <Switch>
          <Route>
            {(isAppLoading || isLoadingReadConfiguration) && (
              <>
                <Loader />
                <p className={s.loadingText}>loading . . .</p>
              </>
            )}
            <MapSidebar />
            {screenMode === EScreenMode.Desktop && <WorldMap className={s.worldMap} />}
            <ModalCollaborationDeploy />
            <ModalUploadDataSheet />
            <ModalBuildCommunity />
            <ModalDeployProcess />
            <ModalAddUser />
            <ModalOperateProcess />
            <ModalMoveToCollabs />
            {screenMode === EScreenMode.Desktop &&
              modalSimulationProgressShow &&
              !isUserACommunityMember && <SimulationProgressModal />}
          </Route>
        </Switch>
        {isUserACommunityMember && screenMode === EScreenMode.Desktop && <Copyright />}
        {!isUserACommunityMember && <BottomRightCopyright />}
        {isUserACommunityMember && showResultsTab && !showResultsTabOnClick && (
          <InformationBox
            onClickAction={() => {
              setShowResultsTabOnClick(true);
            }}
          />
        )}
      </TemplateDefault>
    </PageEffectsContainer>
  );
};
