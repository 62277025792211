import 'react-tooltip/dist/react-tooltip.css';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { LoadingWrapper } from 'src/components/LoadingWrapper';
import { ETabs, Tabs } from 'src/components/Tabs';
import { ConfigType, useListScmLazyQuery } from 'src/graphql';
import { ConfigurationOutput } from 'src/graphql';
import { useDeleteProjectMutation, useDeleteConfigurationMutation } from 'src/graphql';
import { ListScmDocument } from 'src/graphql';
import { selectAppMode } from 'src/redux/application/application.selectors';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { setCommunitiesList } from 'src/redux/communities/communities.slice';
import { selectActiveCommunityListTab } from 'src/redux/scm/scm.selectors';
import { setActiveCommunityListTab } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { EAppMode } from 'src/utils/appMode';
import { dispatchErrorToast, dispatchSuccessToast } from 'src/utils/toasts';

import { ListCommunities } from './ListCommunities';
import s from './ListCommunities.module.scss';

export const ListCommunitiesCentral: React.FC<{
  mobileListClassName?: string;
}> = ({ mobileListClassName }) => {
  const dispatch = useAppDispatch();

  const [listHeight, setListHeight] = useState<number>();
  const [isloading, setLoading] = useState(false);

  const activeCommunityListTab = useSelector(selectActiveCommunityListTab);
  const appMode = useSelector(selectAppMode);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [deleteProject] = useDeleteProjectMutation({
    errorPolicy: 'none',
    refetchQueries: [
      { query: ListScmDocument, variables: { configType: ConfigType.Collaboration } },
    ],
    onCompleted: () => {
      dispatchSuccessToast('Community removed');
    },
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });

  const [deleteConfiguration] = useDeleteConfigurationMutation({
    errorPolicy: 'none',
    refetchQueries: [
      { query: ListScmDocument, variables: { configType: ConfigType.CanaryNetwork } },
    ],
    onCompleted: () => {
      dispatchSuccessToast('Community removed');
    },
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });

  const handleRemoveSimulationCommunity = useCallback(
    async ({ projectUuid }) => {
      await deleteProject({
        variables: { projectUuid },
      });
    },
    [deleteProject],
  );

  const handleRemoveOperationalCommunity = useCallback(
    async ({ projectUuid }) => {
      await deleteConfiguration({
        variables: { configurationUuid: projectUuid },
      });
    },
    [deleteConfiguration],
  );

  const onListContainerLayout = useCallback(
    (elem: HTMLDivElement) => {
      if (elem && !listHeight) {
        setListHeight(elem.clientHeight);
      }
    },
    [listHeight],
  );

  const [fetchSimulationCommunities, { data: simulationCommunities }] = useListScmLazyQuery({
    fetchPolicy: 'cache-first',
    variables: {
      configType: ConfigType.Collaboration,
    },
  });

  const [fetchOperationalCommunities, { data: operationalCommunities }] = useListScmLazyQuery({
    fetchPolicy: 'cache-first',
    variables: {
      configType: ConfigType.CanaryNetwork,
    },
  });

  useEffect(() => {
    switch (activeCommunityListTab) {
      case ETabs.OPERATIONAL:
        return fetchOperationalCommunities();
      case ETabs.SIMULATIONS:
        return fetchSimulationCommunities();
    }
  }, [activeCommunityListTab, fetchSimulationCommunities, fetchOperationalCommunities, isLoggedIn]);

  const communities = useMemo(() => {
    if (activeCommunityListTab === ETabs.SIMULATIONS && isLoggedIn) {
      return simulationCommunities?.listScmCommunities?.configurations?.filter(
        (config): config is ConfigurationOutput => config !== null,
      );
    } else if (activeCommunityListTab === ETabs.OPERATIONAL && isLoggedIn) {
      return operationalCommunities?.listScmCommunities?.configurations?.filter(
        (config): config is ConfigurationOutput => config !== null,
      );
    }
  }, [
    activeCommunityListTab,
    simulationCommunities?.listScmCommunities?.configurations,
    operationalCommunities?.listScmCommunities?.configurations,
    isLoggedIn,
  ]);

  const onDuplicationComplete = () => {
    dispatch(setActiveCommunityListTab(ETabs.SIMULATIONS));
    setLoading(false);
    if (activeCommunityListTab === ETabs.OPERATIONAL) {
      fetchOperationalCommunities();
    } else {
      fetchSimulationCommunities();
    }
  };

  useEffect(() => {
    if (!!communities) {
      dispatch(setCommunitiesList(communities));
    }
  }, [activeCommunityListTab, communities, dispatch]);

  if (appMode === EAppMode.Mobile) {
    return (
      <div className={classnames(s.list, mobileListClassName)} ref={onListContainerLayout}>
        <LoadingWrapper loading={!listHeight || isloading}>
          {() => (
            <ListCommunities
              items={communities ?? []}
              height={listHeight!}
              removeCommunity={
                activeCommunityListTab === ETabs.OPERATIONAL
                  ? handleRemoveOperationalCommunity
                  : handleRemoveSimulationCommunity
              }
              updatePersonalCommunitiesList={
                activeCommunityListTab === ETabs.OPERATIONAL
                  ? fetchOperationalCommunities
                  : fetchSimulationCommunities
              }
              isOperationalTab={activeCommunityListTab === ETabs.OPERATIONAL}
              onDuplicationComplete={onDuplicationComplete}
            />
          )}
        </LoadingWrapper>
      </div>
    );
  }

  return (
    <div className={s.listContainer}>
      <Tabs
        tabs={[ETabs.SIMULATIONS, ETabs.OPERATIONAL]}
        activeTab={activeCommunityListTab}
        handleClick={(tab) => dispatch(setActiveCommunityListTab(tab))}
        className={s.listHeader}
      />
      <div className={s.list} ref={onListContainerLayout}>
        <LoadingWrapper loading={!listHeight || isloading}>
          {() => (
            <ListCommunities
              items={communities ?? []}
              height={listHeight!}
              removeCommunity={
                activeCommunityListTab === ETabs.OPERATIONAL
                  ? handleRemoveOperationalCommunity
                  : handleRemoveSimulationCommunity
              }
              updatePersonalCommunitiesList={
                activeCommunityListTab === ETabs.OPERATIONAL
                  ? fetchOperationalCommunities
                  : fetchSimulationCommunities
              }
              isOperationalTab={activeCommunityListTab === ETabs.OPERATIONAL}
              onDuplicationComplete={onDuplicationComplete}
              setLoadingStatus={setLoading}
            />
          )}
        </LoadingWrapper>
      </div>
    </div>
  );
};
