import React from 'react';

import classnames from 'classnames';
import { BaseButton } from 'src/components/BaseButton';

import { InputField } from './InputField';
import s from './InputField.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InputFieldWithOk: React.FC<any> = (props: any) => {
  const { onClick, disabled, className, ...rest } = props;
  return (
    <div className={classnames(s.inputFieldWithOk, className)}>
      {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
      <InputField {...rest} fieldType={props.type || props.fieldType} />
      <BaseButton type="button" className={s.OKButton} onClick={onClick} disabled={disabled}>
        OK
      </BaseButton>
    </div>
  );
};
