import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { ConfigurationOutput, useReadConfigurationQuery } from 'src/graphql';
import { selectTokenAuth, selectUserRole } from 'src/redux/auth/auth.selectors';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { EUserRoles } from 'src/typings/base-types';

export type TUseIsAdminInspectingResult = {
  isAdminInspecting: boolean;
  isOwnerAdmin: boolean;
  isOwnerCM: boolean;
};

export const useIsAdminInspecting = (): TUseIsAdminInspectingResult => {
  const userRole = useSelector(selectUserRole);
  const userInfo = useSelector(selectTokenAuth);
  const configUuid = useSelector(selectActiveConfigurationUuid);

  const { data: configurationResponse } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !configUuid || configUuid === 'NEW_COMMUNITY',
    variables: { uuid: configUuid! },
  });
  const configuration: ConfigurationOutput | null = useMemo(
    () => configurationResponse?.readConfiguration as ConfigurationOutput | null,
    [configurationResponse],
  );
  const isAdminInspecting = useMemo(
    () =>
      userRole && userRole === EUserRoles.Admin && configuration?.user !== userInfo?.email
        ? true
        : false,
    [userRole, configuration, userInfo?.email],
  );

  const isOwnerAdmin = useMemo(
    () => !!(userRole && userRole === EUserRoles.Admin && configuration?.user === userInfo?.email),
    [userRole, configuration, userInfo?.email],
  );

  const isOwnerCM = useMemo(
    () =>
      userRole &&
      userRole === EUserRoles.ExchangeOperator &&
      configuration?.user === userInfo?.email
        ? true
        : false,
    [userRole, configuration, userInfo?.email],
  );

  return { isAdminInspecting, isOwnerAdmin, isOwnerCM };
};
